import ReactGA from 'react-ga';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React from 'react';

import VectorMap, {
  Label,
  Layer,
  Tooltip,
  Border,
  Font
} from 'devextreme-react/vector-map';

import * as mapsData from 'devextreme/dist/js/vectormap-data/world.js';
import { countries } from './data/dx-countries.js';
import { colors } from './colors.js';
import TooltipTemplate from './TooltipTemplate.js';

const bounds = [-100, 85, 100, -80];
const devextreme = require('./images/DevExpress-Powered-White-Medium.png');

ReactGA.initialize('UA-164356869-1');
ReactGA.pageview(window.location.pathname + window.location.search);

export default function App() {

  return (
    <div class="full-height">
      <VectorMap
        id="vector-map"
        bounds={bounds}
        onClick={clickHandler}
      >
        <Layer
          dataSource={mapsData.world}
          customize={customizeLayer}>
          <Label enabled={true} dataField="name"></Label>
        </Layer>
        <Tooltip enabled={true}
          contentRender={TooltipTemplate}
        >
          <Border visible={true}></Border>
          <Font color="#fff"></Font>
        </Tooltip>
      <div id="footer">
        <p>
          <a class="copyright-link" href="http://fredericpaladin.com" target="blank">Frederic Paladin</a><span> | </span>Copyright © {(new Date()).getFullYear()}<span> | </span>
          <a href="https://www.devexpress.com" target="blank"><img id="dx-img" src={devextreme}/></a>
        </p>
      </div>
      <div id="source">data source: <a id="source-link" href="http://www.wikipedia.org" target="blank">www.wikipedia.org</a></div>
      </VectorMap>
    </div>
  );
}

function randomColor() {
  return colors[Math.floor(Math.random() * colors.length)];
}

function clickHandler({ target }) {
  if (target && countries[target.attribute('name')]) {
    var selected = !target.selected();
    target.selected(selected);

  }
}

function customizeLayer(elements) {
  elements.forEach((element) => {
    const country = countries[element.attribute('name')];
    if (country) {
      element.applySettings({
        color: randomColor(),
        hoveredColor: '#e0e000',
        selectedColor: '#008f00'
      });
    }
  });
}
