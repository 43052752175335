export const wikies = {
    "AF": {
        "name": "Afghanistan",
        "wiki": "https://en.wikipedia.org/wiki/Afghanistan",
        "capital": "Kabul",
        "area": 652230,
        "population": {
            "value": 32225560,
            "year": 2019,
            "source": "estimate"
        }
    },
    "AL": {
        "name": "Albania",
        "wiki": "https://en.wikipedia.org/wiki/Albania",
        "capital": "Tirana",
        "area": 28748,
        "population": {
            "value": 2821977,
            "year": 2011,
            "source": "census"
        }
    },
    "DZ": {
        "name": "Algeria",
        "wiki": "https://en.wikipedia.org/wiki/Algeria",
        "capital": "Algiers",
        "area": 2381741,
        "population": {
            "value": 43000000,
            "year": 2019,
            "source": "estimate"
        }
    },
    "AO": {
        "name": "Angola",
        "wiki": "https://en.wikipedia.org/wiki/Angola",
        "capital": "Luanda",
        "area": 1246700,
        "population": {
            "value": 25789024,
            "year": 2014,
            "source": "census"
        }
    },
    "AQ": {
        "name": "Antarctica",
        "wiki": "https://en.wikipedia.org/wiki/Antarctica",
        "capital": "",
        "area": 14200000,
        "population": {
            "value": -99,
            "year": -99,
            "source": "0"
        }
    },
    "AR": {
        "name": "Argentina",
        "wiki": "https://en.wikipedia.org/wiki/Argentina",
        "capital": "Buenos Aires",
        "area": 2780400,
        "population": {
            "value": 40117096,
            "year": 2010,
            "source": "census"
        }
    },
    "AM": {
        "name": "Armenia",
        "wiki": "https://en.wikipedia.org/wiki/Armenia",
        "capital": "Yerevan",
        "area": 29743,
        "population": {
            "value": 3018854,
            "year": 2011,
            "source": "census"
        }
    },
    "AU": {
        "name": "Australia",
        "wiki": "https://en.wikipedia.org/wiki/Australia",
        "capital": "Canberra",
        "area": 7692024,
        "population": {
            "value": 23401892,
            "year": 2016,
            "source": "census"
        }
    },
    "AT": {
        "name": "Austria",
        "wiki": "https://en.wikipedia.org/wiki/Austria",
        "capital": "Vienna",
        "area": 83879,
        "population": {
            "value": 8902600,
            "year": 2020,
            "source": "estimate"
        }
    },
    "AZ": {
        "name": "Azerbaijan",
        "wiki": "https://en.wikipedia.org/wiki/Azerbaijan",
        "capital": "Baku",
        "area": 86600,
        "population": {
            "value": 10027874,
            "year": 2019,
            "source": "estimate"
        }
    },
    "BS": {
        "name": "Bahamas",
        "wiki": "https://en.wikipedia.org/wiki/The_Bahamas",
        "capital": "Nassau",
        "area": 13878,
        "population": {
            "value": 351461,
            "year": 2010,
            "source": "census"
        }
    },
    "BD": {
        "name": "Bangladesh",
        "wiki": "https://en.wikipedia.org/wiki/Bangladesh",
        "capital": "Dhaka",
        "area": 147570,
        "population": {
            "value": 149772364,
            "year": 2011,
            "source": "census"
        }
    },
    "BY": {
        "name": "Belarus",
        "wiki": "https://en.wikipedia.org/wiki/Belarus",
        "capital": "Minsk",
        "area": 207595,
        "population": {
            "value": 9413446,
            "year": 2019,
            "source": "census"
        }
    },
    "BE": {
        "name": "Belgium",
        "wiki": "https://en.wikipedia.org/wiki/Belgium",
        "capital": "Brussels",
        "area": 30689,
        "population": {
            "value": 11515793,
            "year": 2019,
            "source": "census"
        }
    },
    "BZ": {
        "name": "Belize",
        "wiki": "https://en.wikipedia.org/wiki/Belize",
        "capital": "Belmopan",
        "area": 22966,
        "population": {
            "value": 408487,
            "year": 2019,
            "source": "estimate"
        }
    },
    "BJ": {
        "name": "Benin",
        "wiki": "https://en.wikipedia.org/wiki/Benin",
        "capital": "Porto-Novo",
        "area": 114763,
        "population": {
            "value": 10008749,
            "year": 2013,
            "source": "census"
        }
    },
    "BT": {
        "name": "Bhutan",
        "wiki": "https://en.wikipedia.org/wiki/Bhutan",
        "capital": "Thimphu",
        "area": 38394,
        "population": {
            "value": 727145,
            "year": 2017,
            "source": "census"
        }
    },
    "BO": {
        "name": "Bolivia",
        "wiki": "https://en.wikipedia.org/wiki/Bolivia",
        "capital": "Sucre (constitutional and judicial), La Paz (executive and legislative)",
        "area": 1098581,
        "population": {
            "value": 11428245,
            "year": 2019,
            "source": "estimate"
        }
    },
    "BA": {
        "name": "Bosnia and Herz.",
        "wiki": "https://en.wikipedia.org/wiki/Bosnia_and_Herzegovina",
        "capital": "Sarajevo",
        "area": 51129,
        "population": {
            "value": 3511372,
            "year": 2016,
            "source": "census"
        }
    },
    "BW": {
        "name": "Botswana",
        "wiki": "https://en.wikipedia.org/wiki/Botswana",
        "capital": "Gaborone",
        "area": 581730,
        "population": {
            "value": 2024904,
            "year": 2011,
            "source": "census"
        }
    },
    "BR": {
        "name": "Brazil",
        "wiki": "https://en.wikipedia.org/wiki/Brazil",
        "capital": "Brasília",
        "area": 8515767,
        "population": {
            "value": 210147125,
            "year": 2019,
            "source": "estimate"
        }
    },
    "BN": {
        "name": "Brunei",
        "wiki": "https://en.wikipedia.org/wiki/Brunei",
        "capital": "Bandar Seri Begawan",
        "area": 5765,
        "population": {
            "value": 442400,
            "year": 2018,
            "source": "estimate"
        }
    },
    "BG": {
        "name": "Bulgaria",
        "wiki": "https://en.wikipedia.org/wiki/Bulgaria",
        "capital": "Sofia",
        "area": 110993.6,
        "population": {
            "value": 7000039,
            "year": 2019,
            "source": "estimate"
        }
    },
    "BF": {
        "name": "Burkina Faso",
        "wiki": "https://en.wikipedia.org/wiki/Burkina_Faso",
        "capital": "Ouagadougou",
        "area": 274200,
        "population": {
            "value": 14017262,
            "year": 2006,
            "source": "census"
        }
    },
    "BI": {
        "name": "Burundi",
        "wiki": "https://en.wikipedia.org/wiki/Burundi",
        "capital": "Gitega (political), Bujumbura (economic)",
        "area": 27834,
        "population": {
            "value": 8053574,
            "year": 2008,
            "source": "census"
        }
    },
    "KH": {
        "name": "Cambodia",
        "wiki": "https://en.wikipedia.org/wiki/Cambodia",
        "capital": "Phnom Penh",
        "area": 181035,
        "population": {
            "value": 15288489,
            "year": 2019,
            "source": "census"
        }
    },
    "CM": {
        "name": "Cameroon",
        "wiki": "https://en.wikipedia.org/wiki/Cameroon",
        "capital": "Yaoundé",
        "area": 475442,
        "population": {
            "value": 17463836,
            "year": 2005,
            "source": "census"
        }
    },
    "CA": {
        "name": "Canada",
        "wiki": "https://en.wikipedia.org/wiki/Canada",
        "capital": "Ottawa",
        "area": 9984670,
        "population": {
            "value": 35151728,
            "year": 2016,
            "source": "census"
        }
    },
    "CF": {
        "name": "Central African Rep.",
        "wiki": "https://en.wikipedia.org/wiki/Central_African_Republic",
        "capital": "Bangui",
        "area": 622984,
        "population": {
            "value": 3895139,
            "year": 2003,
            "source": "census"
        }
    },
    "TD": {
        "name": "Chad",
        "wiki": "https://en.wikipedia.org/wiki/Chad",
        "capital": "N'Djamena",
        "area": 1284000,
        "population": {
            "value": 11039873,
            "year": 2009,
            "source": "census"
        }
    },
    "CL": {
        "name": "Chile",
        "wiki": "https://en.wikipedia.org/wiki/Chile",
        "capital": "Santiago",
        "area": 756096.3,
        "population": {
            "value": 17574003,
            "year": 2017,
            "source": "census"
        }
    },
    "CN": {
        "name": "China",
        "wiki": "https://en.wikipedia.org/wiki/China",
        "capital": "Beijing",
        "area": 9596961,
        "population": {
            "value": 1339724852,
            "year": 2010,
            "source": "census"
        }
    },
    "CO": {
        "name": "Colombia",
        "wiki": "https://en.wikipedia.org/wiki/Colombia",
        "capital": "Bogotá",
        "area": 1141748,
        "population": {
            "value": 50372424,
            "year": 2020,
            "source": "estimate"
        }
    },
    "CG": {
        "name": "Congo",
        "wiki": "https://en.wikipedia.org/wiki/Republic_of_the_Congo",
        "capital": "Brazzaville",
        "area": 342000,
        "population": {
            "value": 5244359,
            "year": 2018,
            "source": "estimate"
        }
    },
    "CR": {
        "name": "Costa Rica",
        "wiki": "https://en.wikipedia.org/wiki/Costa_Rica",
        "capital": "San José",
        "area": 51100,
        "population": {
            "value": 4999441,
            "year": 2018,
            "source": "estimate"
        }
    },
    "CI": {
        "name": "Côte d'Ivoire",
        "wiki": "https://en.wikipedia.org/wiki/Ivory_Coast",
        "capital": "Yamoussoukro (political), Abidjan (economic)",
        "area": 322463,
        "population": {
            "value": 24905843,
            "year": 2015,
            "source": "census"
        }
    },
    "HR": {
        "name": "Croatia",
        "wiki": "https://en.wikipedia.org/wiki/Croatia",
        "capital": "Zagreb",
        "area": 56594,
        "population": {
            "value": 4284889,
            "year": 2011,
            "source": "census"
        }
    },
    "CU": {
        "name": "Cuba",
        "wiki": "https://en.wikipedia.org/wiki/Cuba",
        "capital": "Havana",
        "area": 109884,
        "population": {
            "value": 11209628,
            "year": 2018,
            "source": "census"
        }
    },
    "CY": {
        "name": "N. Cyprus",
        "wiki": "https://en.wikipedia.org/wiki/Northern_Cyprus",
        "capital": "North Nicosia (de facto), Nicosia (de jure)",
        "area": 3355,
        "population": {
            "value": 286257,
            "year": 2011,
            "source": "census"
        }
    },
    "CZ": {
        "name": "Czech Rep.",
        "wiki": "https://en.wikipedia.org/wiki/Czech_Republic",
        "capital": "Prague",
        "area": 78866,
        "population": {
            "value": 10436560,
            "year": 2011,
            "source": "census"
        }
    },
    "CD": {
        "name": "Dem. Rep. Congo",
        "wiki": "https://en.wikipedia.org/wiki/Democratic_Republic_of_the_Congo",
        "capital": "Kinshasa",
        "area": 2345409,
        "population": {
            "value": 101780263,
            "year": -99,
            "source": "estimate"
        }
    },
    "KP": {
        "name": "Dem. Rep. Korea",
        "wiki": "https://en.wikipedia.org/wiki/North_Korea",
        "capital": "Pyongyang",
        "area": 120540,
        "population": {
            "value": 24052231,
            "year": 2008,
            "source": "census"
        }
    },
    "DK": {
        "name": "Denmark",
        "wiki": "https://en.wikipedia.org/wiki/Denmark",
        "capital": "Copenhagen",
        "area": 42933,
        "population": {
            "value": 5822763,
            "year": 2019,
            "source": "estimate"
        }
    },
    "DJ": {
        "name": "Djibouti",
        "wiki": "https://en.wikipedia.org/wiki/Djibouti",
        "capital": "Djibouti",
        "area": 23200,
        "population": {
            "value": 921804,
            "year": 2020,
            "source": "estimate"
        }
    },
    "DO": {
        "name": "Dominican Rep.",
        "wiki": "https://en.wikipedia.org/wiki/Dominican_Republic",
        "capital": "Santo Domingo",
        "area": 48671,
        "population": {
            "value": 9478612,
            "year": 2010,
            "source": "census"
        }
    },
    "EC": {
        "name": "Ecuador",
        "wiki": "https://en.wikipedia.org/wiki/Ecuador",
        "capital": "Quito",
        "area": 283561,
        "population": {
            "value": 17084358,
            "year": 2018,
            "source": "estimate"
        }
    },
    "EG": {
        "name": "Egypt",
        "wiki": "https://en.wikipedia.org/wiki/Egypt",
        "capital": "Cairo",
        "area": 1010408,
        "population": {
            "value": 94798827,
            "year": 2017,
            "source": "census"
        }
    },
    "SV": {
        "name": "El Salvador",
        "wiki": "https://en.wikipedia.org/wiki/El_Salvador",
        "capital": "San Salvador",
        "area": 21041,
        "population": {
            "value": 6420746,
            "year": 2018,
            "source": "estimate"
        }
    },
    "GQ": {
        "name": "Eq. Guinea",
        "wiki": "https://en.wikipedia.org/wiki/Equatorial_Guinea",
        "capital": "Malabo",
        "area": 28050,
        "population": {
            "value": 1222442,
            "year": 2015,
            "source": "census"
        }
    },
    "ER": {
        "name": "Eritrea",
        "wiki": "https://en.wikipedia.org/wiki/Eritrea",
        "capital": "Asmara",
        "area": 117600,
        "population": {
            "value": 5750433,
            "year": 2018,
            "source": "estimate"
        }
    },
    "EE": {
        "name": "Estonia",
        "wiki": "https://en.wikipedia.org/wiki/Estonia",
        "capital": "Tallinna",
        "area": 45227,
        "population": {
            "value": 1294455,
            "year": 2011,
            "source": "census"
        }
    },
    "ET": {
        "name": "Ethiopia",
        "wiki": "https://en.wikipedia.org/wiki/Ethiopia",
        "capital": "Addis Ababa",
        "area": 1104300,
        "population": {
            "value": 73750932,
            "year": 2007,
            "source": "census"
        }
    },
    "FK": {
        "name": "Falkland Is.",
        "wiki": "https://en.wikipedia.org/wiki/Falkland_Islands",
        "capital": "Stanley",
        "area": 12200,
        "population": {
            "value": 3398,
            "year": 2016,
            "source": "census"
        }
    },
    "FJ": {
        "name": "Fiji",
        "wiki": "https://en.wikipedia.org/wiki/Fiji",
        "capital": "Suva",
        "area": 18274,
        "population": {
            "value": 884887,
            "year": 2017,
            "source": "census"
        }
    },
    "FI": {
        "name": "Finland",
        "wiki": "https://en.wikipedia.org/wiki/Finland",
        "capital": "Helsinki",
        "area": 338424,
        "population": {
            "value": 5526774,
            "year": 2020,
            "source": "estimate"
        }
    },
    "TF": {
        "name": "Fr. S. Antarctic Lands",
        "wiki": "https://en.wikipedia.org/wiki/French_Southern_and_Antarctic_Lands",
        "capital": "Saint-Pierre",
        "area": 439781,
        "population": {
            "value": -99,
            "year": -99,
            "source": "0"
        }
    },
    "FR": {
        "name": "France",
        "wiki": "https://en.wikipedia.org/wiki/France",
        "capital": "Paris",
        "area": 640679,
        "population": {
            "value": 67076000,
            "year": 2020,
            "source": "estimate"
        }
    },
    "GA": {
        "name": "Gabon",
        "wiki": "https://en.wikipedia.org/wiki/Gabon",
        "capital": "Libreville",
        "area": 267667,
        "population": {
            "value": 2119275,
            "year": 2018,
            "source": "estimate"
        }
    },
    "GM": {
        "name": "Gambia",
        "wiki": "https://en.wikipedia.org/wiki/The_Gambia",
        "capital": "Banjul",
        "area": 10689,
        "population": {
            "value": 1857181,
            "year": 2013,
            "source": "census"
        }
    },
    "GE": {
        "name": "Georgia",
        "wiki": "https://en.wikipedia.org/wiki/Georgia_(country)",
        "capital": "Tbilisi",
        "area": 69700,
        "population": {
            "value": 3713804,
            "year": 2014,
            "source": "census"
        }
    },
    "DE": {
        "name": "Germany",
        "wiki": "https://en.wikipedia.org/wiki/Germany",
        "capital": "Berlin",
        "area": 357022,
        "population": {
            "value": 83149300,
            "year": 2019,
            "source": "estimate"
        }
    },
    "GH": {
        "name": "Ghana",
        "wiki": "https://en.wikipedia.org/wiki/Ghana",
        "capital": "Accra",
        "area": 239567,
        "population": {
            "value": 24200000,
            "year": 2010,
            "source": "census"
        }
    },
    "GR": {
        "name": "Greece",
        "wiki": "https://en.wikipedia.org/wiki/Greece",
        "capital": "Athens",
        "area": 131957,
        "population": {
            "value": 10816286,
            "year": 2011,
            "source": "census"
        }
    },
    "GL": {
        "name": "Greenland",
        "wiki": "https://en.wikipedia.org/wiki/Greenland",
        "capital": "Nuuk",
        "area": 2166086,
        "population": {
            "value": 56081,
            "year": 2020,
            "source": "estimate"
        }
    },
    "GT": {
        "name": "Guatemala",
        "wiki": "https://en.wikipedia.org/wiki/Guatemala",
        "capital": "Guatemala City",
        "area": 108889,
        "population": {
            "value": 17263239,
            "year": 2018,
            "source": "estimate"
        }
    },
    "GN": {
        "name": "Guinea",
        "wiki": "https://en.wikipedia.org/wiki/Guinea",
        "capital": "Conakry",
        "area": 245857,
        "population": {
            "value": 11628972,
            "year": 2014,
            "source": "census"
        }
    },
    "GW": {
        "name": "Guinea-Bissau",
        "wiki": "https://en.wikipedia.org/wiki/Guinea-Bissau",
        "capital": "Bissau",
        "area": 36125,
        "population": {
            "value": 1874303,
            "year": 2018,
            "source": "estimate"
        }
    },
    "GY": {
        "name": "Guyana",
        "wiki": "https://en.wikipedia.org/wiki/Guyana",
        "capital": "Georgetown",
        "area": 214970,
        "population": {
            "value": 747884,
            "year": 2012,
            "source": "census"
        }
    },
    "HT": {
        "name": "Haiti",
        "wiki": "https://en.wikipedia.org/wiki/Haiti",
        "capital": "Port-au-Prince",
        "area": 27750,
        "population": {
            "value": 11123178,
            "year": 2018,
            "source": "estimate"
        }
    },
    "HN": {
        "name": "Honduras",
        "wiki": "https://en.wikipedia.org/wiki/Honduras",
        "capital": "Tegucigalpa",
        "area": 112492,
        "population": {
            "value": 8303771,
            "year": 2013,
            "source": "census"
        }
    },
    "HU": {
        "name": "Hungary",
        "wiki": "https://en.wikipedia.org/wiki/Hungary",
        "capital": "Budapest",
        "area": 93030,
        "population": {
            "value": 9772756,
            "year": 2019,
            "source": "estimate"
        }
    },
    "IS": {
        "name": "Iceland",
        "wiki": "https://en.wikipedia.org/wiki/Iceland",
        "capital": "Reykjavík",
        "area": 102775,
        "population": {
            "value": 364134,
            "year": 2020,
            "source": "estimate"
        }
    },
    "IN": {
        "name": "India",
        "wiki": "https://en.wikipedia.org/wiki/India",
        "capital": "New Delhi",
        "area": 3287263,
        "population": {
            "value": 1210854977,
            "year": 2011,
            "source": "census"
        }
    },
    "ID": {
        "name": "Indonesia",
        "wiki": "https://en.wikipedia.org/wiki/Indonesia",
        "capital": "Jakarta",
        "area": 1904569,
        "population": {
            "value": 237641326,
            "year": 2010,
            "source": "census"
        }
    },
    "IR": {
        "name": "Iran",
        "wiki": "https://en.wikipedia.org/wiki/Iran",
        "capital": "Tehran",
        "area": 1648195,
        "population": {
            "value": 83183741,
            "year": 2019,
            "source": "estimate"
        }
    },
    "IQ": {
        "name": "Iraq",
        "wiki": "https://en.wikipedia.org/wiki/Iraq",
        "capital": "Baghdad",
        "area": 437072,
        "population": {
            "value": 38433600,
            "year": 2018,
            "source": "estimate"
        }
    },
    "IE": {
        "name": "Ireland",
        "wiki": "https://en.wikipedia.org/wiki/Republic_of_Ireland",
        "capital": "Dublin",
        "area": 70273,
        "population": {
            "value": 4921500,
            "year": 2019,
            "source": "estimate"
        }
    },
    "IL": {
        "name": "Israel",
        "wiki": "https://en.wikipedia.org/wiki/Israel",
        "capital": "Jerusalem",
        "area": 20770,
        "population": {
            "value": 7412200,
            "year": 2008,
            "source": "census"
        }
    },
    "IT": {
        "name": "Italy",
        "wiki": "https://en.wikipedia.org/wiki/Italy",
        "capital": "Rome",
        "area": 301340,
        "population": {
            "value": 59433744,
            "year": 2011,
            "source": "census"
        }
    },
    "JM": {
        "name": "Jamaica",
        "wiki": "https://en.wikipedia.org/wiki/Jamaica",
        "capital": "Kingston",
        "area": 10991,
        "population": {
            "value": 2697983,
            "year": 2011,
            "source": "census"
        }
    },
    "JP": {
        "name": "Japan",
        "wiki": "https://en.wikipedia.org/wiki/Japan",
        "capital": "Tokyo",
        "area": 377975,
        "population": {
            "value": 127094745,
            "year": 2015,
            "source": "census"
        }
    },
    "JO": {
        "name": "Jordan",
        "wiki": "https://en.wikipedia.org/wiki/Jordan",
        "capital": "Amman",
        "area": 89342,
        "population": {
            "value": 9531712,
            "year": 2015,
            "source": "census"
        }
    },
    "KZ": {
        "name": "Kazakhstan",
        "wiki": "https://en.wikipedia.org/wiki/Kazakhstan",
        "capital": "Nur-Sultan",
        "area": 2724900,
        "population": {
            "value": 18448600,
            "year": 2019,
            "source": "estimate"
        }
    },
    "KE": {
        "name": "Kenya",
        "wiki": "https://en.wikipedia.org/wiki/Kenya",
        "capital": "Nairobi",
        "area": 580367,
        "population": {
            "value": 47564296,
            "year": 2019,
            "source": "census"
        }
    },
    "KR": {
        "name": "Korea",
        "wiki": "https://en.wikipedia.org/wiki/South_Korea",
        "capital": "Seoul, Sejong (de-facto administrative)",
        "area": 100363,
        "population": {
            "value": 51709098,
            "year": 2019,
            "source": "estimate"
        }
    },
    "KV": {
        "name": "Kosovo",
        "wiki": "https://en.wikipedia.org/wiki/Kosovo",
        "capital": "Pristina",
        "area": 10887,
        "population": {
            "value": 1810463,
            "year": 2020,
            "source": "estimate"
        }
    },
    "KW": {
        "name": "Kuwait",
        "wiki": "https://en.wikipedia.org/wiki/Kuwait",
        "capital": "Kuwait City",
        "area": 17818,
        "population": {
            "value": 2213403,
            "year": 2005,
            "source": "census"
        }
    },
    "KG": {
        "name": "Kyrgyzstan",
        "wiki": "https://en.wikipedia.org/wiki/Kyrgyzstan",
        "capital": "Bishkek",
        "area": 199951,
        "population": {
            "value": 5362800,
            "year": 2009,
            "source": "census"
        }
    },
    "LA": {
        "name": "Lao PDR",
        "wiki": "https://en.wikipedia.org/wiki/Laos",
        "capital": "Vientiane",
        "area": 237955,
        "population": {
            "value": 7096376,
            "year": 2015,
            "source": "census"
        }
    },
    "LV": {
        "name": "Latvia",
        "wiki": "https://en.wikipedia.org/wiki/Latvia",
        "capital": "Riga",
        "area": 64589,
        "population": {
            "value": 2070371,
            "year": 2011,
            "source": "census"
        }
    },
    "LB": {
        "name": "Lebanon",
        "wiki": "https://en.wikipedia.org/wiki/Lebanon",
        "capital": "Beirut",
        "area": 10452,
        "population": {
            "value": 6859408,
            "year": 2018,
            "source": "estimate"
        }
    },
    "LS": {
        "name": "Lesotho",
        "wiki": "https://en.wikipedia.org/wiki/Lesotho",
        "capital": "Maseru",
        "area": 30355,
        "population": {
            "value": 2031348,
            "year": 2004,
            "source": "census"
        }
    },
    "LR": {
        "name": "Liberia",
        "wiki": "https://en.wikipedia.org/wiki/Liberia",
        "capital": "Monrovia",
        "area": 111369,
        "population": {
            "value": 3476608,
            "year": 2008,
            "source": "census"
        }
    },
    "LY": {
        "name": "Libya",
        "wiki": "https://en.wikipedia.org/wiki/Libya",
        "capital": "Tripoli",
        "area": 1759541,
        "population": {
            "value": 5670688,
            "year": 2006,
            "source": "census"
        }
    },
    "LT": {
        "name": "Lithuania",
        "wiki": "https://en.wikipedia.org/wiki/Lithuania",
        "capital": "Vilnius",
        "area": 65300,
        "population": {
            "value": 2794329,
            "year": 2020,
            "source": "estimate"
        }
    },
    "LU": {
        "name": "Luxembourg",
        "wiki": "https://en.wikipedia.org/wiki/Luxembourg",
        "capital": "Luxembourg City",
        "area": 2586.4,
        "population": {
            "value": 512353,
            "year": 2011,
            "source": "census"
        }
    },
    "MK": {
        "name": "Macedonia",
        "wiki": "https://en.wikipedia.org/wiki/North_Macedonia",
        "capital": "Skopje",
        "area": 25713,
        "population": {
            "value": 2022547,
            "year": 2002,
            "source": "census"
        }
    },
    "MG": {
        "name": "Madagascar",
        "wiki": "https://en.wikipedia.org/wiki/Madagascar",
        "capital": "Antananarivo",
        "area": 587041,
        "population": {
            "value": 26262313,
            "year": 2018,
            "source": "estimate"
        }
    },
    "MW": {
        "name": "Malawi",
        "wiki": "https://en.wikipedia.org/wiki/Malawi",
        "capital": "Lilongwe",
        "area": 118484,
        "population": {
            "value": 17563749,
            "year": 2018,
            "source": "census"
        }
    },
    "MY": {
        "name": "Malaysia",
        "wiki": "https://en.wikipedia.org/wiki/Malaysia",
        "capital": "Kuala Lumpur",
        "area": 330803,
        "population": {
            "value": 28334135,
            "year": 2010,
            "source": "census"
        }
    },
    "ML": {
        "name": "Mali",
        "wiki": "https://en.wikipedia.org/wiki/Mali",
        "capital": "Bamako",
        "area": 1240192,
        "population": {
            "value": 19329841,
            "year": 2018,
            "source": "census"
        }
    },
    "MR": {
        "name": "Mauritania",
        "wiki": "https://en.wikipedia.org/wiki/Mauritania",
        "capital": "Nouakchott",
        "area": 1030000,
        "population": {
            "value": 3537368,
            "year": 2013,
            "source": "census"
        }
    },
    "MX": {
        "name": "Mexico",
        "wiki": "https://en.wikipedia.org/wiki/Mexico",
        "capital": "Mexico City",
        "area": 1972550,
        "population": {
            "value": 128649565,
            "year": 2020,
            "source": "estimate"
        }
    },
    "MD": {
        "name": "Moldova",
        "wiki": "https://en.wikipedia.org/wiki/Moldova",
        "capital": "Chișinău",
        "area": 33846,
        "population": {
            "value": 2681735,
            "year": 2019,
            "source": "estimate"
        }
    },
    "MN": {
        "name": "Mongolia",
        "wiki": "https://en.wikipedia.org/wiki/Mongolia",
        "capital": "Ulaanbaatar",
        "area": 1566000,
        "population": {
            "value": 3278290,
            "year": 2020,
            "source": "estimate"
        }
    },
    "ME": {
        "name": "Montenegro",
        "wiki": "https://en.wikipedia.org/wiki/Montenegro",
        "capital": "Podgorica",
        "area": 13812,
        "population": {
            "value": 631219,
            "year": 2020,
            "source": "estimate"
        }
    },
    "MA": {
        "name": "Morocco",
        "wiki": "https://en.wikipedia.org/wiki/Morocco",
        "capital": "Rabat",
        "area": 710850,
        "population": {
            "value": 33848242,
            "year": 2014,
            "source": "census"
        }
    },
    "MZ": {
        "name": "Mozambique",
        "wiki": "https://en.wikipedia.org/wiki/Mozambique",
        "capital": "Maputo",
        "area": 801590,
        "population": {
            "value": 27909798,
            "year": 2017,
            "source": "census"
        }
    },
    "MM": {
        "name": "Myanmar",
        "wiki": "https://en.wikipedia.org/wiki/Myanmar",
        "capital": "Naypyidaw",
        "area": 676578,
        "population": {
            "value": 53582855,
            "year": 2017,
            "source": "census"
        }
    },
    "NA": {
        "name": "Namibia",
        "wiki": "https://en.wikipedia.org/wiki/Namibia",
        "capital": "Windhoek",
        "area": 825615,
        "population": {
            "value": 2113077,
            "year": 2011,
            "source": "census"
        }
    },
    "NP": {
        "name": "Nepal",
        "wiki": "https://en.wikipedia.org/wiki/Nepal",
        "capital": "Kathmandu",
        "area": 147181,
        "population": {
            "value": 26494504,
            "year": 2011,
            "source": "census"
        }
    },
    "NL": {
        "name": "Netherlands",
        "wiki": "https://en.wikipedia.org/wiki/Netherlands",
        "capital": "Amsterdam",
        "area": 41865,
        "population": {
            "value": 17424978,
            "year": 2019,
            "source": "estimate"
        }
    },
    "NC": {
        "name": "New Caledonia",
        "wiki": "https://en.wikipedia.org/wiki/New_Caledonia",
        "capital": "Nouméa",
        "area": 18576,
        "population": {
            "value": 271407,
            "year": 2019,
            "source": "census"
        }
    },
    "NZ": {
        "name": "New Zealand",
        "wiki": "https://en.wikipedia.org/wiki/New_Zealand",
        "capital": "Wellington",
        "area": 268021,
        "population": {
            "value": 4699755,
            "year": 2018,
            "source": "census"
        }
    },
    "NI": {
        "name": "Nicaragua",
        "wiki": "https://en.wikipedia.org/wiki/Nicaragua",
        "capital": "Managua",
        "area": 130375,
        "population": {
            "value": 6167237,
            "year": 2012,
            "source": "census"
        }
    },
    "NE": {
        "name": "Niger",
        "wiki": "https://en.wikipedia.org/wiki/Niger",
        "capital": "Niamey",
        "area": 1267000,
        "population": {
            "value": 17138707,
            "year": 2012,
            "source": "census"
        }
    },
    "NG": {
        "name": "Nigeria",
        "wiki": "https://en.wikipedia.org/wiki/Nigeria",
        "capital": "Abuja",
        "area": 923768,
        "population": {
            "value": 140431790,
            "year": 2006,
            "source": "census"
        }
    },
    "NO": {
        "name": "Norway",
        "wiki": "https://en.wikipedia.org/wiki/Norway",
        "capital": "Oslo",
        "area": 385207,
        "population": {
            "value": 5367580,
            "year": 2020,
            "source": "estimate"
        }
    },
    "OM": {
        "name": "Oman",
        "wiki": "https://en.wikipedia.org/wiki/Oman",
        "capital": "Muscat",
        "area": 309500,
        "population": {
            "value": 2773479,
            "year": 2010,
            "source": "census"
        }
    },
    "PK": {
        "name": "Pakistan",
        "wiki": "https://en.wikipedia.org/wiki/Pakistan",
        "capital": "Islamabad",
        "area": 881913,
        "population": {
            "value": 207800000,
            "year": 2017,
            "source": "census"
        }
    },
    "PS": {
        "name": "Palestine",
        "wiki": "https://en.wikipedia.org/wiki/State_of_Palestine",
        "capital": "Jerusalem (Proclaimed capital), Ramallah (Administrative center)",
        "area": 6020,
        "population": {
            "value": 5051953,
            "year": 2020,
            "source": "estimate"
        }
    },
    "PA": {
        "name": "Panama",
        "wiki": "https://en.wikipedia.org/wiki/Panama",
        "capital": "Panama City",
        "area": 75417,
        "population": {
            "value": 3405813,
            "year": 2010,
            "source": "census"
        }
    },
    "PG": {
        "name": "Papua New Guinea",
        "wiki": "https://en.wikipedia.org/wiki/Papua_New_Guinea",
        "capital": "Port Moresby",
        "area": 462840,
        "population": {
            "value": 7275324,
            "year": 2011,
            "source": "census"
        }
    },
    "PY": {
        "name": "Paraguay",
        "wiki": "https://en.wikipedia.org/wiki/Paraguay",
        "capital": "Asunción",
        "area": 406752,
        "population": {
            "value": 7252672,
            "year": 2020,
            "source": "estimate"
        }
    },
    "PE": {
        "name": "Peru",
        "wiki": "https://en.wikipedia.org/wiki/Peru",
        "capital": "Lima",
        "area": 1285216,
        "population": {
            "value": 31237385,
            "year": 2017,
            "source": "census"
        }
    },
    "PH": {
        "name": "Philippines",
        "wiki": "https://en.wikipedia.org/wiki/Philippines",
        "capital": "Manila",
        "area": 300000,
        "population": {
            "value": 100981437,
            "year": 2015,
            "source": "census"
        }
    },
    "PL": {
        "name": "Poland",
        "wiki": "https://en.wikipedia.org/wiki/Poland",
        "capital": "Warsaw",
        "area": 312696,
        "population": {
            "value": 38386000,
            "year": 2019,
            "source": "estimate"
        }
    },
    "PT": {
        "name": "Portugal",
        "wiki": "https://en.wikipedia.org/wiki/Portugal",
        "capital": "Lisbon",
        "area": 92212,
        "population": {
            "value": 10562178,
            "year": 2011,
            "source": "census"
        }
    },
    "PR": {
        "name": "Puerto Rico",
        "wiki": "https://en.wikipedia.org/wiki/Puerto_Rico",
        "capital": "San Juan",
        "area": 9104,
        "population": {
            "value": 3725789,
            "year": 2010,
            "source": "census"
        }
    },
    "QA": {
        "name": "Qatar",
        "wiki": "https://en.wikipedia.org/wiki/Qatar",
        "capital": "Doha",
        "area": 11581,
        "population": {
            "value": 1699435,
            "year": 2010,
            "source": "census"
        }
    },
    "RO": {
        "name": "Romania",
        "wiki": "https://en.wikipedia.org/wiki/Romania",
        "capital": "Bucharest",
        "area": 238397,
        "population": {
            "value": 20121641,
            "year": 2011,
            "source": "census"
        }
    },
    "RU": {
        "name": "Russia",
        "wiki": "https://en.wikipedia.org/wiki/Russia",
        "capital": "Moscow",
        "area": 17098246,
        "population": {
            "value": 146745098,
            "year": 2020,
            "source": "estimate"
        }
    },
    "RW": {
        "name": "Rwanda",
        "wiki": "https://en.wikipedia.org/wiki/Rwanda",
        "capital": "Kigali",
        "area": 26338,
        "population": {
            "value": 10515973,
            "year": 2012,
            "source": "census"
        }
    },
    "SS": {
        "name": "S. Sudan",
        "wiki": "https://en.wikipedia.org/wiki/South_Sudan",
        "capital": "Juba",
        "area": 619745,
        "population": {
            "value": 8260490,
            "year": 2008,
            "source": "census"
        }
    },
    "SA": {
        "name": "Saudi Arabia",
        "wiki": "https://en.wikipedia.org/wiki/Saudi_Arabia",
        "capital": "Riyadh",
        "area": 2149690,
        "population": {
            "value": 34218169,
            "year": 2019,
            "source": "estimate"
        }
    },
    "SN": {
        "name": "Senegal",
        "wiki": "https://en.wikipedia.org/wiki/Senegal",
        "capital": "Dakar",
        "area": 196712,
        "population": {
            "value": 14668522,
            "year": 2016,
            "source": "census"
        }
    },
    "RS": {
        "name": "Serbia",
        "wiki": "https://en.wikipedia.org/wiki/Serbia",
        "capital": "Belgrade",
        "area": 77474,
        "population": {
            "value": 6963764,
            "year": 2019,
            "source": "estimate"
        }
    },
    "SL": {
        "name": "Sierra Leone",
        "wiki": "https://en.wikipedia.org/wiki/Sierra_Leone",
        "capital": "Freetown",
        "area": 71740,
        "population": {
            "value": 7092113,
            "year": 2015,
            "source": "census"
        }
    },
    "SK": {
        "name": "Slovakia",
        "wiki": "https://en.wikipedia.org/wiki/Slovakia",
        "capital": "Bratislava",
        "area": 49035,
        "population": {
            "value": 5397036,
            "year": 2011,
            "source": "census"
        }
    },
    "SI": {
        "name": "Slovenia",
        "wiki": "https://en.wikipedia.org/wiki/Slovenia",
        "capital": "Ljubljana",
        "area": 20273,
        "population": {
            "value": 1964036,
            "year": 2002,
            "source": "census"
        }
    },
    "SB": {
        "name": "Solomon Is.",
        "wiki": "https://en.wikipedia.org/wiki/Solomon_Islands",
        "capital": "Honiara",
        "area": 28400,
        "population": {
            "value": 652857,
            "year": 2018,
            "source": "estimate"
        }
    },
    "SO": {
        "name": "Somalia",
        "wiki": "https://en.wikipedia.org/wiki/Somalia",
        "capital": "Mogadishu",
        "area": 637657,
        "population": {
            "value": 11031386,
            "year": 2017,
            "source": "estimate"
        }
    },
    "SH": {
        "name": "Somaliland",
        "wiki": "https://en.wikipedia.org/wiki/Somaliland",
        "capital": "Hargeisa",
        "area": 176120,
        "population": {
            "value": 3508180,
            "year": 2017,
            "source": "estimate"
        }
    },
    "ZA": {
        "name": "South Africa",
        "wiki": "https://en.wikipedia.org/wiki/South_Africa",
        "capital": "Pretoria (executive), Bloemfontein (judicial), Cape Town (legislative)",
        "area": 1221037,
        "population": {
            "value": 51770560,
            "year": 2011,
            "source": "census"
        }
    },
    "ES": {
        "name": "Spain",
        "wiki": "https://en.wikipedia.org/wiki/Spain",
        "capital": "Madrid",
        "area": 505990,
        "population": {
            "value": 47007367,
            "year": 2019,
            "source": "estimate"
        }
    },
    "LK": {
        "name": "Sri Lanka",
        "wiki": "https://en.wikipedia.org/wiki/Sri_Lanka",
        "capital": "Sri Jayawardenepura Kotte",
        "area": 65610,
        "population": {
            "value": 20277597,
            "year": 2012,
            "source": "census"
        }
    },
    "SD": {
        "name": "Sudan",
        "wiki": "https://en.wikipedia.org/wiki/Sudan",
        "capital": "Khartoum",
        "area": 1886068,
        "population": {
            "value": 30894000,
            "year": 2008,
            "source": "census"
        }
    },
    "SR": {
        "name": "Suriname",
        "wiki": "https://en.wikipedia.org/wiki/Suriname",
        "capital": "Paramaribo",
        "area": 163821,
        "population": {
            "value": 541638,
            "year": 2012,
            "source": "census"
        }
    },
    "SZ": {
        "name": "Swaziland",
        "wiki": "https://en.wikipedia.org/wiki/Eswatini",
        "capital": "Mbabane (executive), Lobamba (legislative)",
        "area": 17364,
        "population": {
            "value": 1093238,
            "year": 2017,
            "source": "census"
        }
    },
    "SE": {
        "name": "Sweden",
        "wiki": "https://en.wikipedia.org/wiki/Sweden",
        "capital": "Stockholm",
        "area": 450295,
        "population": {
            "value": 10333456,
            "year": 2020,
            "source": "census"
        }
    },
    "CH": {
        "name": "Switzerland",
        "wiki": "https://en.wikipedia.org/wiki/Switzerland",
        "capital": "Bern",
        "area": 41285,
        "population": {
            "value": 8327126,
            "year": 2015,
            "source": "census"
        }
    },
    "SY": {
        "name": "Syria",
        "wiki": "https://en.wikipedia.org/wiki/Syria",
        "capital": "Damascus",
        "area": 185180,
        "population": {
            "value": 21018834,
            "year": 2010,
            "source": "census"
        }
    },
    "TW": {
        "name": "Taiwan",
        "wiki": "https://en.wikipedia.org/wiki/Taiwan",
        "capital": "Taipei",
        "area": 36197,
        "population": {
            "value": 23123866,
            "year": 2010,
            "source": "census"
        }
    },
    "TJ": {
        "name": "Tajikistan",
        "wiki": "https://en.wikipedia.org/wiki/Tajikistan",
        "capital": "Dushanbe",
        "area": 143100,
        "population": {
            "value": 9275827,
            "year": 2019,
            "source": "estimate"
        }
    },
    "TZ": {
        "name": "Tanzania",
        "wiki": "https://en.wikipedia.org/wiki/Tanzania",
        "capital": "Dodoma",
        "area": 947303,
        "population": {
            "value": 44928923,
            "year": 2012,
            "source": "census"
        }
    },
    "TH": {
        "name": "Thailand",
        "wiki": "https://en.wikipedia.org/wiki/Thailand",
        "capital": "Bangkok",
        "area": 513120,
        "population": {
            "value": 64785909,
            "year": 2010,
            "source": "census"
        }
    },
    "TL": {
        "name": "Timor-Leste",
        "wiki": "https://en.wikipedia.org/wiki/East_Timor",
        "capital": "Dili",
        "area": 15007,
        "population": {
            "value": 1183643,
            "year": 2015,
            "source": "census"
        }
    },
    "TG": {
        "name": "Togo",
        "wiki": "https://en.wikipedia.org/wiki/Togo",
        "capital": "Lomé",
        "area": 56785,
        "population": {
            "value": 6191155,
            "year": 2010,
            "source": "census"
        }
    },
    "TT": {
        "name": "Trinidad and Tobago",
        "wiki": "https://en.wikipedia.org/wiki/Trinidad_and_Tobago",
        "capital": "Port of Spain",
        "area": 5131,
        "population": {
            "value": 1328019,
            "year": 2011,
            "source": "census"
        }
    },
    "TN": {
        "name": "Tunisia",
        "wiki": "https://en.wikipedia.org/wiki/Tunisia",
        "capital": "Tunis",
        "area": 163610,
        "population": {
            "value": 11722038,
            "year": 2019,
            "source": "estimate"
        }
    },
    "TR": {
        "name": "Turkey",
        "wiki": "https://en.wikipedia.org/wiki/Turkey",
        "capital": "Ankara",
        "area": 783356,
        "population": {
            "value": 83154997,
            "year": 2019,
            "source": "estimate"
        }
    },
    "TM": {
        "name": "Turkmenistan",
        "wiki": "https://en.wikipedia.org/wiki/Turkmenistan",
        "capital": "Ashgabat",
        "area": 491210,
        "population": {
            "value": 5850901,
            "year": 2018,
            "source": "estimate"
        }
    },
    "UG": {
        "name": "Uganda",
        "wiki": "https://en.wikipedia.org/wiki/Uganda",
        "capital": "Kampala",
        "area": 241038,
        "population": {
            "value": 34634650,
            "year": 2014,
            "source": "census"
        }
    },
    "UA": {
        "name": "Ukraine",
        "wiki": "https://en.wikipedia.org/wiki/Ukraine",
        "capital": "Kiev",
        "area": 603628,
        "population": {
            "value": 48457102,
            "year": 2001,
            "source": "census"
        }
    },
    "AE": {
        "name": "United Arab Emirates",
        "wiki": "https://en.wikipedia.org/wiki/United_Arab_Emirates",
        "capital": "Abu Dhabi",
        "area": 83600,
        "population": {
            "value": 4106427,
            "year": 2005,
            "source": "census"
        }
    },
    "GB": {
        "name": "United Kingdom",
        "wiki": "https://en.wikipedia.org/wiki/United_Kingdom",
        "capital": "London",
        "area": 242495,
        "population": {
            "value": 63182178,
            "year": 2011,
            "source": "census"
        }
    },
    "US": {
        "name": "United States",
        "wiki": "https://en.wikipedia.org/wiki/United_States",
        "capital": "Washington, D.C.",
        "area": 9833520,
        "population": {
            "value": 308745538,
            "year": 2010,
            "source": "census"
        }
    },
    "UY": {
        "name": "Uruguay",
        "wiki": "https://en.wikipedia.org/wiki/Uruguay",
        "capital": "Montevideo",
        "area": 176215,
        "population": {
            "value": 3390077,
            "year": 2011,
            "source": "census"
        }
    },
    "UZ": {
        "name": "Uzbekistan",
        "wiki": "https://en.wikipedia.org/wiki/Uzbekistan",
        "capital": "Tashkent",
        "area": 448978,
        "population": {
            "value": 33961708,
            "year": 2020,
            "source": "estimate"
        }
    },
    "VU": {
        "name": "Vanuatu",
        "wiki": "https://en.wikipedia.org/wiki/Vanuatu",
        "capital": "Port Vila",
        "area": 12189,
        "population": {
            "value": 272459,
            "year": 2016,
            "source": "census"
        }
    },
    "VE": {
        "name": "Venezuela",
        "wiki": "https://en.wikipedia.org/wiki/Venezuela",
        "capital": "Caracas",
        "area": 916445,
        "population": {
            "value": 28887118,
            "year": 2018,
            "source": "estimate"
        }
    },
    "VN": {
        "name": "Vietnam",
        "wiki": "https://en.wikipedia.org/wiki/Vietnam",
        "capital": "Hanoi",
        "area": 331212,
        "population": {
            "value": 95545962,
            "year": 2018,
            "source": "estimate"
        }
    },
    "EH": {
        "name": "W. Sahara",
        "wiki": "https://en.wikipedia.org/wiki/Western_Sahara",
        "capital": "",
        "area": 266000,
        "population": {
            "value": 567402,
            "year": -99,
            "source": "0"
        }
    },
    "YE": {
        "name": "Yemen",
        "wiki": "https://en.wikipedia.org/wiki/Yemen",
        "capital": "Sana'a (de jure), Aden (provisional)",
        "area": 527968,
        "population": {
            "value": 19685000,
            "year": 2004,
            "source": "census"
        }
    },
    "ZM": {
        "name": "Zambia",
        "wiki": "https://en.wikipedia.org/wiki/Zambia",
        "capital": "Lusaka",
        "area": 752618,
        "population": {
            "value": 13092666,
            "year": 2010,
            "source": "census"
        }
    },
    "ZW": {
        "name": "Zimbabwe",
        "wiki": "https://en.wikipedia.org/wiki/Zimbabwe",
        "capital": "Harare",
        "area": 390757,
        "population": {
            "value": 12973808,
            "year": 2012,
            "source": "census"
        }
    }
}