export const colors = [
    '#FFA07A',
    '#FA8072',
    '#CD5C5C',
    '#DC143C',
    '#B22222',
    '#FF0000',
    '#8B0000',
    '#FF7F50',
    '#FF6347',
    '#FF4500',
    '#FFD700',
    '#FFA500',
    '#FF8C00',
    '#FFE4B5',
    '#FFDAB9',
    '#F0E68C',
    '#BDB76B',
    '#32CD32',
    '#008000',
    '#006400',
    '#8FBC8F',
    '#3CB371',
    '#2E8B57',
    '#808000',
    '#556B2F',
    '#6B8E23',
    '#7FFFD4',
    '#66CDAA',
    '#AFEEEE',
    '#40E0D0',
    '#48D1CC',
    '#20B2AA',
    '#5F9EA0',
    '#008B8B',
    '#ADD8E6',
    '#87CEFA',
    '#00BFFF',
    '#1E90FF',
    '#6495ED',
    '#4682B4',
    '#4169E1',
    '#0000FF',
    '#000080',
    '#6A5ACD',
    '#483D8B',
    '#E6E6FA',
    '#D8BFD8',
    '#EE82EE',
    '#DA70D6',
    '#BA55D3',
    '#9370DB',
    '#8A2BE2',
    '#9400D3',
    '#9932CC',
    '#800080',
    '#4B0082',
    '#FFC0CB',
    '#FF69B4',
    '#FF1493',
    '#DB7093',
    '#C71585',
    '#C0C0C0',
    '#A9A9A9',
    '#808080',
    '#696969',
    '#708090',
    '#2F4F4F',
    '#FFDEAD',
    '#F5DEB3',
    '#DEB887',
    '#D2B48C',
    '#BC8F8F',
    '#F4A460',
    '#CD853F',
    '#D2691E',
    '#8B4513',
    '#A52A2A',
    '#800000'    
];
