export const countries = {
    "Afghanistan": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Afghanistan",
        "sov_a3": "AFG",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Afghanistan",
        "adm0_a3": "AFG",
        "geou_dif": 0,
        "geounit": "Afghanistan",
        "gu_a3": "AFG",
        "su_dif": 0,
        "subunit": "Afghanistan",
        "su_a3": "AFG",
        "brk_diff": 0,
        "name": "Afghanistan",
        "name_long": "Afghanistan",
        "brk_a3": "AFG",
        "brk_name": "Afghanistan",
        "brk_group": "",
        "abbrev": "Afg.",
        "postal": "AF",
        "formal_en": "Islamic State of Afghanistan",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Afghanistan",
        "name_alt": "",
        "mapcolor7": 5,
        "mapcolor8": 6,
        "mapcolor9": 8,
        "mapcolor13": 7,
        "pop_est": 28400000,
        "gdp_md_est": 22270,
        "pop_year": -99,
        "lastcensus": 1979,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "AF",
        "iso_a3": "AFG",
        "iso_n3": "004",
        "un_a3": "004",
        "wb_a2": "AF",
        "wb_a3": "AFG",
        "woe_id": -99,
        "adm0_a3_is": "AFG",
        "adm0_a3_us": "AFG",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Southern Asia",
        "region_wb": "South Asia",
        "name_len": 11,
        "long_len": 11,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Angola": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Angola",
        "sov_a3": "AGO",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Angola",
        "adm0_a3": "AGO",
        "geou_dif": 0,
        "geounit": "Angola",
        "gu_a3": "AGO",
        "su_dif": 0,
        "subunit": "Angola",
        "su_a3": "AGO",
        "brk_diff": 0,
        "name": "Angola",
        "name_long": "Angola",
        "brk_a3": "AGO",
        "brk_name": "Angola",
        "brk_group": "",
        "abbrev": "Ang.",
        "postal": "AO",
        "formal_en": "People's Republic of Angola",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Angola",
        "name_alt": "",
        "mapcolor7": 3,
        "mapcolor8": 2,
        "mapcolor9": 6,
        "mapcolor13": 1,
        "pop_est": 12799293,
        "gdp_md_est": 110300,
        "pop_year": -99,
        "lastcensus": 1970,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "AO",
        "iso_a3": "AGO",
        "iso_n3": "024",
        "un_a3": "024",
        "wb_a2": "AO",
        "wb_a3": "AGO",
        "woe_id": -99,
        "adm0_a3_is": "AGO",
        "adm0_a3_us": "AGO",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Middle Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 6,
        "long_len": 6,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Albania": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 6,
        "sovereignt": "Albania",
        "sov_a3": "ALB",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Albania",
        "adm0_a3": "ALB",
        "geou_dif": 0,
        "geounit": "Albania",
        "gu_a3": "ALB",
        "su_dif": 0,
        "subunit": "Albania",
        "su_a3": "ALB",
        "brk_diff": 0,
        "name": "Albania",
        "name_long": "Albania",
        "brk_a3": "ALB",
        "brk_name": "Albania",
        "brk_group": "",
        "abbrev": "Alb.",
        "postal": "AL",
        "formal_en": "Republic of Albania",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Albania",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 4,
        "mapcolor9": 1,
        "mapcolor13": 6,
        "pop_est": 3639453,
        "gdp_md_est": 21810,
        "pop_year": -99,
        "lastcensus": 2001,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "AL",
        "iso_a3": "ALB",
        "iso_n3": "008",
        "un_a3": "008",
        "wb_a2": "AL",
        "wb_a3": "ALB",
        "woe_id": -99,
        "adm0_a3_is": "ALB",
        "adm0_a3_us": "ALB",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Southern Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "United Arab Emirates": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 4,
        "sovereignt": "United Arab Emirates",
        "sov_a3": "ARE",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "United Arab Emirates",
        "adm0_a3": "ARE",
        "geou_dif": 0,
        "geounit": "United Arab Emirates",
        "gu_a3": "ARE",
        "su_dif": 0,
        "subunit": "United Arab Emirates",
        "su_a3": "ARE",
        "brk_diff": 0,
        "name": "United Arab Emirates",
        "name_long": "United Arab Emirates",
        "brk_a3": "ARE",
        "brk_name": "United Arab Emirates",
        "brk_group": "",
        "abbrev": "U.A.E.",
        "postal": "AE",
        "formal_en": "United Arab Emirates",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "United Arab Emirates",
        "name_alt": "",
        "mapcolor7": 2,
        "mapcolor8": 1,
        "mapcolor9": 3,
        "mapcolor13": 3,
        "pop_est": 4798491,
        "gdp_md_est": 184300,
        "pop_year": -99,
        "lastcensus": 2010,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "2. High income: nonOECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "AE",
        "iso_a3": "ARE",
        "iso_n3": "784",
        "un_a3": "784",
        "wb_a2": "AE",
        "wb_a3": "ARE",
        "woe_id": -99,
        "adm0_a3_is": "ARE",
        "adm0_a3_us": "ARE",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Western Asia",
        "region_wb": "Middle East & North Africa",
        "name_len": 20,
        "long_len": 20,
        "abbrev_len": 6,
        "tiny": -99,
        "homepart": 1
    },
    "Argentina": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "Argentina",
        "sov_a3": "ARG",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Argentina",
        "adm0_a3": "ARG",
        "geou_dif": 0,
        "geounit": "Argentina",
        "gu_a3": "ARG",
        "su_dif": 0,
        "subunit": "Argentina",
        "su_a3": "ARG",
        "brk_diff": 0,
        "name": "Argentina",
        "name_long": "Argentina",
        "brk_a3": "ARG",
        "brk_name": "Argentina",
        "brk_group": "",
        "abbrev": "Arg.",
        "postal": "AR",
        "formal_en": "Argentine Republic",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Argentina",
        "name_alt": "",
        "mapcolor7": 3,
        "mapcolor8": 1,
        "mapcolor9": 3,
        "mapcolor13": 13,
        "pop_est": 40913584,
        "gdp_md_est": 573900,
        "pop_year": -99,
        "lastcensus": 2010,
        "gdp_year": -99,
        "economy": "5. Emerging region: G20",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "AR",
        "iso_a3": "ARG",
        "iso_n3": "032",
        "un_a3": "032",
        "wb_a2": "AR",
        "wb_a3": "ARG",
        "woe_id": -99,
        "adm0_a3_is": "ARG",
        "adm0_a3_us": "ARG",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "South America",
        "region_un": "Americas",
        "subregion": "South America",
        "region_wb": "Latin America & Caribbean",
        "name_len": 9,
        "long_len": 9,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Armenia": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 6,
        "sovereignt": "Armenia",
        "sov_a3": "ARM",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Armenia",
        "adm0_a3": "ARM",
        "geou_dif": 0,
        "geounit": "Armenia",
        "gu_a3": "ARM",
        "su_dif": 0,
        "subunit": "Armenia",
        "su_a3": "ARM",
        "brk_diff": 0,
        "name": "Armenia",
        "name_long": "Armenia",
        "brk_a3": "ARM",
        "brk_name": "Armenia",
        "brk_group": "",
        "abbrev": "Arm.",
        "postal": "ARM",
        "formal_en": "Republic of Armenia",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Armenia",
        "name_alt": "",
        "mapcolor7": 3,
        "mapcolor8": 1,
        "mapcolor9": 2,
        "mapcolor13": 10,
        "pop_est": 2967004,
        "gdp_md_est": 18770,
        "pop_year": -99,
        "lastcensus": 2001,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "AM",
        "iso_a3": "ARM",
        "iso_n3": "051",
        "un_a3": "051",
        "wb_a2": "AM",
        "wb_a3": "ARM",
        "woe_id": -99,
        "adm0_a3_is": "ARM",
        "adm0_a3_us": "ARM",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Western Asia",
        "region_wb": "Europe & Central Asia",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Antarctica": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 4,
        "sovereignt": "Antarctica",
        "sov_a3": "ATA",
        "adm0_dif": 0,
        "level": 2,
        "type": "Indeterminate",
        "admin": "Antarctica",
        "adm0_a3": "ATA",
        "geou_dif": 0,
        "geounit": "Antarctica",
        "gu_a3": "ATA",
        "su_dif": 0,
        "subunit": "Antarctica",
        "su_a3": "ATA",
        "brk_diff": 0,
        "name": "Antarctica",
        "name_long": "Antarctica",
        "brk_a3": "ATA",
        "brk_name": "Antarctica",
        "brk_group": "",
        "abbrev": "Ant.",
        "postal": "AQ",
        "formal_en": "",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "Multiple claims held in abeyance",
        "name_sort": "Antarctica",
        "name_alt": "",
        "mapcolor7": 4,
        "mapcolor8": 5,
        "mapcolor9": 1,
        "mapcolor13": -99,
        "pop_est": 3802,
        "gdp_md_est": 760.4,
        "pop_year": -99,
        "lastcensus": -99,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "2. High income: nonOECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "AQ",
        "iso_a3": "ATA",
        "iso_n3": "010",
        "un_a3": "-099",
        "wb_a2": "-99",
        "wb_a3": "-99",
        "woe_id": -99,
        "adm0_a3_is": "ATA",
        "adm0_a3_us": "ATA",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Antarctica",
        "region_un": "Antarctica",
        "subregion": "Antarctica",
        "region_wb": "Antarctica",
        "name_len": 10,
        "long_len": 10,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Fr. S. Antarctic Lands": {
        "scalerank": 3,
        "featurecla": "Admin-0 country",
        "labelrank": 6,
        "sovereignt": "France",
        "sov_a3": "FR1",
        "adm0_dif": 1,
        "level": 2,
        "type": "Dependency",
        "admin": "French Southern and Antarctic Lands",
        "adm0_a3": "ATF",
        "geou_dif": 0,
        "geounit": "French Southern and Antarctic Lands",
        "gu_a3": "ATF",
        "su_dif": 0,
        "subunit": "French Southern and Antarctic Lands",
        "su_a3": "ATF",
        "brk_diff": 0,
        "name": "Fr. S. Antarctic Lands",
        "name_long": "French Southern and Antarctic Lands",
        "brk_a3": "ATF",
        "brk_name": "Fr. S. and Antarctic Lands",
        "brk_group": "",
        "abbrev": "Fr. S.A.L.",
        "postal": "TF",
        "formal_en": "Territory of the French Southern and Antarctic Lands",
        "formal_fr": "",
        "note_adm0": "Fr.",
        "note_brk": "",
        "name_sort": "French Southern and Antarctic Lands",
        "name_alt": "",
        "mapcolor7": 7,
        "mapcolor8": 5,
        "mapcolor9": 9,
        "mapcolor13": 11,
        "pop_est": 140,
        "gdp_md_est": 16,
        "pop_year": -99,
        "lastcensus": -99,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "2. High income: nonOECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "TF",
        "iso_a3": "ATF",
        "iso_n3": "260",
        "un_a3": "-099",
        "wb_a2": "-99",
        "wb_a3": "-99",
        "woe_id": -99,
        "adm0_a3_is": "ATF",
        "adm0_a3_us": "ATF",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Seven seas (open ocean)",
        "region_un": "Seven seas (open ocean)",
        "subregion": "Seven seas (open ocean)",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 22,
        "long_len": 35,
        "abbrev_len": 10,
        "tiny": 2,
        "homepart": -99
    },
    "Australia": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "Australia",
        "sov_a3": "AU1",
        "adm0_dif": 1,
        "level": 2,
        "type": "Country",
        "admin": "Australia",
        "adm0_a3": "AUS",
        "geou_dif": 0,
        "geounit": "Australia",
        "gu_a3": "AUS",
        "su_dif": 0,
        "subunit": "Australia",
        "su_a3": "AUS",
        "brk_diff": 0,
        "name": "Australia",
        "name_long": "Australia",
        "brk_a3": "AUS",
        "brk_name": "Australia",
        "brk_group": "",
        "abbrev": "Auz.",
        "postal": "AU",
        "formal_en": "Commonwealth of Australia",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Australia",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 2,
        "mapcolor9": 2,
        "mapcolor13": 7,
        "pop_est": 21262641,
        "gdp_md_est": 800200,
        "pop_year": -99,
        "lastcensus": 2006,
        "gdp_year": -99,
        "economy": "2. Developed region: nonG7",
        "income_grp": "1. High income: OECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "AU",
        "iso_a3": "AUS",
        "iso_n3": "036",
        "un_a3": "036",
        "wb_a2": "AU",
        "wb_a3": "AUS",
        "woe_id": -99,
        "adm0_a3_is": "AUS",
        "adm0_a3_us": "AUS",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Oceania",
        "region_un": "Oceania",
        "subregion": "Australia and New Zealand",
        "region_wb": "East Asia & Pacific",
        "name_len": 9,
        "long_len": 9,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Austria": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 4,
        "sovereignt": "Austria",
        "sov_a3": "AUT",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Austria",
        "adm0_a3": "AUT",
        "geou_dif": 0,
        "geounit": "Austria",
        "gu_a3": "AUT",
        "su_dif": 0,
        "subunit": "Austria",
        "su_a3": "AUT",
        "brk_diff": 0,
        "name": "Austria",
        "name_long": "Austria",
        "brk_a3": "AUT",
        "brk_name": "Austria",
        "brk_group": "",
        "abbrev": "Aust.",
        "postal": "A",
        "formal_en": "Republic of Austria",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Austria",
        "name_alt": "",
        "mapcolor7": 3,
        "mapcolor8": 1,
        "mapcolor9": 3,
        "mapcolor13": 4,
        "pop_est": 8210281,
        "gdp_md_est": 329500,
        "pop_year": -99,
        "lastcensus": 2011,
        "gdp_year": -99,
        "economy": "2. Developed region: nonG7",
        "income_grp": "1. High income: OECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "AT",
        "iso_a3": "AUT",
        "iso_n3": "040",
        "un_a3": "040",
        "wb_a2": "AT",
        "wb_a3": "AUT",
        "woe_id": -99,
        "adm0_a3_is": "AUT",
        "adm0_a3_us": "AUT",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Western Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "Azerbaijan": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 5,
        "sovereignt": "Azerbaijan",
        "sov_a3": "AZE",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Azerbaijan",
        "adm0_a3": "AZE",
        "geou_dif": 0,
        "geounit": "Azerbaijan",
        "gu_a3": "AZE",
        "su_dif": 0,
        "subunit": "Azerbaijan",
        "su_a3": "AZE",
        "brk_diff": 0,
        "name": "Azerbaijan",
        "name_long": "Azerbaijan",
        "brk_a3": "AZE",
        "brk_name": "Azerbaijan",
        "brk_group": "",
        "abbrev": "Aze.",
        "postal": "AZ",
        "formal_en": "Republic of Azerbaijan",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Azerbaijan",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 6,
        "mapcolor9": 5,
        "mapcolor13": 8,
        "pop_est": 8238672,
        "gdp_md_est": 77610,
        "pop_year": -99,
        "lastcensus": 2009,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "AZ",
        "iso_a3": "AZE",
        "iso_n3": "031",
        "un_a3": "031",
        "wb_a2": "AZ",
        "wb_a3": "AZE",
        "woe_id": -99,
        "adm0_a3_is": "AZE",
        "adm0_a3_us": "AZE",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Western Asia",
        "region_wb": "Europe & Central Asia",
        "name_len": 10,
        "long_len": 10,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Burundi": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 6,
        "sovereignt": "Burundi",
        "sov_a3": "BDI",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Burundi",
        "adm0_a3": "BDI",
        "geou_dif": 0,
        "geounit": "Burundi",
        "gu_a3": "BDI",
        "su_dif": 0,
        "subunit": "Burundi",
        "su_a3": "BDI",
        "brk_diff": 0,
        "name": "Burundi",
        "name_long": "Burundi",
        "brk_a3": "BDI",
        "brk_name": "Burundi",
        "brk_group": "",
        "abbrev": "Bur.",
        "postal": "BI",
        "formal_en": "Republic of Burundi",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Burundi",
        "name_alt": "",
        "mapcolor7": 2,
        "mapcolor8": 2,
        "mapcolor9": 5,
        "mapcolor13": 8,
        "pop_est": 8988091,
        "gdp_md_est": 3102,
        "pop_year": -99,
        "lastcensus": 2008,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "BI",
        "iso_a3": "BDI",
        "iso_n3": "108",
        "un_a3": "108",
        "wb_a2": "BI",
        "wb_a3": "BDI",
        "woe_id": -99,
        "adm0_a3_is": "BDI",
        "adm0_a3_us": "BDI",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Eastern Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Belgium": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "Belgium",
        "sov_a3": "BEL",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Belgium",
        "adm0_a3": "BEL",
        "geou_dif": 0,
        "geounit": "Belgium",
        "gu_a3": "BEL",
        "su_dif": 0,
        "subunit": "Belgium",
        "su_a3": "BEL",
        "brk_diff": 0,
        "name": "Belgium",
        "name_long": "Belgium",
        "brk_a3": "BEL",
        "brk_name": "Belgium",
        "brk_group": "",
        "abbrev": "Belg.",
        "postal": "B",
        "formal_en": "Kingdom of Belgium",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Belgium",
        "name_alt": "",
        "mapcolor7": 3,
        "mapcolor8": 2,
        "mapcolor9": 1,
        "mapcolor13": 8,
        "pop_est": 10414336,
        "gdp_md_est": 389300,
        "pop_year": -99,
        "lastcensus": 2011,
        "gdp_year": -99,
        "economy": "2. Developed region: nonG7",
        "income_grp": "1. High income: OECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "BE",
        "iso_a3": "BEL",
        "iso_n3": "056",
        "un_a3": "056",
        "wb_a2": "BE",
        "wb_a3": "BEL",
        "woe_id": -99,
        "adm0_a3_is": "BEL",
        "adm0_a3_us": "BEL",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Western Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "Benin": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 5,
        "sovereignt": "Benin",
        "sov_a3": "BEN",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Benin",
        "adm0_a3": "BEN",
        "geou_dif": 0,
        "geounit": "Benin",
        "gu_a3": "BEN",
        "su_dif": 0,
        "subunit": "Benin",
        "su_a3": "BEN",
        "brk_diff": 0,
        "name": "Benin",
        "name_long": "Benin",
        "brk_a3": "BEN",
        "brk_name": "Benin",
        "brk_group": "",
        "abbrev": "Benin",
        "postal": "BJ",
        "formal_en": "Republic of Benin",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Benin",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 2,
        "mapcolor9": 2,
        "mapcolor13": 12,
        "pop_est": 8791832,
        "gdp_md_est": 12830,
        "pop_year": -99,
        "lastcensus": 2002,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "BJ",
        "iso_a3": "BEN",
        "iso_n3": "204",
        "un_a3": "204",
        "wb_a2": "BJ",
        "wb_a3": "BEN",
        "woe_id": -99,
        "adm0_a3_is": "BEN",
        "adm0_a3_us": "BEN",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Western Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 5,
        "long_len": 5,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "Burkina Faso": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Burkina Faso",
        "sov_a3": "BFA",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Burkina Faso",
        "adm0_a3": "BFA",
        "geou_dif": 0,
        "geounit": "Burkina Faso",
        "gu_a3": "BFA",
        "su_dif": 0,
        "subunit": "Burkina Faso",
        "su_a3": "BFA",
        "brk_diff": 0,
        "name": "Burkina Faso",
        "name_long": "Burkina Faso",
        "brk_a3": "BFA",
        "brk_name": "Burkina Faso",
        "brk_group": "",
        "abbrev": "B.F.",
        "postal": "BF",
        "formal_en": "Burkina Faso",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Burkina Faso",
        "name_alt": "",
        "mapcolor7": 2,
        "mapcolor8": 1,
        "mapcolor9": 5,
        "mapcolor13": 11,
        "pop_est": 15746232,
        "gdp_md_est": 17820,
        "pop_year": -99,
        "lastcensus": 2006,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "BF",
        "iso_a3": "BFA",
        "iso_n3": "854",
        "un_a3": "854",
        "wb_a2": "BF",
        "wb_a3": "BFA",
        "woe_id": -99,
        "adm0_a3_is": "BFA",
        "adm0_a3_us": "BFA",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Western Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 12,
        "long_len": 12,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Bangladesh": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Bangladesh",
        "sov_a3": "BGD",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Bangladesh",
        "adm0_a3": "BGD",
        "geou_dif": 0,
        "geounit": "Bangladesh",
        "gu_a3": "BGD",
        "su_dif": 0,
        "subunit": "Bangladesh",
        "su_a3": "BGD",
        "brk_diff": 0,
        "name": "Bangladesh",
        "name_long": "Bangladesh",
        "brk_a3": "BGD",
        "brk_name": "Bangladesh",
        "brk_group": "",
        "abbrev": "Bang.",
        "postal": "BD",
        "formal_en": "People's Republic of Bangladesh",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Bangladesh",
        "name_alt": "",
        "mapcolor7": 3,
        "mapcolor8": 4,
        "mapcolor9": 7,
        "mapcolor13": 7,
        "pop_est": 156050883,
        "gdp_md_est": 224000,
        "pop_year": -99,
        "lastcensus": 2011,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "BD",
        "iso_a3": "BGD",
        "iso_n3": "050",
        "un_a3": "050",
        "wb_a2": "BD",
        "wb_a3": "BGD",
        "woe_id": -99,
        "adm0_a3_is": "BGD",
        "adm0_a3_us": "BGD",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Southern Asia",
        "region_wb": "South Asia",
        "name_len": 10,
        "long_len": 10,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "Bulgaria": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 4,
        "sovereignt": "Bulgaria",
        "sov_a3": "BGR",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Bulgaria",
        "adm0_a3": "BGR",
        "geou_dif": 0,
        "geounit": "Bulgaria",
        "gu_a3": "BGR",
        "su_dif": 0,
        "subunit": "Bulgaria",
        "su_a3": "BGR",
        "brk_diff": 0,
        "name": "Bulgaria",
        "name_long": "Bulgaria",
        "brk_a3": "BGR",
        "brk_name": "Bulgaria",
        "brk_group": "",
        "abbrev": "Bulg.",
        "postal": "BG",
        "formal_en": "Republic of Bulgaria",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Bulgaria",
        "name_alt": "",
        "mapcolor7": 4,
        "mapcolor8": 5,
        "mapcolor9": 1,
        "mapcolor13": 8,
        "pop_est": 7204687,
        "gdp_md_est": 93750,
        "pop_year": -99,
        "lastcensus": 2011,
        "gdp_year": -99,
        "economy": "2. Developed region: nonG7",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "BG",
        "iso_a3": "BGR",
        "iso_n3": "100",
        "un_a3": "100",
        "wb_a2": "BG",
        "wb_a3": "BGR",
        "woe_id": -99,
        "adm0_a3_is": "BGR",
        "adm0_a3_us": "BGR",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Eastern Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 8,
        "long_len": 8,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "Bahamas": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 4,
        "sovereignt": "The Bahamas",
        "sov_a3": "BHS",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "The Bahamas",
        "adm0_a3": "BHS",
        "geou_dif": 0,
        "geounit": "The Bahamas",
        "gu_a3": "BHS",
        "su_dif": 0,
        "subunit": "The Bahamas",
        "su_a3": "BHS",
        "brk_diff": 0,
        "name": "Bahamas",
        "name_long": "Bahamas",
        "brk_a3": "BHS",
        "brk_name": "Bahamas",
        "brk_group": "",
        "abbrev": "Bhs.",
        "postal": "BS",
        "formal_en": "Commonwealth of the Bahamas",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Bahamas, The",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 1,
        "mapcolor9": 2,
        "mapcolor13": 5,
        "pop_est": 309156,
        "gdp_md_est": 9093,
        "pop_year": -99,
        "lastcensus": 2010,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "2. High income: nonOECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "BS",
        "iso_a3": "BHS",
        "iso_n3": "044",
        "un_a3": "044",
        "wb_a2": "BS",
        "wb_a3": "BHS",
        "woe_id": -99,
        "adm0_a3_is": "BHS",
        "adm0_a3_us": "BHS",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "North America",
        "region_un": "Americas",
        "subregion": "Caribbean",
        "region_wb": "Latin America & Caribbean",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Bosnia and Herz.": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 5,
        "sovereignt": "Bosnia and Herzegovina",
        "sov_a3": "BIH",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Bosnia and Herzegovina",
        "adm0_a3": "BIH",
        "geou_dif": 0,
        "geounit": "Bosnia and Herzegovina",
        "gu_a3": "BIH",
        "su_dif": 0,
        "subunit": "Bosnia and Herzegovina",
        "su_a3": "BIH",
        "brk_diff": 0,
        "name": "Bosnia and Herz.",
        "name_long": "Bosnia and Herzegovina",
        "brk_a3": "BIH",
        "brk_name": "Bosnia and Herz.",
        "brk_group": "",
        "abbrev": "B.H.",
        "postal": "BiH",
        "formal_en": "Bosnia and Herzegovina",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Bosnia and Herzegovina",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 1,
        "mapcolor9": 1,
        "mapcolor13": 2,
        "pop_est": 4613414,
        "gdp_md_est": 29700,
        "pop_year": -99,
        "lastcensus": 1991,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "BA",
        "iso_a3": "BIH",
        "iso_n3": "070",
        "un_a3": "070",
        "wb_a2": "BA",
        "wb_a3": "BIH",
        "woe_id": -99,
        "adm0_a3_is": "BIH",
        "adm0_a3_us": "BIH",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Southern Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 16,
        "long_len": 22,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Belarus": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 4,
        "sovereignt": "Belarus",
        "sov_a3": "BLR",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Belarus",
        "adm0_a3": "BLR",
        "geou_dif": 0,
        "geounit": "Belarus",
        "gu_a3": "BLR",
        "su_dif": 0,
        "subunit": "Belarus",
        "su_a3": "BLR",
        "brk_diff": 0,
        "name": "Belarus",
        "name_long": "Belarus",
        "brk_a3": "BLR",
        "brk_name": "Belarus",
        "brk_group": "",
        "abbrev": "Bela.",
        "postal": "BY",
        "formal_en": "Republic of Belarus",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Belarus",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 1,
        "mapcolor9": 5,
        "mapcolor13": 11,
        "pop_est": 9648533,
        "gdp_md_est": 114100,
        "pop_year": -99,
        "lastcensus": 2009,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "BY",
        "iso_a3": "BLR",
        "iso_n3": "112",
        "un_a3": "112",
        "wb_a2": "BY",
        "wb_a3": "BLR",
        "woe_id": -99,
        "adm0_a3_is": "BLR",
        "adm0_a3_us": "BLR",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Eastern Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "Belize": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 6,
        "sovereignt": "Belize",
        "sov_a3": "BLZ",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Belize",
        "adm0_a3": "BLZ",
        "geou_dif": 0,
        "geounit": "Belize",
        "gu_a3": "BLZ",
        "su_dif": 0,
        "subunit": "Belize",
        "su_a3": "BLZ",
        "brk_diff": 0,
        "name": "Belize",
        "name_long": "Belize",
        "brk_a3": "BLZ",
        "brk_name": "Belize",
        "brk_group": "",
        "abbrev": "Belize",
        "postal": "BZ",
        "formal_en": "Belize",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Belize",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 4,
        "mapcolor9": 5,
        "mapcolor13": 7,
        "pop_est": 307899,
        "gdp_md_est": 2536,
        "pop_year": -99,
        "lastcensus": 2010,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "BZ",
        "iso_a3": "BLZ",
        "iso_n3": "084",
        "un_a3": "084",
        "wb_a2": "BZ",
        "wb_a3": "BLZ",
        "woe_id": -99,
        "adm0_a3_is": "BLZ",
        "adm0_a3_us": "BLZ",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "North America",
        "region_un": "Americas",
        "subregion": "Central America",
        "region_wb": "Latin America & Caribbean",
        "name_len": 6,
        "long_len": 6,
        "abbrev_len": 6,
        "tiny": -99,
        "homepart": 1
    },
    "Bolivia": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Bolivia",
        "sov_a3": "BOL",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Bolivia",
        "adm0_a3": "BOL",
        "geou_dif": 0,
        "geounit": "Bolivia",
        "gu_a3": "BOL",
        "su_dif": 0,
        "subunit": "Bolivia",
        "su_a3": "BOL",
        "brk_diff": 0,
        "name": "Bolivia",
        "name_long": "Bolivia",
        "brk_a3": "BOL",
        "brk_name": "Bolivia",
        "brk_group": "",
        "abbrev": "Bolivia",
        "postal": "BO",
        "formal_en": "Plurinational State of Bolivia",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Bolivia",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 5,
        "mapcolor9": 2,
        "mapcolor13": 3,
        "pop_est": 9775246,
        "gdp_md_est": 43270,
        "pop_year": -99,
        "lastcensus": 2001,
        "gdp_year": -99,
        "economy": "5. Emerging region: G20",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "BO",
        "iso_a3": "BOL",
        "iso_n3": "068",
        "un_a3": "068",
        "wb_a2": "BO",
        "wb_a3": "BOL",
        "woe_id": -99,
        "adm0_a3_is": "BOL",
        "adm0_a3_us": "BOL",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "South America",
        "region_un": "Americas",
        "subregion": "South America",
        "region_wb": "Latin America & Caribbean",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 7,
        "tiny": -99,
        "homepart": 1
    },
    "Brazil": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "Brazil",
        "sov_a3": "BRA",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Brazil",
        "adm0_a3": "BRA",
        "geou_dif": 0,
        "geounit": "Brazil",
        "gu_a3": "BRA",
        "su_dif": 0,
        "subunit": "Brazil",
        "su_a3": "BRA",
        "brk_diff": 0,
        "name": "Brazil",
        "name_long": "Brazil",
        "brk_a3": "BRA",
        "brk_name": "Brazil",
        "brk_group": "",
        "abbrev": "Brazil",
        "postal": "BR",
        "formal_en": "Federative Republic of Brazil",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Brazil",
        "name_alt": "",
        "mapcolor7": 5,
        "mapcolor8": 6,
        "mapcolor9": 5,
        "mapcolor13": 7,
        "pop_est": 198739269,
        "gdp_md_est": 1993000,
        "pop_year": -99,
        "lastcensus": 2010,
        "gdp_year": -99,
        "economy": "3. Emerging region: BRIC",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "BR",
        "iso_a3": "BRA",
        "iso_n3": "076",
        "un_a3": "076",
        "wb_a2": "BR",
        "wb_a3": "BRA",
        "woe_id": -99,
        "adm0_a3_is": "BRA",
        "adm0_a3_us": "BRA",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "South America",
        "region_un": "Americas",
        "subregion": "South America",
        "region_wb": "Latin America & Caribbean",
        "name_len": 6,
        "long_len": 6,
        "abbrev_len": 6,
        "tiny": -99,
        "homepart": 1
    },
    "Brunei": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 6,
        "sovereignt": "Brunei",
        "sov_a3": "BRN",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Brunei",
        "adm0_a3": "BRN",
        "geou_dif": 0,
        "geounit": "Brunei",
        "gu_a3": "BRN",
        "su_dif": 0,
        "subunit": "Brunei",
        "su_a3": "BRN",
        "brk_diff": 0,
        "name": "Brunei",
        "name_long": "Brunei Darussalam",
        "brk_a3": "BRN",
        "brk_name": "Brunei",
        "brk_group": "",
        "abbrev": "Brunei",
        "postal": "BN",
        "formal_en": "Negara Brunei Darussalam",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Brunei",
        "name_alt": "",
        "mapcolor7": 4,
        "mapcolor8": 6,
        "mapcolor9": 6,
        "mapcolor13": 12,
        "pop_est": 388190,
        "gdp_md_est": 20250,
        "pop_year": -99,
        "lastcensus": 2001,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "2. High income: nonOECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "BN",
        "iso_a3": "BRN",
        "iso_n3": "096",
        "un_a3": "096",
        "wb_a2": "BN",
        "wb_a3": "BRN",
        "woe_id": -99,
        "adm0_a3_is": "BRN",
        "adm0_a3_us": "BRN",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "South-Eastern Asia",
        "region_wb": "East Asia & Pacific",
        "name_len": 6,
        "long_len": 17,
        "abbrev_len": 6,
        "tiny": 2,
        "homepart": 1
    },
    "Bhutan": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 5,
        "sovereignt": "Bhutan",
        "sov_a3": "BTN",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Bhutan",
        "adm0_a3": "BTN",
        "geou_dif": 0,
        "geounit": "Bhutan",
        "gu_a3": "BTN",
        "su_dif": 0,
        "subunit": "Bhutan",
        "su_a3": "BTN",
        "brk_diff": 0,
        "name": "Bhutan",
        "name_long": "Bhutan",
        "brk_a3": "BTN",
        "brk_name": "Bhutan",
        "brk_group": "",
        "abbrev": "Bhutan",
        "postal": "BT",
        "formal_en": "Kingdom of Bhutan",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Bhutan",
        "name_alt": "",
        "mapcolor7": 5,
        "mapcolor8": 6,
        "mapcolor9": 1,
        "mapcolor13": 8,
        "pop_est": 691141,
        "gdp_md_est": 3524,
        "pop_year": -99,
        "lastcensus": 2005,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "BT",
        "iso_a3": "BTN",
        "iso_n3": "064",
        "un_a3": "064",
        "wb_a2": "BT",
        "wb_a3": "BTN",
        "woe_id": -99,
        "adm0_a3_is": "BTN",
        "adm0_a3_us": "BTN",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Southern Asia",
        "region_wb": "South Asia",
        "name_len": 6,
        "long_len": 6,
        "abbrev_len": 6,
        "tiny": -99,
        "homepart": 1
    },
    "Botswana": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 4,
        "sovereignt": "Botswana",
        "sov_a3": "BWA",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Botswana",
        "adm0_a3": "BWA",
        "geou_dif": 0,
        "geounit": "Botswana",
        "gu_a3": "BWA",
        "su_dif": 0,
        "subunit": "Botswana",
        "su_a3": "BWA",
        "brk_diff": 0,
        "name": "Botswana",
        "name_long": "Botswana",
        "brk_a3": "BWA",
        "brk_name": "Botswana",
        "brk_group": "",
        "abbrev": "Bwa.",
        "postal": "BW",
        "formal_en": "Republic of Botswana",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Botswana",
        "name_alt": "",
        "mapcolor7": 6,
        "mapcolor8": 5,
        "mapcolor9": 7,
        "mapcolor13": 3,
        "pop_est": 1990876,
        "gdp_md_est": 27060,
        "pop_year": -99,
        "lastcensus": 2011,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "BW",
        "iso_a3": "BWA",
        "iso_n3": "072",
        "un_a3": "072",
        "wb_a2": "BW",
        "wb_a3": "BWA",
        "woe_id": -99,
        "adm0_a3_is": "BWA",
        "adm0_a3_us": "BWA",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Southern Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 8,
        "long_len": 8,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Central African Rep.": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 4,
        "sovereignt": "Central African Republic",
        "sov_a3": "CAF",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Central African Republic",
        "adm0_a3": "CAF",
        "geou_dif": 0,
        "geounit": "Central African Republic",
        "gu_a3": "CAF",
        "su_dif": 0,
        "subunit": "Central African Republic",
        "su_a3": "CAF",
        "brk_diff": 0,
        "name": "Central African Rep.",
        "name_long": "Central African Republic",
        "brk_a3": "CAF",
        "brk_name": "Central African Rep.",
        "brk_group": "",
        "abbrev": "C.A.R.",
        "postal": "CF",
        "formal_en": "Central African Republic",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Central African Republic",
        "name_alt": "",
        "mapcolor7": 5,
        "mapcolor8": 6,
        "mapcolor9": 6,
        "mapcolor13": 9,
        "pop_est": 4511488,
        "gdp_md_est": 3198,
        "pop_year": -99,
        "lastcensus": 2003,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "CF",
        "iso_a3": "CAF",
        "iso_n3": "140",
        "un_a3": "140",
        "wb_a2": "CF",
        "wb_a3": "CAF",
        "woe_id": -99,
        "adm0_a3_is": "CAF",
        "adm0_a3_us": "CAF",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Middle Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 20,
        "long_len": 24,
        "abbrev_len": 6,
        "tiny": -99,
        "homepart": 1
    },
    "Canada": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "Canada",
        "sov_a3": "CAN",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Canada",
        "adm0_a3": "CAN",
        "geou_dif": 0,
        "geounit": "Canada",
        "gu_a3": "CAN",
        "su_dif": 0,
        "subunit": "Canada",
        "su_a3": "CAN",
        "brk_diff": 0,
        "name": "Canada",
        "name_long": "Canada",
        "brk_a3": "CAN",
        "brk_name": "Canada",
        "brk_group": "",
        "abbrev": "Can.",
        "postal": "CA",
        "formal_en": "Canada",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Canada",
        "name_alt": "",
        "mapcolor7": 6,
        "mapcolor8": 6,
        "mapcolor9": 2,
        "mapcolor13": 2,
        "pop_est": 33487208,
        "gdp_md_est": 1300000,
        "pop_year": -99,
        "lastcensus": 2011,
        "gdp_year": -99,
        "economy": "1. Developed region: G7",
        "income_grp": "1. High income: OECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "CA",
        "iso_a3": "CAN",
        "iso_n3": "124",
        "un_a3": "124",
        "wb_a2": "CA",
        "wb_a3": "CAN",
        "woe_id": -99,
        "adm0_a3_is": "CAN",
        "adm0_a3_us": "CAN",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "North America",
        "region_un": "Americas",
        "subregion": "Northern America",
        "region_wb": "North America",
        "name_len": 6,
        "long_len": 6,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Switzerland": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 4,
        "sovereignt": "Switzerland",
        "sov_a3": "CHE",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Switzerland",
        "adm0_a3": "CHE",
        "geou_dif": 0,
        "geounit": "Switzerland",
        "gu_a3": "CHE",
        "su_dif": 0,
        "subunit": "Switzerland",
        "su_a3": "CHE",
        "brk_diff": 0,
        "name": "Switzerland",
        "name_long": "Switzerland",
        "brk_a3": "CHE",
        "brk_name": "Switzerland",
        "brk_group": "",
        "abbrev": "Switz.",
        "postal": "CH",
        "formal_en": "Swiss Confederation",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Switzerland",
        "name_alt": "",
        "mapcolor7": 5,
        "mapcolor8": 2,
        "mapcolor9": 7,
        "mapcolor13": 3,
        "pop_est": 7604467,
        "gdp_md_est": 316700,
        "pop_year": -99,
        "lastcensus": 2010,
        "gdp_year": -99,
        "economy": "2. Developed region: nonG7",
        "income_grp": "1. High income: OECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "CH",
        "iso_a3": "CHE",
        "iso_n3": "756",
        "un_a3": "756",
        "wb_a2": "CH",
        "wb_a3": "CHE",
        "woe_id": -99,
        "adm0_a3_is": "CHE",
        "adm0_a3_us": "CHE",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Western Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 11,
        "long_len": 11,
        "abbrev_len": 6,
        "tiny": -99,
        "homepart": 1
    },
    "Chile": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "Chile",
        "sov_a3": "CHL",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Chile",
        "adm0_a3": "CHL",
        "geou_dif": 0,
        "geounit": "Chile",
        "gu_a3": "CHL",
        "su_dif": 0,
        "subunit": "Chile",
        "su_a3": "CHL",
        "brk_diff": 0,
        "name": "Chile",
        "name_long": "Chile",
        "brk_a3": "CHL",
        "brk_name": "Chile",
        "brk_group": "",
        "abbrev": "Chile",
        "postal": "CL",
        "formal_en": "Republic of Chile",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Chile",
        "name_alt": "",
        "mapcolor7": 5,
        "mapcolor8": 1,
        "mapcolor9": 5,
        "mapcolor13": 9,
        "pop_est": 16601707,
        "gdp_md_est": 244500,
        "pop_year": -99,
        "lastcensus": 2002,
        "gdp_year": -99,
        "economy": "5. Emerging region: G20",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "CL",
        "iso_a3": "CHL",
        "iso_n3": "152",
        "un_a3": "152",
        "wb_a2": "CL",
        "wb_a3": "CHL",
        "woe_id": -99,
        "adm0_a3_is": "CHL",
        "adm0_a3_us": "CHL",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "South America",
        "region_un": "Americas",
        "subregion": "South America",
        "region_wb": "Latin America & Caribbean",
        "name_len": 5,
        "long_len": 5,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "China": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "China",
        "sov_a3": "CH1",
        "adm0_dif": 1,
        "level": 2,
        "type": "Country",
        "admin": "China",
        "adm0_a3": "CHN",
        "geou_dif": 0,
        "geounit": "China",
        "gu_a3": "CHN",
        "su_dif": 0,
        "subunit": "China",
        "su_a3": "CHN",
        "brk_diff": 0,
        "name": "China",
        "name_long": "China",
        "brk_a3": "CHN",
        "brk_name": "China",
        "brk_group": "",
        "abbrev": "China",
        "postal": "CN",
        "formal_en": "People's Republic of China",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "China",
        "name_alt": "",
        "mapcolor7": 4,
        "mapcolor8": 4,
        "mapcolor9": 4,
        "mapcolor13": 3,
        "pop_est": 1338612970,
        "gdp_md_est": 7973000,
        "pop_year": -99,
        "lastcensus": 2010,
        "gdp_year": -99,
        "economy": "3. Emerging region: BRIC",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "CN",
        "iso_a3": "CHN",
        "iso_n3": "156",
        "un_a3": "156",
        "wb_a2": "CN",
        "wb_a3": "CHN",
        "woe_id": -99,
        "adm0_a3_is": "CHN",
        "adm0_a3_us": "CHN",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Eastern Asia",
        "region_wb": "East Asia & Pacific",
        "name_len": 5,
        "long_len": 5,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "Côte d'Ivoire": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Ivory Coast",
        "sov_a3": "CIV",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Ivory Coast",
        "adm0_a3": "CIV",
        "geou_dif": 0,
        "geounit": "Ivory Coast",
        "gu_a3": "CIV",
        "su_dif": 0,
        "subunit": "Ivory Coast",
        "su_a3": "CIV",
        "brk_diff": 0,
        "name": "Côte d'Ivoire",
        "name_long": "Côte d'Ivoire",
        "brk_a3": "CIV",
        "brk_name": "Côte d'Ivoire",
        "brk_group": "",
        "abbrev": "I.C.",
        "postal": "CI",
        "formal_en": "Republic of Ivory Coast",
        "formal_fr": "Republic of Cote D'Ivoire",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Côte d'Ivoire",
        "name_alt": "",
        "mapcolor7": 4,
        "mapcolor8": 6,
        "mapcolor9": 3,
        "mapcolor13": 3,
        "pop_est": 20617068,
        "gdp_md_est": 33850,
        "pop_year": -99,
        "lastcensus": 1998,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "CI",
        "iso_a3": "CIV",
        "iso_n3": "384",
        "un_a3": "384",
        "wb_a2": "CI",
        "wb_a3": "CIV",
        "woe_id": -99,
        "adm0_a3_is": "CIV",
        "adm0_a3_us": "CIV",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Western Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 13,
        "long_len": 13,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Cameroon": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Cameroon",
        "sov_a3": "CMR",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Cameroon",
        "adm0_a3": "CMR",
        "geou_dif": 0,
        "geounit": "Cameroon",
        "gu_a3": "CMR",
        "su_dif": 0,
        "subunit": "Cameroon",
        "su_a3": "CMR",
        "brk_diff": 0,
        "name": "Cameroon",
        "name_long": "Cameroon",
        "brk_a3": "CMR",
        "brk_name": "Cameroon",
        "brk_group": "",
        "abbrev": "Cam.",
        "postal": "CM",
        "formal_en": "Republic of Cameroon",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Cameroon",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 4,
        "mapcolor9": 1,
        "mapcolor13": 3,
        "pop_est": 18879301,
        "gdp_md_est": 42750,
        "pop_year": -99,
        "lastcensus": 2005,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "CM",
        "iso_a3": "CMR",
        "iso_n3": "120",
        "un_a3": "120",
        "wb_a2": "CM",
        "wb_a3": "CMR",
        "woe_id": -99,
        "adm0_a3_is": "CMR",
        "adm0_a3_us": "CMR",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Middle Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 8,
        "long_len": 8,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Dem. Rep. Congo": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "Democratic Republic of the Congo",
        "sov_a3": "COD",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Democratic Republic of the Congo",
        "adm0_a3": "COD",
        "geou_dif": 0,
        "geounit": "Democratic Republic of the Congo",
        "gu_a3": "COD",
        "su_dif": 0,
        "subunit": "Democratic Republic of the Congo",
        "su_a3": "COD",
        "brk_diff": 0,
        "name": "Dem. Rep. Congo",
        "name_long": "Democratic Republic of the Congo",
        "brk_a3": "COD",
        "brk_name": "Democratic Republic of the Congo",
        "brk_group": "",
        "abbrev": "D.R.C.",
        "postal": "DRC",
        "formal_en": "Democratic Republic of the Congo",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Congo, Dem. Rep.",
        "name_alt": "",
        "mapcolor7": 4,
        "mapcolor8": 4,
        "mapcolor9": 4,
        "mapcolor13": 7,
        "pop_est": 68692542,
        "gdp_md_est": 20640,
        "pop_year": -99,
        "lastcensus": 1984,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "CD",
        "iso_a3": "COD",
        "iso_n3": "180",
        "un_a3": "180",
        "wb_a2": "ZR",
        "wb_a3": "ZAR",
        "woe_id": -99,
        "adm0_a3_is": "COD",
        "adm0_a3_us": "COD",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Middle Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 15,
        "long_len": 32,
        "abbrev_len": 6,
        "tiny": -99,
        "homepart": 1
    },
    "Congo": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 4,
        "sovereignt": "Republic of Congo",
        "sov_a3": "COG",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Republic of Congo",
        "adm0_a3": "COG",
        "geou_dif": 0,
        "geounit": "Republic of Congo",
        "gu_a3": "COG",
        "su_dif": 0,
        "subunit": "Republic of Congo",
        "su_a3": "COG",
        "brk_diff": 0,
        "name": "Congo",
        "name_long": "Republic of Congo",
        "brk_a3": "COG",
        "brk_name": "Republic of Congo",
        "brk_group": "",
        "abbrev": "Rep. Congo",
        "postal": "CG",
        "formal_en": "Republic of Congo",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Congo, Rep.",
        "name_alt": "",
        "mapcolor7": 2,
        "mapcolor8": 1,
        "mapcolor9": 3,
        "mapcolor13": 10,
        "pop_est": 4012809,
        "gdp_md_est": 15350,
        "pop_year": -99,
        "lastcensus": 2007,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "CG",
        "iso_a3": "COG",
        "iso_n3": "178",
        "un_a3": "178",
        "wb_a2": "CG",
        "wb_a3": "COG",
        "woe_id": -99,
        "adm0_a3_is": "COG",
        "adm0_a3_us": "COG",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Middle Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 5,
        "long_len": 17,
        "abbrev_len": 10,
        "tiny": -99,
        "homepart": 1
    },
    "Colombia": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "Colombia",
        "sov_a3": "COL",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Colombia",
        "adm0_a3": "COL",
        "geou_dif": 0,
        "geounit": "Colombia",
        "gu_a3": "COL",
        "su_dif": 0,
        "subunit": "Colombia",
        "su_a3": "COL",
        "brk_diff": 0,
        "name": "Colombia",
        "name_long": "Colombia",
        "brk_a3": "COL",
        "brk_name": "Colombia",
        "brk_group": "",
        "abbrev": "Col.",
        "postal": "CO",
        "formal_en": "Republic of Colombia",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Colombia",
        "name_alt": "",
        "mapcolor7": 2,
        "mapcolor8": 1,
        "mapcolor9": 3,
        "mapcolor13": 1,
        "pop_est": 45644023,
        "gdp_md_est": 395400,
        "pop_year": -99,
        "lastcensus": 2006,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "CO",
        "iso_a3": "COL",
        "iso_n3": "170",
        "un_a3": "170",
        "wb_a2": "CO",
        "wb_a3": "COL",
        "woe_id": -99,
        "adm0_a3_is": "COL",
        "adm0_a3_us": "COL",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "South America",
        "region_un": "Americas",
        "subregion": "South America",
        "region_wb": "Latin America & Caribbean",
        "name_len": 8,
        "long_len": 8,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Costa Rica": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 5,
        "sovereignt": "Costa Rica",
        "sov_a3": "CRI",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Costa Rica",
        "adm0_a3": "CRI",
        "geou_dif": 0,
        "geounit": "Costa Rica",
        "gu_a3": "CRI",
        "su_dif": 0,
        "subunit": "Costa Rica",
        "su_a3": "CRI",
        "brk_diff": 0,
        "name": "Costa Rica",
        "name_long": "Costa Rica",
        "brk_a3": "CRI",
        "brk_name": "Costa Rica",
        "brk_group": "",
        "abbrev": "C.R.",
        "postal": "CR",
        "formal_en": "Republic of Costa Rica",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Costa Rica",
        "name_alt": "",
        "mapcolor7": 3,
        "mapcolor8": 2,
        "mapcolor9": 4,
        "mapcolor13": 2,
        "pop_est": 4253877,
        "gdp_md_est": 48320,
        "pop_year": -99,
        "lastcensus": 2011,
        "gdp_year": -99,
        "economy": "5. Emerging region: G20",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "CR",
        "iso_a3": "CRI",
        "iso_n3": "188",
        "un_a3": "188",
        "wb_a2": "CR",
        "wb_a3": "CRI",
        "woe_id": -99,
        "adm0_a3_is": "CRI",
        "adm0_a3_us": "CRI",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "North America",
        "region_un": "Americas",
        "subregion": "Central America",
        "region_wb": "Latin America & Caribbean",
        "name_len": 10,
        "long_len": 10,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Cuba": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Cuba",
        "sov_a3": "CUB",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Cuba",
        "adm0_a3": "CUB",
        "geou_dif": 0,
        "geounit": "Cuba",
        "gu_a3": "CUB",
        "su_dif": 0,
        "subunit": "Cuba",
        "su_a3": "CUB",
        "brk_diff": 0,
        "name": "Cuba",
        "name_long": "Cuba",
        "brk_a3": "CUB",
        "brk_name": "Cuba",
        "brk_group": "",
        "abbrev": "Cuba",
        "postal": "CU",
        "formal_en": "Republic of Cuba",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Cuba",
        "name_alt": "",
        "mapcolor7": 3,
        "mapcolor8": 5,
        "mapcolor9": 3,
        "mapcolor13": 4,
        "pop_est": 11451652,
        "gdp_md_est": 108200,
        "pop_year": -99,
        "lastcensus": 2002,
        "gdp_year": -99,
        "economy": "5. Emerging region: G20",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "CU",
        "iso_a3": "CUB",
        "iso_n3": "192",
        "un_a3": "192",
        "wb_a2": "CU",
        "wb_a3": "CUB",
        "woe_id": -99,
        "adm0_a3_is": "CUB",
        "adm0_a3_us": "CUB",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "North America",
        "region_un": "Americas",
        "subregion": "Caribbean",
        "region_wb": "Latin America & Caribbean",
        "name_len": 4,
        "long_len": 4,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "N. Cyprus": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 6,
        "sovereignt": "Northern Cyprus",
        "sov_a3": "CYN",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Northern Cyprus",
        "adm0_a3": "CYN",
        "geou_dif": 0,
        "geounit": "Northern Cyprus",
        "gu_a3": "CYN",
        "su_dif": 0,
        "subunit": "Northern Cyprus",
        "su_a3": "CYN",
        "brk_diff": 1,
        "name": "N. Cyprus",
        "name_long": "Northern Cyprus",
        "brk_a3": "B20",
        "brk_name": "N. Cyprus",
        "brk_group": "",
        "abbrev": "N. Cy.",
        "postal": "CN",
        "formal_en": "Turkish Republic of Northern Cyprus",
        "formal_fr": "",
        "note_adm0": "Self admin.",
        "note_brk": "Self admin.; Claimed by Cyprus",
        "name_sort": "Cyprus, Northern",
        "name_alt": "",
        "mapcolor7": 3,
        "mapcolor8": 1,
        "mapcolor9": 4,
        "mapcolor13": 8,
        "pop_est": 265100,
        "gdp_md_est": 3600,
        "pop_year": -99,
        "lastcensus": -99,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "CY",
        "iso_a3": "-99",
        "iso_n3": "-99",
        "un_a3": "-099",
        "wb_a2": "CY",
        "wb_a3": "-99",
        "woe_id": -99,
        "adm0_a3_is": "CYP",
        "adm0_a3_us": "CYP",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Western Asia",
        "region_wb": "Europe & Central Asia",
        "name_len": 9,
        "long_len": 15,
        "abbrev_len": 6,
        "tiny": -99,
        "homepart": 1
    },
    "Cyprus": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 5,
        "sovereignt": "Cyprus",
        "sov_a3": "CYP",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Cyprus",
        "adm0_a3": "CYP",
        "geou_dif": 0,
        "geounit": "Cyprus",
        "gu_a3": "CYP",
        "su_dif": 0,
        "subunit": "Cyprus",
        "su_a3": "CYP",
        "brk_diff": 0,
        "name": "Cyprus",
        "name_long": "Cyprus",
        "brk_a3": "CYP",
        "brk_name": "Cyprus",
        "brk_group": "",
        "abbrev": "Cyp.",
        "postal": "CY",
        "formal_en": "Republic of Cyprus",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Cyprus",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 2,
        "mapcolor9": 3,
        "mapcolor13": 7,
        "pop_est": 531640,
        "gdp_md_est": 22700,
        "pop_year": -99,
        "lastcensus": 2001,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "2. High income: nonOECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "CY",
        "iso_a3": "CYP",
        "iso_n3": "196",
        "un_a3": "196",
        "wb_a2": "CY",
        "wb_a3": "CYP",
        "woe_id": -99,
        "adm0_a3_is": "CYP",
        "adm0_a3_us": "CYP",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Western Asia",
        "region_wb": "Europe & Central Asia",
        "name_len": 6,
        "long_len": 6,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Czech Rep.": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 5,
        "sovereignt": "Czech Republic",
        "sov_a3": "CZE",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Czech Republic",
        "adm0_a3": "CZE",
        "geou_dif": 0,
        "geounit": "Czech Republic",
        "gu_a3": "CZE",
        "su_dif": 0,
        "subunit": "Czech Republic",
        "su_a3": "CZE",
        "brk_diff": 0,
        "name": "Czech Rep.",
        "name_long": "Czech Republic",
        "brk_a3": "CZE",
        "brk_name": "Czech Rep.",
        "brk_group": "",
        "abbrev": "Cz. Rep.",
        "postal": "CZ",
        "formal_en": "Czech Republic",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Czech Republic",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 1,
        "mapcolor9": 2,
        "mapcolor13": 6,
        "pop_est": 10211904,
        "gdp_md_est": 265200,
        "pop_year": -99,
        "lastcensus": 2011,
        "gdp_year": -99,
        "economy": "2. Developed region: nonG7",
        "income_grp": "1. High income: OECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "CZ",
        "iso_a3": "CZE",
        "iso_n3": "203",
        "un_a3": "203",
        "wb_a2": "CZ",
        "wb_a3": "CZE",
        "woe_id": -99,
        "adm0_a3_is": "CZE",
        "adm0_a3_us": "CZE",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Eastern Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 10,
        "long_len": 14,
        "abbrev_len": 8,
        "tiny": -99,
        "homepart": 1
    },
    "Germany": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "Germany",
        "sov_a3": "DEU",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Germany",
        "adm0_a3": "DEU",
        "geou_dif": 0,
        "geounit": "Germany",
        "gu_a3": "DEU",
        "su_dif": 0,
        "subunit": "Germany",
        "su_a3": "DEU",
        "brk_diff": 0,
        "name": "Germany",
        "name_long": "Germany",
        "brk_a3": "DEU",
        "brk_name": "Germany",
        "brk_group": "",
        "abbrev": "Ger.",
        "postal": "D",
        "formal_en": "Federal Republic of Germany",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Germany",
        "name_alt": "",
        "mapcolor7": 2,
        "mapcolor8": 5,
        "mapcolor9": 5,
        "mapcolor13": 1,
        "pop_est": 82329758,
        "gdp_md_est": 2918000,
        "pop_year": -99,
        "lastcensus": 2011,
        "gdp_year": -99,
        "economy": "1. Developed region: G7",
        "income_grp": "1. High income: OECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "DE",
        "iso_a3": "DEU",
        "iso_n3": "276",
        "un_a3": "276",
        "wb_a2": "DE",
        "wb_a3": "DEU",
        "woe_id": -99,
        "adm0_a3_is": "DEU",
        "adm0_a3_us": "DEU",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Western Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Djibouti": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 5,
        "sovereignt": "Djibouti",
        "sov_a3": "DJI",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Djibouti",
        "adm0_a3": "DJI",
        "geou_dif": 0,
        "geounit": "Djibouti",
        "gu_a3": "DJI",
        "su_dif": 0,
        "subunit": "Djibouti",
        "su_a3": "DJI",
        "brk_diff": 0,
        "name": "Djibouti",
        "name_long": "Djibouti",
        "brk_a3": "DJI",
        "brk_name": "Djibouti",
        "brk_group": "",
        "abbrev": "Dji.",
        "postal": "DJ",
        "formal_en": "Republic of Djibouti",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Djibouti",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 2,
        "mapcolor9": 4,
        "mapcolor13": 8,
        "pop_est": 516055,
        "gdp_md_est": 1885,
        "pop_year": -99,
        "lastcensus": 2009,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "DJ",
        "iso_a3": "DJI",
        "iso_n3": "262",
        "un_a3": "262",
        "wb_a2": "DJ",
        "wb_a3": "DJI",
        "woe_id": -99,
        "adm0_a3_is": "DJI",
        "adm0_a3_us": "DJI",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Eastern Africa",
        "region_wb": "Middle East & North Africa",
        "name_len": 8,
        "long_len": 8,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Denmark": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 4,
        "sovereignt": "Denmark",
        "sov_a3": "DN1",
        "adm0_dif": 1,
        "level": 2,
        "type": "Country",
        "admin": "Denmark",
        "adm0_a3": "DNK",
        "geou_dif": 0,
        "geounit": "Denmark",
        "gu_a3": "DNK",
        "su_dif": 0,
        "subunit": "Denmark",
        "su_a3": "DNK",
        "brk_diff": 0,
        "name": "Denmark",
        "name_long": "Denmark",
        "brk_a3": "DNK",
        "brk_name": "Denmark",
        "brk_group": "",
        "abbrev": "Den.",
        "postal": "DK",
        "formal_en": "Kingdom of Denmark",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Denmark",
        "name_alt": "",
        "mapcolor7": 4,
        "mapcolor8": 1,
        "mapcolor9": 3,
        "mapcolor13": 12,
        "pop_est": 5500510,
        "gdp_md_est": 203600,
        "pop_year": -99,
        "lastcensus": 2011,
        "gdp_year": -99,
        "economy": "2. Developed region: nonG7",
        "income_grp": "1. High income: OECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "DK",
        "iso_a3": "DNK",
        "iso_n3": "208",
        "un_a3": "208",
        "wb_a2": "DK",
        "wb_a3": "DNK",
        "woe_id": -99,
        "adm0_a3_is": "DNK",
        "adm0_a3_us": "DNK",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Northern Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Dominican Rep.": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 5,
        "sovereignt": "Dominican Republic",
        "sov_a3": "DOM",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Dominican Republic",
        "adm0_a3": "DOM",
        "geou_dif": 0,
        "geounit": "Dominican Republic",
        "gu_a3": "DOM",
        "su_dif": 0,
        "subunit": "Dominican Republic",
        "su_a3": "DOM",
        "brk_diff": 0,
        "name": "Dominican Rep.",
        "name_long": "Dominican Republic",
        "brk_a3": "DOM",
        "brk_name": "Dominican Rep.",
        "brk_group": "",
        "abbrev": "Dom. Rep.",
        "postal": "DO",
        "formal_en": "Dominican Republic",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Dominican Republic",
        "name_alt": "",
        "mapcolor7": 5,
        "mapcolor8": 2,
        "mapcolor9": 5,
        "mapcolor13": 7,
        "pop_est": 9650054,
        "gdp_md_est": 78000,
        "pop_year": -99,
        "lastcensus": 2010,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "DO",
        "iso_a3": "DOM",
        "iso_n3": "214",
        "un_a3": "214",
        "wb_a2": "DO",
        "wb_a3": "DOM",
        "woe_id": -99,
        "adm0_a3_is": "DOM",
        "adm0_a3_us": "DOM",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "North America",
        "region_un": "Americas",
        "subregion": "Caribbean",
        "region_wb": "Latin America & Caribbean",
        "name_len": 14,
        "long_len": 18,
        "abbrev_len": 9,
        "tiny": -99,
        "homepart": 1
    },
    "Algeria": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Algeria",
        "sov_a3": "DZA",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Algeria",
        "adm0_a3": "DZA",
        "geou_dif": 0,
        "geounit": "Algeria",
        "gu_a3": "DZA",
        "su_dif": 0,
        "subunit": "Algeria",
        "su_a3": "DZA",
        "brk_diff": 0,
        "name": "Algeria",
        "name_long": "Algeria",
        "brk_a3": "DZA",
        "brk_name": "Algeria",
        "brk_group": "",
        "abbrev": "Alg.",
        "postal": "DZ",
        "formal_en": "People's Democratic Republic of Algeria",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Algeria",
        "name_alt": "",
        "mapcolor7": 5,
        "mapcolor8": 1,
        "mapcolor9": 6,
        "mapcolor13": 3,
        "pop_est": 34178188,
        "gdp_md_est": 232900,
        "pop_year": -99,
        "lastcensus": 2008,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "DZ",
        "iso_a3": "DZA",
        "iso_n3": "012",
        "un_a3": "012",
        "wb_a2": "DZ",
        "wb_a3": "DZA",
        "woe_id": -99,
        "adm0_a3_is": "DZA",
        "adm0_a3_us": "DZA",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Northern Africa",
        "region_wb": "Middle East & North Africa",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Ecuador": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Ecuador",
        "sov_a3": "ECU",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Ecuador",
        "adm0_a3": "ECU",
        "geou_dif": 0,
        "geounit": "Ecuador",
        "gu_a3": "ECU",
        "su_dif": 0,
        "subunit": "Ecuador",
        "su_a3": "ECU",
        "brk_diff": 0,
        "name": "Ecuador",
        "name_long": "Ecuador",
        "brk_a3": "ECU",
        "brk_name": "Ecuador",
        "brk_group": "",
        "abbrev": "Ecu.",
        "postal": "EC",
        "formal_en": "Republic of Ecuador",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Ecuador",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 5,
        "mapcolor9": 2,
        "mapcolor13": 12,
        "pop_est": 14573101,
        "gdp_md_est": 107700,
        "pop_year": -99,
        "lastcensus": 2010,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "EC",
        "iso_a3": "ECU",
        "iso_n3": "218",
        "un_a3": "218",
        "wb_a2": "EC",
        "wb_a3": "ECU",
        "woe_id": -99,
        "adm0_a3_is": "ECU",
        "adm0_a3_us": "ECU",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "South America",
        "region_un": "Americas",
        "subregion": "South America",
        "region_wb": "Latin America & Caribbean",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Egypt": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "Egypt",
        "sov_a3": "EGY",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Egypt",
        "adm0_a3": "EGY",
        "geou_dif": 0,
        "geounit": "Egypt",
        "gu_a3": "EGY",
        "su_dif": 0,
        "subunit": "Egypt",
        "su_a3": "EGY",
        "brk_diff": 0,
        "name": "Egypt",
        "name_long": "Egypt",
        "brk_a3": "EGY",
        "brk_name": "Egypt",
        "brk_group": "",
        "abbrev": "Egypt",
        "postal": "EG",
        "formal_en": "Arab Republic of Egypt",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Egypt, Arab Rep.",
        "name_alt": "",
        "mapcolor7": 4,
        "mapcolor8": 6,
        "mapcolor9": 7,
        "mapcolor13": 2,
        "pop_est": 83082869,
        "gdp_md_est": 443700,
        "pop_year": -99,
        "lastcensus": 2006,
        "gdp_year": -99,
        "economy": "5. Emerging region: G20",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "EG",
        "iso_a3": "EGY",
        "iso_n3": "818",
        "un_a3": "818",
        "wb_a2": "EG",
        "wb_a3": "EGY",
        "woe_id": -99,
        "adm0_a3_is": "EGY",
        "adm0_a3_us": "EGY",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Northern Africa",
        "region_wb": "Middle East & North Africa",
        "name_len": 5,
        "long_len": 5,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "Eritrea": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 4,
        "sovereignt": "Eritrea",
        "sov_a3": "ERI",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Eritrea",
        "adm0_a3": "ERI",
        "geou_dif": 0,
        "geounit": "Eritrea",
        "gu_a3": "ERI",
        "su_dif": 0,
        "subunit": "Eritrea",
        "su_a3": "ERI",
        "brk_diff": 0,
        "name": "Eritrea",
        "name_long": "Eritrea",
        "brk_a3": "ERI",
        "brk_name": "Eritrea",
        "brk_group": "",
        "abbrev": "Erit.",
        "postal": "ER",
        "formal_en": "State of Eritrea",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Eritrea",
        "name_alt": "",
        "mapcolor7": 3,
        "mapcolor8": 1,
        "mapcolor9": 2,
        "mapcolor13": 12,
        "pop_est": 5647168,
        "gdp_md_est": 3945,
        "pop_year": -99,
        "lastcensus": 1984,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "ER",
        "iso_a3": "ERI",
        "iso_n3": "232",
        "un_a3": "232",
        "wb_a2": "ER",
        "wb_a3": "ERI",
        "woe_id": -99,
        "adm0_a3_is": "ERI",
        "adm0_a3_us": "ERI",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Eastern Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "Spain": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "Spain",
        "sov_a3": "ESP",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Spain",
        "adm0_a3": "ESP",
        "geou_dif": 0,
        "geounit": "Spain",
        "gu_a3": "ESP",
        "su_dif": 0,
        "subunit": "Spain",
        "su_a3": "ESP",
        "brk_diff": 0,
        "name": "Spain",
        "name_long": "Spain",
        "brk_a3": "ESP",
        "brk_name": "Spain",
        "brk_group": "",
        "abbrev": "Sp.",
        "postal": "E",
        "formal_en": "Kingdom of Spain",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Spain",
        "name_alt": "",
        "mapcolor7": 4,
        "mapcolor8": 5,
        "mapcolor9": 5,
        "mapcolor13": 5,
        "pop_est": 40525002,
        "gdp_md_est": 1403000,
        "pop_year": -99,
        "lastcensus": 2001,
        "gdp_year": -99,
        "economy": "2. Developed region: nonG7",
        "income_grp": "1. High income: OECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "ES",
        "iso_a3": "ESP",
        "iso_n3": "724",
        "un_a3": "724",
        "wb_a2": "ES",
        "wb_a3": "ESP",
        "woe_id": -99,
        "adm0_a3_is": "ESP",
        "adm0_a3_us": "ESP",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Southern Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 5,
        "long_len": 5,
        "abbrev_len": 3,
        "tiny": -99,
        "homepart": 1
    },
    "Estonia": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 6,
        "sovereignt": "Estonia",
        "sov_a3": "EST",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Estonia",
        "adm0_a3": "EST",
        "geou_dif": 0,
        "geounit": "Estonia",
        "gu_a3": "EST",
        "su_dif": 0,
        "subunit": "Estonia",
        "su_a3": "EST",
        "brk_diff": 0,
        "name": "Estonia",
        "name_long": "Estonia",
        "brk_a3": "EST",
        "brk_name": "Estonia",
        "brk_group": "",
        "abbrev": "Est.",
        "postal": "EST",
        "formal_en": "Republic of Estonia",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Estonia",
        "name_alt": "",
        "mapcolor7": 3,
        "mapcolor8": 2,
        "mapcolor9": 1,
        "mapcolor13": 10,
        "pop_est": 1299371,
        "gdp_md_est": 27410,
        "pop_year": -99,
        "lastcensus": 2000,
        "gdp_year": -99,
        "economy": "2. Developed region: nonG7",
        "income_grp": "1. High income: OECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "EE",
        "iso_a3": "EST",
        "iso_n3": "233",
        "un_a3": "233",
        "wb_a2": "EE",
        "wb_a3": "EST",
        "woe_id": -99,
        "adm0_a3_is": "EST",
        "adm0_a3_us": "EST",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Northern Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Ethiopia": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "Ethiopia",
        "sov_a3": "ETH",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Ethiopia",
        "adm0_a3": "ETH",
        "geou_dif": 0,
        "geounit": "Ethiopia",
        "gu_a3": "ETH",
        "su_dif": 0,
        "subunit": "Ethiopia",
        "su_a3": "ETH",
        "brk_diff": 0,
        "name": "Ethiopia",
        "name_long": "Ethiopia",
        "brk_a3": "ETH",
        "brk_name": "Ethiopia",
        "brk_group": "",
        "abbrev": "Eth.",
        "postal": "ET",
        "formal_en": "Federal Democratic Republic of Ethiopia",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Ethiopia",
        "name_alt": "",
        "mapcolor7": 4,
        "mapcolor8": 4,
        "mapcolor9": 1,
        "mapcolor13": 13,
        "pop_est": 85237338,
        "gdp_md_est": 68770,
        "pop_year": -99,
        "lastcensus": 2007,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "ET",
        "iso_a3": "ETH",
        "iso_n3": "231",
        "un_a3": "231",
        "wb_a2": "ET",
        "wb_a3": "ETH",
        "woe_id": -99,
        "adm0_a3_is": "ETH",
        "adm0_a3_us": "ETH",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Eastern Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 8,
        "long_len": 8,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Finland": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Finland",
        "sov_a3": "FI1",
        "adm0_dif": 1,
        "level": 2,
        "type": "Country",
        "admin": "Finland",
        "adm0_a3": "FIN",
        "geou_dif": 0,
        "geounit": "Finland",
        "gu_a3": "FIN",
        "su_dif": 0,
        "subunit": "Finland",
        "su_a3": "FIN",
        "brk_diff": 0,
        "name": "Finland",
        "name_long": "Finland",
        "brk_a3": "FIN",
        "brk_name": "Finland",
        "brk_group": "",
        "abbrev": "Fin.",
        "postal": "FIN",
        "formal_en": "Republic of Finland",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Finland",
        "name_alt": "",
        "mapcolor7": 4,
        "mapcolor8": 1,
        "mapcolor9": 4,
        "mapcolor13": 6,
        "pop_est": 5250275,
        "gdp_md_est": 193500,
        "pop_year": -99,
        "lastcensus": 2010,
        "gdp_year": -99,
        "economy": "2. Developed region: nonG7",
        "income_grp": "1. High income: OECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "FI",
        "iso_a3": "FIN",
        "iso_n3": "246",
        "un_a3": "246",
        "wb_a2": "FI",
        "wb_a3": "FIN",
        "woe_id": -99,
        "adm0_a3_is": "FIN",
        "adm0_a3_us": "FIN",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Northern Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Fiji": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 6,
        "sovereignt": "Fiji",
        "sov_a3": "FJI",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Fiji",
        "adm0_a3": "FJI",
        "geou_dif": 0,
        "geounit": "Fiji",
        "gu_a3": "FJI",
        "su_dif": 0,
        "subunit": "Fiji",
        "su_a3": "FJI",
        "brk_diff": 0,
        "name": "Fiji",
        "name_long": "Fiji",
        "brk_a3": "FJI",
        "brk_name": "Fiji",
        "brk_group": "",
        "abbrev": "Fiji",
        "postal": "FJ",
        "formal_en": "Republic of Fiji",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Fiji",
        "name_alt": "",
        "mapcolor7": 5,
        "mapcolor8": 1,
        "mapcolor9": 2,
        "mapcolor13": 2,
        "pop_est": 944720,
        "gdp_md_est": 3579,
        "pop_year": -99,
        "lastcensus": 2007,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "FJ",
        "iso_a3": "FJI",
        "iso_n3": "242",
        "un_a3": "242",
        "wb_a2": "FJ",
        "wb_a3": "FJI",
        "woe_id": -99,
        "adm0_a3_is": "FJI",
        "adm0_a3_us": "FJI",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Oceania",
        "region_un": "Oceania",
        "subregion": "Melanesia",
        "region_wb": "East Asia & Pacific",
        "name_len": 4,
        "long_len": 4,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Falkland Is.": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 5,
        "sovereignt": "United Kingdom",
        "sov_a3": "GB1",
        "adm0_dif": 1,
        "level": 2,
        "type": "Dependency",
        "admin": "Falkland Islands",
        "adm0_a3": "FLK",
        "geou_dif": 0,
        "geounit": "Falkland Islands",
        "gu_a3": "FLK",
        "su_dif": 0,
        "subunit": "Falkland Islands",
        "su_a3": "FLK",
        "brk_diff": 1,
        "name": "Falkland Is.",
        "name_long": "Falkland Islands",
        "brk_a3": "B12",
        "brk_name": "Falkland Is.",
        "brk_group": "",
        "abbrev": "Flk. Is.",
        "postal": "FK",
        "formal_en": "Falkland Islands",
        "formal_fr": "",
        "note_adm0": "U.K.",
        "note_brk": "Admin. by U.K.; Claimed by Argentina",
        "name_sort": "Falkland Islands",
        "name_alt": "Islas Malvinas",
        "mapcolor7": 6,
        "mapcolor8": 6,
        "mapcolor9": 6,
        "mapcolor13": 3,
        "pop_est": 3140,
        "gdp_md_est": 105.1,
        "pop_year": -99,
        "lastcensus": -99,
        "gdp_year": -99,
        "economy": "2. Developed region: nonG7",
        "income_grp": "1. High income: OECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "FK",
        "iso_a3": "FLK",
        "iso_n3": "238",
        "un_a3": "238",
        "wb_a2": "-99",
        "wb_a3": "-99",
        "woe_id": -99,
        "adm0_a3_is": "FLK",
        "adm0_a3_us": "FLK",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "South America",
        "region_un": "Americas",
        "subregion": "South America",
        "region_wb": "Latin America & Caribbean",
        "name_len": 12,
        "long_len": 16,
        "abbrev_len": 8,
        "tiny": -99,
        "homepart": -99
    },
    "France": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "France",
        "sov_a3": "FR1",
        "adm0_dif": 1,
        "level": 2,
        "type": "Country",
        "admin": "France",
        "adm0_a3": "FRA",
        "geou_dif": 0,
        "geounit": "France",
        "gu_a3": "FRA",
        "su_dif": 0,
        "subunit": "France",
        "su_a3": "FRA",
        "brk_diff": 0,
        "name": "France",
        "name_long": "France",
        "brk_a3": "FRA",
        "brk_name": "France",
        "brk_group": "",
        "abbrev": "Fr.",
        "postal": "F",
        "formal_en": "French Republic",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "France",
        "name_alt": "",
        "mapcolor7": 7,
        "mapcolor8": 5,
        "mapcolor9": 9,
        "mapcolor13": 11,
        "pop_est": 64057792,
        "gdp_md_est": 2128000,
        "pop_year": -99,
        "lastcensus": -99,
        "gdp_year": -99,
        "economy": "1. Developed region: G7",
        "income_grp": "1. High income: OECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "FR",
        "iso_a3": "FRA",
        "iso_n3": "250",
        "un_a3": "250",
        "wb_a2": "FR",
        "wb_a3": "FRA",
        "woe_id": -99,
        "adm0_a3_is": "FRA",
        "adm0_a3_us": "FRA",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Western Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 6,
        "long_len": 6,
        "abbrev_len": 3,
        "tiny": -99,
        "homepart": 1
    },
    "Gabon": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 4,
        "sovereignt": "Gabon",
        "sov_a3": "GAB",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Gabon",
        "adm0_a3": "GAB",
        "geou_dif": 0,
        "geounit": "Gabon",
        "gu_a3": "GAB",
        "su_dif": 0,
        "subunit": "Gabon",
        "su_a3": "GAB",
        "brk_diff": 0,
        "name": "Gabon",
        "name_long": "Gabon",
        "brk_a3": "GAB",
        "brk_name": "Gabon",
        "brk_group": "",
        "abbrev": "Gabon",
        "postal": "GA",
        "formal_en": "Gabonese Republic",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Gabon",
        "name_alt": "",
        "mapcolor7": 6,
        "mapcolor8": 2,
        "mapcolor9": 5,
        "mapcolor13": 5,
        "pop_est": 1514993,
        "gdp_md_est": 21110,
        "pop_year": -99,
        "lastcensus": 2003,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "GA",
        "iso_a3": "GAB",
        "iso_n3": "266",
        "un_a3": "266",
        "wb_a2": "GA",
        "wb_a3": "GAB",
        "woe_id": -99,
        "adm0_a3_is": "GAB",
        "adm0_a3_us": "GAB",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Middle Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 5,
        "long_len": 5,
        "abbrev_len": 5,
        "tiny": 3,
        "homepart": 1
    },
    "United Kingdom": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "United Kingdom",
        "sov_a3": "GB1",
        "adm0_dif": 1,
        "level": 2,
        "type": "Country",
        "admin": "United Kingdom",
        "adm0_a3": "GBR",
        "geou_dif": 0,
        "geounit": "United Kingdom",
        "gu_a3": "GBR",
        "su_dif": 0,
        "subunit": "United Kingdom",
        "su_a3": "GBR",
        "brk_diff": 0,
        "name": "United Kingdom",
        "name_long": "United Kingdom",
        "brk_a3": "GBR",
        "brk_name": "United Kingdom",
        "brk_group": "",
        "abbrev": "U.K.",
        "postal": "GB",
        "formal_en": "United Kingdom of Great Britain and Northern Ireland",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "United Kingdom",
        "name_alt": "",
        "mapcolor7": 6,
        "mapcolor8": 6,
        "mapcolor9": 6,
        "mapcolor13": 3,
        "pop_est": 62262000,
        "gdp_md_est": 1977704,
        "pop_year": 0,
        "lastcensus": 2011,
        "gdp_year": 2009,
        "economy": "1. Developed region: G7",
        "income_grp": "1. High income: OECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "GB",
        "iso_a3": "GBR",
        "iso_n3": "826",
        "un_a3": "826",
        "wb_a2": "GB",
        "wb_a3": "GBR",
        "woe_id": -99,
        "adm0_a3_is": "GBR",
        "adm0_a3_us": "GBR",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Northern Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 14,
        "long_len": 14,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Georgia": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 5,
        "sovereignt": "Georgia",
        "sov_a3": "GEO",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Georgia",
        "adm0_a3": "GEO",
        "geou_dif": 0,
        "geounit": "Georgia",
        "gu_a3": "GEO",
        "su_dif": 0,
        "subunit": "Georgia",
        "su_a3": "GEO",
        "brk_diff": 0,
        "name": "Georgia",
        "name_long": "Georgia",
        "brk_a3": "GEO",
        "brk_name": "Georgia",
        "brk_group": "",
        "abbrev": "Geo.",
        "postal": "GE",
        "formal_en": "Georgia",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Georgia",
        "name_alt": "",
        "mapcolor7": 5,
        "mapcolor8": 1,
        "mapcolor9": 3,
        "mapcolor13": 2,
        "pop_est": 4615807,
        "gdp_md_est": 21510,
        "pop_year": -99,
        "lastcensus": 2002,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "GE",
        "iso_a3": "GEO",
        "iso_n3": "268",
        "un_a3": "268",
        "wb_a2": "GE",
        "wb_a3": "GEO",
        "woe_id": -99,
        "adm0_a3_is": "GEO",
        "adm0_a3_us": "GEO",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Western Asia",
        "region_wb": "Europe & Central Asia",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Ghana": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Ghana",
        "sov_a3": "GHA",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Ghana",
        "adm0_a3": "GHA",
        "geou_dif": 0,
        "geounit": "Ghana",
        "gu_a3": "GHA",
        "su_dif": 0,
        "subunit": "Ghana",
        "su_a3": "GHA",
        "brk_diff": 0,
        "name": "Ghana",
        "name_long": "Ghana",
        "brk_a3": "GHA",
        "brk_name": "Ghana",
        "brk_group": "",
        "abbrev": "Ghana",
        "postal": "GH",
        "formal_en": "Republic of Ghana",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Ghana",
        "name_alt": "",
        "mapcolor7": 5,
        "mapcolor8": 3,
        "mapcolor9": 1,
        "mapcolor13": 4,
        "pop_est": 23832495,
        "gdp_md_est": 34200,
        "pop_year": -99,
        "lastcensus": 2010,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "GH",
        "iso_a3": "GHA",
        "iso_n3": "288",
        "un_a3": "288",
        "wb_a2": "GH",
        "wb_a3": "GHA",
        "woe_id": -99,
        "adm0_a3_is": "GHA",
        "adm0_a3_us": "GHA",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Western Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 5,
        "long_len": 5,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "Guinea": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Guinea",
        "sov_a3": "GIN",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Guinea",
        "adm0_a3": "GIN",
        "geou_dif": 0,
        "geounit": "Guinea",
        "gu_a3": "GIN",
        "su_dif": 0,
        "subunit": "Guinea",
        "su_a3": "GIN",
        "brk_diff": 0,
        "name": "Guinea",
        "name_long": "Guinea",
        "brk_a3": "GIN",
        "brk_name": "Guinea",
        "brk_group": "",
        "abbrev": "Gin.",
        "postal": "GN",
        "formal_en": "Republic of Guinea",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Guinea",
        "name_alt": "",
        "mapcolor7": 6,
        "mapcolor8": 3,
        "mapcolor9": 7,
        "mapcolor13": 2,
        "pop_est": 10057975,
        "gdp_md_est": 10600,
        "pop_year": -99,
        "lastcensus": 1996,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "GN",
        "iso_a3": "GIN",
        "iso_n3": "324",
        "un_a3": "324",
        "wb_a2": "GN",
        "wb_a3": "GIN",
        "woe_id": -99,
        "adm0_a3_is": "GIN",
        "adm0_a3_us": "GIN",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Western Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 6,
        "long_len": 6,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Gambia": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 6,
        "sovereignt": "Gambia",
        "sov_a3": "GMB",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Gambia",
        "adm0_a3": "GMB",
        "geou_dif": 0,
        "geounit": "Gambia",
        "gu_a3": "GMB",
        "su_dif": 0,
        "subunit": "Gambia",
        "su_a3": "GMB",
        "brk_diff": 0,
        "name": "Gambia",
        "name_long": "The Gambia",
        "brk_a3": "GMB",
        "brk_name": "Gambia",
        "brk_group": "",
        "abbrev": "Gambia",
        "postal": "GM",
        "formal_en": "Republic of the Gambia",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Gambia, The",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 4,
        "mapcolor9": 1,
        "mapcolor13": 8,
        "pop_est": 1782893,
        "gdp_md_est": 2272,
        "pop_year": -99,
        "lastcensus": 2003,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "GM",
        "iso_a3": "GMB",
        "iso_n3": "270",
        "un_a3": "270",
        "wb_a2": "GM",
        "wb_a3": "GMB",
        "woe_id": -99,
        "adm0_a3_is": "GMB",
        "adm0_a3_us": "GMB",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Western Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 6,
        "long_len": 10,
        "abbrev_len": 6,
        "tiny": -99,
        "homepart": 1
    },
    "Guinea-Bissau": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 6,
        "sovereignt": "Guinea Bissau",
        "sov_a3": "GNB",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Guinea Bissau",
        "adm0_a3": "GNB",
        "geou_dif": 0,
        "geounit": "Guinea Bissau",
        "gu_a3": "GNB",
        "su_dif": 0,
        "subunit": "Guinea Bissau",
        "su_a3": "GNB",
        "brk_diff": 0,
        "name": "Guinea-Bissau",
        "name_long": "Guinea-Bissau",
        "brk_a3": "GNB",
        "brk_name": "Guinea-Bissau",
        "brk_group": "",
        "abbrev": "GnB.",
        "postal": "GW",
        "formal_en": "Republic of Guinea-Bissau",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Guinea-Bissau",
        "name_alt": "",
        "mapcolor7": 3,
        "mapcolor8": 5,
        "mapcolor9": 3,
        "mapcolor13": 4,
        "pop_est": 1533964,
        "gdp_md_est": 904.2,
        "pop_year": -99,
        "lastcensus": 2009,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "GW",
        "iso_a3": "GNB",
        "iso_n3": "624",
        "un_a3": "624",
        "wb_a2": "GW",
        "wb_a3": "GNB",
        "woe_id": -99,
        "adm0_a3_is": "GNB",
        "adm0_a3_us": "GNB",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Western Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 13,
        "long_len": 13,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Eq. Guinea": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 4,
        "sovereignt": "Equatorial Guinea",
        "sov_a3": "GNQ",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Equatorial Guinea",
        "adm0_a3": "GNQ",
        "geou_dif": 0,
        "geounit": "Equatorial Guinea",
        "gu_a3": "GNQ",
        "su_dif": 0,
        "subunit": "Equatorial Guinea",
        "su_a3": "GNQ",
        "brk_diff": 0,
        "name": "Eq. Guinea",
        "name_long": "Equatorial Guinea",
        "brk_a3": "GNQ",
        "brk_name": "Eq. Guinea",
        "brk_group": "",
        "abbrev": "Eq. G.",
        "postal": "GQ",
        "formal_en": "Republic of Equatorial Guinea",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Equatorial Guinea",
        "name_alt": "",
        "mapcolor7": 4,
        "mapcolor8": 1,
        "mapcolor9": 4,
        "mapcolor13": 8,
        "pop_est": 650702,
        "gdp_md_est": 14060,
        "pop_year": 0,
        "lastcensus": 2002,
        "gdp_year": 0,
        "economy": "7. Least developed region",
        "income_grp": "2. High income: nonOECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "GQ",
        "iso_a3": "GNQ",
        "iso_n3": "226",
        "un_a3": "226",
        "wb_a2": "GQ",
        "wb_a3": "GNQ",
        "woe_id": -99,
        "adm0_a3_is": "GNQ",
        "adm0_a3_us": "GNQ",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Middle Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 10,
        "long_len": 17,
        "abbrev_len": 6,
        "tiny": -99,
        "homepart": 1
    },
    "Greece": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Greece",
        "sov_a3": "GRC",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Greece",
        "adm0_a3": "GRC",
        "geou_dif": 0,
        "geounit": "Greece",
        "gu_a3": "GRC",
        "su_dif": 0,
        "subunit": "Greece",
        "su_a3": "GRC",
        "brk_diff": 0,
        "name": "Greece",
        "name_long": "Greece",
        "brk_a3": "GRC",
        "brk_name": "Greece",
        "brk_group": "",
        "abbrev": "Greece",
        "postal": "GR",
        "formal_en": "Hellenic Republic",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Greece",
        "name_alt": "",
        "mapcolor7": 2,
        "mapcolor8": 2,
        "mapcolor9": 2,
        "mapcolor13": 9,
        "pop_est": 10737428,
        "gdp_md_est": 343000,
        "pop_year": -99,
        "lastcensus": 2011,
        "gdp_year": -99,
        "economy": "2. Developed region: nonG7",
        "income_grp": "1. High income: OECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "GR",
        "iso_a3": "GRC",
        "iso_n3": "300",
        "un_a3": "300",
        "wb_a2": "GR",
        "wb_a3": "GRC",
        "woe_id": -99,
        "adm0_a3_is": "GRC",
        "adm0_a3_us": "GRC",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Southern Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 6,
        "long_len": 6,
        "abbrev_len": 6,
        "tiny": -99,
        "homepart": 1
    },
    "Greenland": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Denmark",
        "sov_a3": "DN1",
        "adm0_dif": 1,
        "level": 2,
        "type": "Country",
        "admin": "Greenland",
        "adm0_a3": "GRL",
        "geou_dif": 0,
        "geounit": "Greenland",
        "gu_a3": "GRL",
        "su_dif": 0,
        "subunit": "Greenland",
        "su_a3": "GRL",
        "brk_diff": 0,
        "name": "Greenland",
        "name_long": "Greenland",
        "brk_a3": "GRL",
        "brk_name": "Greenland",
        "brk_group": "",
        "abbrev": "Grlnd.",
        "postal": "GL",
        "formal_en": "Greenland",
        "formal_fr": "",
        "note_adm0": "Den.",
        "note_brk": "",
        "name_sort": "Greenland",
        "name_alt": "",
        "mapcolor7": 4,
        "mapcolor8": 1,
        "mapcolor9": 3,
        "mapcolor13": 12,
        "pop_est": 57600,
        "gdp_md_est": 1100,
        "pop_year": -99,
        "lastcensus": 2010,
        "gdp_year": -99,
        "economy": "2. Developed region: nonG7",
        "income_grp": "2. High income: nonOECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "GL",
        "iso_a3": "GRL",
        "iso_n3": "304",
        "un_a3": "304",
        "wb_a2": "GL",
        "wb_a3": "GRL",
        "woe_id": -99,
        "adm0_a3_is": "GRL",
        "adm0_a3_us": "GRL",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "North America",
        "region_un": "Americas",
        "subregion": "Northern America",
        "region_wb": "Europe & Central Asia",
        "name_len": 9,
        "long_len": 9,
        "abbrev_len": 6,
        "tiny": -99,
        "homepart": -99
    },
    "Guatemala": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Guatemala",
        "sov_a3": "GTM",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Guatemala",
        "adm0_a3": "GTM",
        "geou_dif": 0,
        "geounit": "Guatemala",
        "gu_a3": "GTM",
        "su_dif": 0,
        "subunit": "Guatemala",
        "su_a3": "GTM",
        "brk_diff": 0,
        "name": "Guatemala",
        "name_long": "Guatemala",
        "brk_a3": "GTM",
        "brk_name": "Guatemala",
        "brk_group": "",
        "abbrev": "Guat.",
        "postal": "GT",
        "formal_en": "Republic of Guatemala",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Guatemala",
        "name_alt": "",
        "mapcolor7": 3,
        "mapcolor8": 3,
        "mapcolor9": 3,
        "mapcolor13": 6,
        "pop_est": 13276517,
        "gdp_md_est": 68580,
        "pop_year": -99,
        "lastcensus": 2002,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "GT",
        "iso_a3": "GTM",
        "iso_n3": "320",
        "un_a3": "320",
        "wb_a2": "GT",
        "wb_a3": "GTM",
        "woe_id": -99,
        "adm0_a3_is": "GTM",
        "adm0_a3_us": "GTM",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "North America",
        "region_un": "Americas",
        "subregion": "Central America",
        "region_wb": "Latin America & Caribbean",
        "name_len": 9,
        "long_len": 9,
        "abbrev_len": 5,
        "tiny": 4,
        "homepart": 1
    },
    "Guyana": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 4,
        "sovereignt": "Guyana",
        "sov_a3": "GUY",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Guyana",
        "adm0_a3": "GUY",
        "geou_dif": 0,
        "geounit": "Guyana",
        "gu_a3": "GUY",
        "su_dif": 0,
        "subunit": "Guyana",
        "su_a3": "GUY",
        "brk_diff": 0,
        "name": "Guyana",
        "name_long": "Guyana",
        "brk_a3": "GUY",
        "brk_name": "Guyana",
        "brk_group": "",
        "abbrev": "Guy.",
        "postal": "GY",
        "formal_en": "Co-operative Republic of Guyana",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Guyana",
        "name_alt": "",
        "mapcolor7": 3,
        "mapcolor8": 1,
        "mapcolor9": 4,
        "mapcolor13": 8,
        "pop_est": 772298,
        "gdp_md_est": 2966,
        "pop_year": -99,
        "lastcensus": 2002,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "GY",
        "iso_a3": "GUY",
        "iso_n3": "328",
        "un_a3": "328",
        "wb_a2": "GY",
        "wb_a3": "GUY",
        "woe_id": -99,
        "adm0_a3_is": "GUY",
        "adm0_a3_us": "GUY",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "South America",
        "region_un": "Americas",
        "subregion": "South America",
        "region_wb": "Latin America & Caribbean",
        "name_len": 6,
        "long_len": 6,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Honduras": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 5,
        "sovereignt": "Honduras",
        "sov_a3": "HND",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Honduras",
        "adm0_a3": "HND",
        "geou_dif": 0,
        "geounit": "Honduras",
        "gu_a3": "HND",
        "su_dif": 0,
        "subunit": "Honduras",
        "su_a3": "HND",
        "brk_diff": 0,
        "name": "Honduras",
        "name_long": "Honduras",
        "brk_a3": "HND",
        "brk_name": "Honduras",
        "brk_group": "",
        "abbrev": "Hond.",
        "postal": "HN",
        "formal_en": "Republic of Honduras",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Honduras",
        "name_alt": "",
        "mapcolor7": 2,
        "mapcolor8": 5,
        "mapcolor9": 2,
        "mapcolor13": 5,
        "pop_est": 7792854,
        "gdp_md_est": 33720,
        "pop_year": -99,
        "lastcensus": 2001,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "HN",
        "iso_a3": "HND",
        "iso_n3": "340",
        "un_a3": "340",
        "wb_a2": "HN",
        "wb_a3": "HND",
        "woe_id": -99,
        "adm0_a3_is": "HND",
        "adm0_a3_us": "HND",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "North America",
        "region_un": "Americas",
        "subregion": "Central America",
        "region_wb": "Latin America & Caribbean",
        "name_len": 8,
        "long_len": 8,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "Croatia": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 6,
        "sovereignt": "Croatia",
        "sov_a3": "HRV",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Croatia",
        "adm0_a3": "HRV",
        "geou_dif": 0,
        "geounit": "Croatia",
        "gu_a3": "HRV",
        "su_dif": 0,
        "subunit": "Croatia",
        "su_a3": "HRV",
        "brk_diff": 0,
        "name": "Croatia",
        "name_long": "Croatia",
        "brk_a3": "HRV",
        "brk_name": "Croatia",
        "brk_group": "",
        "abbrev": "Cro.",
        "postal": "HR",
        "formal_en": "Republic of Croatia",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Croatia",
        "name_alt": "",
        "mapcolor7": 5,
        "mapcolor8": 4,
        "mapcolor9": 5,
        "mapcolor13": 1,
        "pop_est": 4489409,
        "gdp_md_est": 82390,
        "pop_year": -99,
        "lastcensus": 2011,
        "gdp_year": -99,
        "economy": "2. Developed region: nonG7",
        "income_grp": "2. High income: nonOECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "HR",
        "iso_a3": "HRV",
        "iso_n3": "191",
        "un_a3": "191",
        "wb_a2": "HR",
        "wb_a3": "HRV",
        "woe_id": -99,
        "adm0_a3_is": "HRV",
        "adm0_a3_us": "HRV",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Southern Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Haiti": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 5,
        "sovereignt": "Haiti",
        "sov_a3": "HTI",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Haiti",
        "adm0_a3": "HTI",
        "geou_dif": 0,
        "geounit": "Haiti",
        "gu_a3": "HTI",
        "su_dif": 0,
        "subunit": "Haiti",
        "su_a3": "HTI",
        "brk_diff": 0,
        "name": "Haiti",
        "name_long": "Haiti",
        "brk_a3": "HTI",
        "brk_name": "Haiti",
        "brk_group": "",
        "abbrev": "Haiti",
        "postal": "HT",
        "formal_en": "Republic of Haiti",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Haiti",
        "name_alt": "",
        "mapcolor7": 2,
        "mapcolor8": 1,
        "mapcolor9": 7,
        "mapcolor13": 2,
        "pop_est": 9035536,
        "gdp_md_est": 11500,
        "pop_year": -99,
        "lastcensus": 2003,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "HT",
        "iso_a3": "HTI",
        "iso_n3": "332",
        "un_a3": "332",
        "wb_a2": "HT",
        "wb_a3": "HTI",
        "woe_id": -99,
        "adm0_a3_is": "HTI",
        "adm0_a3_us": "HTI",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "North America",
        "region_un": "Americas",
        "subregion": "Caribbean",
        "region_wb": "Latin America & Caribbean",
        "name_len": 5,
        "long_len": 5,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "Hungary": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 5,
        "sovereignt": "Hungary",
        "sov_a3": "HUN",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Hungary",
        "adm0_a3": "HUN",
        "geou_dif": 0,
        "geounit": "Hungary",
        "gu_a3": "HUN",
        "su_dif": 0,
        "subunit": "Hungary",
        "su_a3": "HUN",
        "brk_diff": 0,
        "name": "Hungary",
        "name_long": "Hungary",
        "brk_a3": "HUN",
        "brk_name": "Hungary",
        "brk_group": "",
        "abbrev": "Hun.",
        "postal": "HU",
        "formal_en": "Republic of Hungary",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Hungary",
        "name_alt": "",
        "mapcolor7": 4,
        "mapcolor8": 6,
        "mapcolor9": 1,
        "mapcolor13": 5,
        "pop_est": 9905596,
        "gdp_md_est": 196600,
        "pop_year": -99,
        "lastcensus": 2001,
        "gdp_year": -99,
        "economy": "2. Developed region: nonG7",
        "income_grp": "1. High income: OECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "HU",
        "iso_a3": "HUN",
        "iso_n3": "348",
        "un_a3": "348",
        "wb_a2": "HU",
        "wb_a3": "HUN",
        "woe_id": -99,
        "adm0_a3_is": "HUN",
        "adm0_a3_us": "HUN",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Eastern Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Indonesia": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "Indonesia",
        "sov_a3": "IDN",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Indonesia",
        "adm0_a3": "IDN",
        "geou_dif": 0,
        "geounit": "Indonesia",
        "gu_a3": "IDN",
        "su_dif": 0,
        "subunit": "Indonesia",
        "su_a3": "IDN",
        "brk_diff": 0,
        "name": "Indonesia",
        "name_long": "Indonesia",
        "brk_a3": "IDN",
        "brk_name": "Indonesia",
        "brk_group": "",
        "abbrev": "Indo.",
        "postal": "INDO",
        "formal_en": "Republic of Indonesia",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Indonesia",
        "name_alt": "",
        "mapcolor7": 6,
        "mapcolor8": 6,
        "mapcolor9": 6,
        "mapcolor13": 11,
        "pop_est": 240271522,
        "gdp_md_est": 914600,
        "pop_year": -99,
        "lastcensus": 2010,
        "gdp_year": -99,
        "economy": "4. Emerging region: MIKT",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "ID",
        "iso_a3": "IDN",
        "iso_n3": "360",
        "un_a3": "360",
        "wb_a2": "ID",
        "wb_a3": "IDN",
        "woe_id": -99,
        "adm0_a3_is": "IDN",
        "adm0_a3_us": "IDN",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "South-Eastern Asia",
        "region_wb": "East Asia & Pacific",
        "name_len": 9,
        "long_len": 9,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "India": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "India",
        "sov_a3": "IND",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "India",
        "adm0_a3": "IND",
        "geou_dif": 0,
        "geounit": "India",
        "gu_a3": "IND",
        "su_dif": 0,
        "subunit": "India",
        "su_a3": "IND",
        "brk_diff": 0,
        "name": "India",
        "name_long": "India",
        "brk_a3": "IND",
        "brk_name": "India",
        "brk_group": "",
        "abbrev": "India",
        "postal": "IND",
        "formal_en": "Republic of India",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "India",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 3,
        "mapcolor9": 2,
        "mapcolor13": 2,
        "pop_est": 1166079220,
        "gdp_md_est": 3297000,
        "pop_year": -99,
        "lastcensus": 2011,
        "gdp_year": -99,
        "economy": "3. Emerging region: BRIC",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "IN",
        "iso_a3": "IND",
        "iso_n3": "356",
        "un_a3": "356",
        "wb_a2": "IN",
        "wb_a3": "IND",
        "woe_id": -99,
        "adm0_a3_is": "IND",
        "adm0_a3_us": "IND",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Southern Asia",
        "region_wb": "South Asia",
        "name_len": 5,
        "long_len": 5,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "Ireland": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Ireland",
        "sov_a3": "IRL",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Ireland",
        "adm0_a3": "IRL",
        "geou_dif": 0,
        "geounit": "Ireland",
        "gu_a3": "IRL",
        "su_dif": 0,
        "subunit": "Ireland",
        "su_a3": "IRL",
        "brk_diff": 0,
        "name": "Ireland",
        "name_long": "Ireland",
        "brk_a3": "IRL",
        "brk_name": "Ireland",
        "brk_group": "",
        "abbrev": "Ire.",
        "postal": "IRL",
        "formal_en": "Ireland",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Ireland",
        "name_alt": "",
        "mapcolor7": 2,
        "mapcolor8": 3,
        "mapcolor9": 2,
        "mapcolor13": 2,
        "pop_est": 4203200,
        "gdp_md_est": 188400,
        "pop_year": -99,
        "lastcensus": 2011,
        "gdp_year": -99,
        "economy": "2. Developed region: nonG7",
        "income_grp": "1. High income: OECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "IE",
        "iso_a3": "IRL",
        "iso_n3": "372",
        "un_a3": "372",
        "wb_a2": "IE",
        "wb_a3": "IRL",
        "woe_id": -99,
        "adm0_a3_is": "IRL",
        "adm0_a3_us": "IRL",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Northern Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Iran": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "Iran",
        "sov_a3": "IRN",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Iran",
        "adm0_a3": "IRN",
        "geou_dif": 0,
        "geounit": "Iran",
        "gu_a3": "IRN",
        "su_dif": 0,
        "subunit": "Iran",
        "su_a3": "IRN",
        "brk_diff": 0,
        "name": "Iran",
        "name_long": "Iran",
        "brk_a3": "IRN",
        "brk_name": "Iran",
        "brk_group": "",
        "abbrev": "Iran",
        "postal": "IRN",
        "formal_en": "Islamic Republic of Iran",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Iran, Islamic Rep.",
        "name_alt": "",
        "mapcolor7": 4,
        "mapcolor8": 3,
        "mapcolor9": 4,
        "mapcolor13": 13,
        "pop_est": 66429284,
        "gdp_md_est": 841700,
        "pop_year": -99,
        "lastcensus": 2006,
        "gdp_year": -99,
        "economy": "5. Emerging region: G20",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "IR",
        "iso_a3": "IRN",
        "iso_n3": "364",
        "un_a3": "364",
        "wb_a2": "IR",
        "wb_a3": "IRN",
        "woe_id": -99,
        "adm0_a3_is": "IRN",
        "adm0_a3_us": "IRN",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Southern Asia",
        "region_wb": "Middle East & North Africa",
        "name_len": 4,
        "long_len": 4,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Iraq": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Iraq",
        "sov_a3": "IRQ",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Iraq",
        "adm0_a3": "IRQ",
        "geou_dif": 0,
        "geounit": "Iraq",
        "gu_a3": "IRQ",
        "su_dif": 0,
        "subunit": "Iraq",
        "su_a3": "IRQ",
        "brk_diff": 0,
        "name": "Iraq",
        "name_long": "Iraq",
        "brk_a3": "IRQ",
        "brk_name": "Iraq",
        "brk_group": "",
        "abbrev": "Iraq",
        "postal": "IRQ",
        "formal_en": "Republic of Iraq",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Iraq",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 4,
        "mapcolor9": 3,
        "mapcolor13": 1,
        "pop_est": 31129225,
        "gdp_md_est": 103900,
        "pop_year": -99,
        "lastcensus": 1997,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "IQ",
        "iso_a3": "IRQ",
        "iso_n3": "368",
        "un_a3": "368",
        "wb_a2": "IQ",
        "wb_a3": "IRQ",
        "woe_id": -99,
        "adm0_a3_is": "IRQ",
        "adm0_a3_us": "IRQ",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Western Asia",
        "region_wb": "Middle East & North Africa",
        "name_len": 4,
        "long_len": 4,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Iceland": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Iceland",
        "sov_a3": "ISL",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Iceland",
        "adm0_a3": "ISL",
        "geou_dif": 0,
        "geounit": "Iceland",
        "gu_a3": "ISL",
        "su_dif": 0,
        "subunit": "Iceland",
        "su_a3": "ISL",
        "brk_diff": 0,
        "name": "Iceland",
        "name_long": "Iceland",
        "brk_a3": "ISL",
        "brk_name": "Iceland",
        "brk_group": "",
        "abbrev": "Iceland",
        "postal": "IS",
        "formal_en": "Republic of Iceland",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Iceland",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 4,
        "mapcolor9": 4,
        "mapcolor13": 9,
        "pop_est": 306694,
        "gdp_md_est": 12710,
        "pop_year": -99,
        "lastcensus": -99,
        "gdp_year": -99,
        "economy": "2. Developed region: nonG7",
        "income_grp": "1. High income: OECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "IS",
        "iso_a3": "ISL",
        "iso_n3": "352",
        "un_a3": "352",
        "wb_a2": "IS",
        "wb_a3": "ISL",
        "woe_id": -99,
        "adm0_a3_is": "ISL",
        "adm0_a3_us": "ISL",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Northern Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 7,
        "tiny": -99,
        "homepart": 1
    },
    "Israel": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 4,
        "sovereignt": "Israel",
        "sov_a3": "ISR",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Israel",
        "adm0_a3": "ISR",
        "geou_dif": 0,
        "geounit": "Israel",
        "gu_a3": "ISR",
        "su_dif": 0,
        "subunit": "Israel",
        "su_a3": "ISR",
        "brk_diff": 0,
        "name": "Israel",
        "name_long": "Israel",
        "brk_a3": "ISR",
        "brk_name": "Israel",
        "brk_group": "",
        "abbrev": "Isr.",
        "postal": "IS",
        "formal_en": "State of Israel",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Israel",
        "name_alt": "",
        "mapcolor7": 3,
        "mapcolor8": 2,
        "mapcolor9": 5,
        "mapcolor13": 9,
        "pop_est": 7233701,
        "gdp_md_est": 201400,
        "pop_year": -99,
        "lastcensus": 2009,
        "gdp_year": -99,
        "economy": "2. Developed region: nonG7",
        "income_grp": "1. High income: OECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "IL",
        "iso_a3": "ISR",
        "iso_n3": "376",
        "un_a3": "376",
        "wb_a2": "IL",
        "wb_a3": "ISR",
        "woe_id": -99,
        "adm0_a3_is": "ISR",
        "adm0_a3_us": "ISR",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Western Asia",
        "region_wb": "Middle East & North Africa",
        "name_len": 6,
        "long_len": 6,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Italy": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "Italy",
        "sov_a3": "ITA",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Italy",
        "adm0_a3": "ITA",
        "geou_dif": 0,
        "geounit": "Italy",
        "gu_a3": "ITA",
        "su_dif": 0,
        "subunit": "Italy",
        "su_a3": "ITA",
        "brk_diff": 0,
        "name": "Italy",
        "name_long": "Italy",
        "brk_a3": "ITA",
        "brk_name": "Italy",
        "brk_group": "",
        "abbrev": "Italy",
        "postal": "I",
        "formal_en": "Italian Republic",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Italy",
        "name_alt": "",
        "mapcolor7": 6,
        "mapcolor8": 7,
        "mapcolor9": 8,
        "mapcolor13": 7,
        "pop_est": 58126212,
        "gdp_md_est": 1823000,
        "pop_year": -99,
        "lastcensus": 2012,
        "gdp_year": -99,
        "economy": "1. Developed region: G7",
        "income_grp": "1. High income: OECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "IT",
        "iso_a3": "ITA",
        "iso_n3": "380",
        "un_a3": "380",
        "wb_a2": "IT",
        "wb_a3": "ITA",
        "woe_id": -99,
        "adm0_a3_is": "ITA",
        "adm0_a3_us": "ITA",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Southern Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 5,
        "long_len": 5,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "Jamaica": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 4,
        "sovereignt": "Jamaica",
        "sov_a3": "JAM",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Jamaica",
        "adm0_a3": "JAM",
        "geou_dif": 0,
        "geounit": "Jamaica",
        "gu_a3": "JAM",
        "su_dif": 0,
        "subunit": "Jamaica",
        "su_a3": "JAM",
        "brk_diff": 0,
        "name": "Jamaica",
        "name_long": "Jamaica",
        "brk_a3": "JAM",
        "brk_name": "Jamaica",
        "brk_group": "",
        "abbrev": "Jam.",
        "postal": "J",
        "formal_en": "Jamaica",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Jamaica",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 2,
        "mapcolor9": 4,
        "mapcolor13": 10,
        "pop_est": 2825928,
        "gdp_md_est": 20910,
        "pop_year": -99,
        "lastcensus": 2011,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "JM",
        "iso_a3": "JAM",
        "iso_n3": "388",
        "un_a3": "388",
        "wb_a2": "JM",
        "wb_a3": "JAM",
        "woe_id": -99,
        "adm0_a3_is": "JAM",
        "adm0_a3_us": "JAM",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "North America",
        "region_un": "Americas",
        "subregion": "Caribbean",
        "region_wb": "Latin America & Caribbean",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Jordan": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 4,
        "sovereignt": "Jordan",
        "sov_a3": "JOR",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Jordan",
        "adm0_a3": "JOR",
        "geou_dif": 0,
        "geounit": "Jordan",
        "gu_a3": "JOR",
        "su_dif": 0,
        "subunit": "Jordan",
        "su_a3": "JOR",
        "brk_diff": 0,
        "name": "Jordan",
        "name_long": "Jordan",
        "brk_a3": "JOR",
        "brk_name": "Jordan",
        "brk_group": "",
        "abbrev": "Jord.",
        "postal": "J",
        "formal_en": "Hashemite Kingdom of Jordan",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Jordan",
        "name_alt": "",
        "mapcolor7": 5,
        "mapcolor8": 3,
        "mapcolor9": 4,
        "mapcolor13": 4,
        "pop_est": 6342948,
        "gdp_md_est": 31610,
        "pop_year": -99,
        "lastcensus": 2004,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "JO",
        "iso_a3": "JOR",
        "iso_n3": "400",
        "un_a3": "400",
        "wb_a2": "JO",
        "wb_a3": "JOR",
        "woe_id": -99,
        "adm0_a3_is": "JOR",
        "adm0_a3_us": "JOR",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Western Asia",
        "region_wb": "Middle East & North Africa",
        "name_len": 6,
        "long_len": 6,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "Japan": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "Japan",
        "sov_a3": "JPN",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Japan",
        "adm0_a3": "JPN",
        "geou_dif": 0,
        "geounit": "Japan",
        "gu_a3": "JPN",
        "su_dif": 0,
        "subunit": "Japan",
        "su_a3": "JPN",
        "brk_diff": 0,
        "name": "Japan",
        "name_long": "Japan",
        "brk_a3": "JPN",
        "brk_name": "Japan",
        "brk_group": "",
        "abbrev": "Japan",
        "postal": "J",
        "formal_en": "Japan",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Japan",
        "name_alt": "",
        "mapcolor7": 5,
        "mapcolor8": 3,
        "mapcolor9": 5,
        "mapcolor13": 4,
        "pop_est": 127078679,
        "gdp_md_est": 4329000,
        "pop_year": -99,
        "lastcensus": 2010,
        "gdp_year": -99,
        "economy": "1. Developed region: G7",
        "income_grp": "1. High income: OECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "JP",
        "iso_a3": "JPN",
        "iso_n3": "392",
        "un_a3": "392",
        "wb_a2": "JP",
        "wb_a3": "JPN",
        "woe_id": -99,
        "adm0_a3_is": "JPN",
        "adm0_a3_us": "JPN",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Eastern Asia",
        "region_wb": "East Asia & Pacific",
        "name_len": 5,
        "long_len": 5,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "Kazakhstan": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Kazakhstan",
        "sov_a3": "KAZ",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Kazakhstan",
        "adm0_a3": "KAZ",
        "geou_dif": 0,
        "geounit": "Kazakhstan",
        "gu_a3": "KAZ",
        "su_dif": 0,
        "subunit": "Kazakhstan",
        "su_a3": "KAZ",
        "brk_diff": 0,
        "name": "Kazakhstan",
        "name_long": "Kazakhstan",
        "brk_a3": "KAZ",
        "brk_name": "Kazakhstan",
        "brk_group": "",
        "abbrev": "Kaz.",
        "postal": "KZ",
        "formal_en": "Republic of Kazakhstan",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Kazakhstan",
        "name_alt": "",
        "mapcolor7": 6,
        "mapcolor8": 1,
        "mapcolor9": 6,
        "mapcolor13": 1,
        "pop_est": 15399437,
        "gdp_md_est": 175800,
        "pop_year": -99,
        "lastcensus": 2009,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "KZ",
        "iso_a3": "KAZ",
        "iso_n3": "398",
        "un_a3": "398",
        "wb_a2": "KZ",
        "wb_a3": "KAZ",
        "woe_id": -99,
        "adm0_a3_is": "KAZ",
        "adm0_a3_us": "KAZ",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Central Asia",
        "region_wb": "Europe & Central Asia",
        "name_len": 10,
        "long_len": 10,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Kenya": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "Kenya",
        "sov_a3": "KEN",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Kenya",
        "adm0_a3": "KEN",
        "geou_dif": 0,
        "geounit": "Kenya",
        "gu_a3": "KEN",
        "su_dif": 0,
        "subunit": "Kenya",
        "su_a3": "KEN",
        "brk_diff": 0,
        "name": "Kenya",
        "name_long": "Kenya",
        "brk_a3": "KEN",
        "brk_name": "Kenya",
        "brk_group": "",
        "abbrev": "Ken.",
        "postal": "KE",
        "formal_en": "Republic of Kenya",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Kenya",
        "name_alt": "",
        "mapcolor7": 5,
        "mapcolor8": 2,
        "mapcolor9": 7,
        "mapcolor13": 3,
        "pop_est": 39002772,
        "gdp_md_est": 61510,
        "pop_year": -99,
        "lastcensus": 2009,
        "gdp_year": -99,
        "economy": "5. Emerging region: G20",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "KE",
        "iso_a3": "KEN",
        "iso_n3": "404",
        "un_a3": "404",
        "wb_a2": "KE",
        "wb_a3": "KEN",
        "woe_id": -99,
        "adm0_a3_is": "KEN",
        "adm0_a3_us": "KEN",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Eastern Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 5,
        "long_len": 5,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Kyrgyzstan": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 4,
        "sovereignt": "Kyrgyzstan",
        "sov_a3": "KGZ",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Kyrgyzstan",
        "adm0_a3": "KGZ",
        "geou_dif": 0,
        "geounit": "Kyrgyzstan",
        "gu_a3": "KGZ",
        "su_dif": 0,
        "subunit": "Kyrgyzstan",
        "su_a3": "KGZ",
        "brk_diff": 0,
        "name": "Kyrgyzstan",
        "name_long": "Kyrgyzstan",
        "brk_a3": "KGZ",
        "brk_name": "Kyrgyzstan",
        "brk_group": "",
        "abbrev": "Kgz.",
        "postal": "KG",
        "formal_en": "Kyrgyz Republic",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Kyrgyz Republic",
        "name_alt": "",
        "mapcolor7": 5,
        "mapcolor8": 7,
        "mapcolor9": 7,
        "mapcolor13": 6,
        "pop_est": 5431747,
        "gdp_md_est": 11610,
        "pop_year": -99,
        "lastcensus": 2009,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "KG",
        "iso_a3": "KGZ",
        "iso_n3": "417",
        "un_a3": "417",
        "wb_a2": "KG",
        "wb_a3": "KGZ",
        "woe_id": -99,
        "adm0_a3_is": "KGZ",
        "adm0_a3_us": "KGZ",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Central Asia",
        "region_wb": "Europe & Central Asia",
        "name_len": 10,
        "long_len": 10,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Cambodia": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Cambodia",
        "sov_a3": "KHM",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Cambodia",
        "adm0_a3": "KHM",
        "geou_dif": 0,
        "geounit": "Cambodia",
        "gu_a3": "KHM",
        "su_dif": 0,
        "subunit": "Cambodia",
        "su_a3": "KHM",
        "brk_diff": 0,
        "name": "Cambodia",
        "name_long": "Cambodia",
        "brk_a3": "KHM",
        "brk_name": "Cambodia",
        "brk_group": "",
        "abbrev": "Camb.",
        "postal": "KH",
        "formal_en": "Kingdom of Cambodia",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Cambodia",
        "name_alt": "",
        "mapcolor7": 6,
        "mapcolor8": 3,
        "mapcolor9": 6,
        "mapcolor13": 5,
        "pop_est": 14494293,
        "gdp_md_est": 27940,
        "pop_year": -99,
        "lastcensus": 2008,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "KH",
        "iso_a3": "KHM",
        "iso_n3": "116",
        "un_a3": "116",
        "wb_a2": "KH",
        "wb_a3": "KHM",
        "woe_id": -99,
        "adm0_a3_is": "KHM",
        "adm0_a3_us": "KHM",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "South-Eastern Asia",
        "region_wb": "East Asia & Pacific",
        "name_len": 8,
        "long_len": 8,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "Korea": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "South Korea",
        "sov_a3": "KOR",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "South Korea",
        "adm0_a3": "KOR",
        "geou_dif": 0,
        "geounit": "South Korea",
        "gu_a3": "KOR",
        "su_dif": 0,
        "subunit": "South Korea",
        "su_a3": "KOR",
        "brk_diff": 0,
        "name": "Korea",
        "name_long": "Republic of Korea",
        "brk_a3": "KOR",
        "brk_name": "Republic of Korea",
        "brk_group": "",
        "abbrev": "S.K.",
        "postal": "KR",
        "formal_en": "Republic of Korea",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Korea, Rep.",
        "name_alt": "",
        "mapcolor7": 4,
        "mapcolor8": 1,
        "mapcolor9": 1,
        "mapcolor13": 5,
        "pop_est": 48508972,
        "gdp_md_est": 1335000,
        "pop_year": -99,
        "lastcensus": 2010,
        "gdp_year": -99,
        "economy": "4. Emerging region: MIKT",
        "income_grp": "1. High income: OECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "KR",
        "iso_a3": "KOR",
        "iso_n3": "410",
        "un_a3": "410",
        "wb_a2": "KR",
        "wb_a3": "KOR",
        "woe_id": -99,
        "adm0_a3_is": "KOR",
        "adm0_a3_us": "KOR",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Eastern Asia",
        "region_wb": "East Asia & Pacific",
        "name_len": 5,
        "long_len": 17,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Kosovo": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 6,
        "sovereignt": "Kosovo",
        "sov_a3": "KOS",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Kosovo",
        "adm0_a3": "KOS",
        "geou_dif": 0,
        "geounit": "Kosovo",
        "gu_a3": "KOS",
        "su_dif": 0,
        "subunit": "Kosovo",
        "su_a3": "KOS",
        "brk_diff": 1,
        "name": "Kosovo",
        "name_long": "Kosovo",
        "brk_a3": "B57",
        "brk_name": "Kosovo",
        "brk_group": "",
        "abbrev": "Kos.",
        "postal": "KO",
        "formal_en": "Republic of Kosovo",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "Self admin.; Claimed by Serbia",
        "name_sort": "Kosovo",
        "name_alt": "",
        "mapcolor7": 2,
        "mapcolor8": 2,
        "mapcolor9": 3,
        "mapcolor13": 11,
        "pop_est": 1804838,
        "gdp_md_est": 5352,
        "pop_year": -99,
        "lastcensus": 1981,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "KV",
        "iso_a3": "-99",
        "iso_n3": "-99",
        "un_a3": "-099",
        "wb_a2": "KV",
        "wb_a3": "KSV",
        "woe_id": -99,
        "adm0_a3_is": "SRB",
        "adm0_a3_us": "KOS",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Southern Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 6,
        "long_len": 6,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Kuwait": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 6,
        "sovereignt": "Kuwait",
        "sov_a3": "KWT",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Kuwait",
        "adm0_a3": "KWT",
        "geou_dif": 0,
        "geounit": "Kuwait",
        "gu_a3": "KWT",
        "su_dif": 0,
        "subunit": "Kuwait",
        "su_a3": "KWT",
        "brk_diff": 0,
        "name": "Kuwait",
        "name_long": "Kuwait",
        "brk_a3": "KWT",
        "brk_name": "Kuwait",
        "brk_group": "",
        "abbrev": "Kwt.",
        "postal": "KW",
        "formal_en": "State of Kuwait",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Kuwait",
        "name_alt": "",
        "mapcolor7": 2,
        "mapcolor8": 2,
        "mapcolor9": 2,
        "mapcolor13": 2,
        "pop_est": 2691158,
        "gdp_md_est": 149100,
        "pop_year": -99,
        "lastcensus": 2010,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "2. High income: nonOECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "KW",
        "iso_a3": "KWT",
        "iso_n3": "414",
        "un_a3": "414",
        "wb_a2": "KW",
        "wb_a3": "KWT",
        "woe_id": -99,
        "adm0_a3_is": "KWT",
        "adm0_a3_us": "KWT",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Western Asia",
        "region_wb": "Middle East & North Africa",
        "name_len": 6,
        "long_len": 6,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Lao PDR": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 4,
        "sovereignt": "Laos",
        "sov_a3": "LAO",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Laos",
        "adm0_a3": "LAO",
        "geou_dif": 0,
        "geounit": "Laos",
        "gu_a3": "LAO",
        "su_dif": 0,
        "subunit": "Laos",
        "su_a3": "LAO",
        "brk_diff": 0,
        "name": "Lao PDR",
        "name_long": "Lao PDR",
        "brk_a3": "LAO",
        "brk_name": "Laos",
        "brk_group": "",
        "abbrev": "Laos",
        "postal": "LA",
        "formal_en": "Lao People's Democratic Republic",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Lao PDR",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 1,
        "mapcolor9": 1,
        "mapcolor13": 9,
        "pop_est": 6834942,
        "gdp_md_est": 13980,
        "pop_year": -99,
        "lastcensus": 2005,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "LA",
        "iso_a3": "LAO",
        "iso_n3": "418",
        "un_a3": "418",
        "wb_a2": "LA",
        "wb_a3": "LAO",
        "woe_id": -99,
        "adm0_a3_is": "LAO",
        "adm0_a3_us": "LAO",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "South-Eastern Asia",
        "region_wb": "East Asia & Pacific",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Lebanon": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 5,
        "sovereignt": "Lebanon",
        "sov_a3": "LBN",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Lebanon",
        "adm0_a3": "LBN",
        "geou_dif": 0,
        "geounit": "Lebanon",
        "gu_a3": "LBN",
        "su_dif": 0,
        "subunit": "Lebanon",
        "su_a3": "LBN",
        "brk_diff": 0,
        "name": "Lebanon",
        "name_long": "Lebanon",
        "brk_a3": "LBN",
        "brk_name": "Lebanon",
        "brk_group": "",
        "abbrev": "Leb.",
        "postal": "LB",
        "formal_en": "Lebanese Republic",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Lebanon",
        "name_alt": "",
        "mapcolor7": 4,
        "mapcolor8": 4,
        "mapcolor9": 4,
        "mapcolor13": 12,
        "pop_est": 4017095,
        "gdp_md_est": 44060,
        "pop_year": -99,
        "lastcensus": 1970,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "LB",
        "iso_a3": "LBN",
        "iso_n3": "422",
        "un_a3": "422",
        "wb_a2": "LB",
        "wb_a3": "LBN",
        "woe_id": -99,
        "adm0_a3_is": "LBN",
        "adm0_a3_us": "LBN",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Western Asia",
        "region_wb": "Middle East & North Africa",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 4,
        "tiny": 4,
        "homepart": 1
    },
    "Liberia": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 4,
        "sovereignt": "Liberia",
        "sov_a3": "LBR",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Liberia",
        "adm0_a3": "LBR",
        "geou_dif": 0,
        "geounit": "Liberia",
        "gu_a3": "LBR",
        "su_dif": 0,
        "subunit": "Liberia",
        "su_a3": "LBR",
        "brk_diff": 0,
        "name": "Liberia",
        "name_long": "Liberia",
        "brk_a3": "LBR",
        "brk_name": "Liberia",
        "brk_group": "",
        "abbrev": "Liberia",
        "postal": "LR",
        "formal_en": "Republic of Liberia",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Liberia",
        "name_alt": "",
        "mapcolor7": 2,
        "mapcolor8": 3,
        "mapcolor9": 4,
        "mapcolor13": 9,
        "pop_est": 3441790,
        "gdp_md_est": 1526,
        "pop_year": -99,
        "lastcensus": 2008,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "LR",
        "iso_a3": "LBR",
        "iso_n3": "430",
        "un_a3": "430",
        "wb_a2": "LR",
        "wb_a3": "LBR",
        "woe_id": -99,
        "adm0_a3_is": "LBR",
        "adm0_a3_us": "LBR",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Western Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 7,
        "tiny": -99,
        "homepart": 1
    },
    "Libya": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Libya",
        "sov_a3": "LBY",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Libya",
        "adm0_a3": "LBY",
        "geou_dif": 0,
        "geounit": "Libya",
        "gu_a3": "LBY",
        "su_dif": 0,
        "subunit": "Libya",
        "su_a3": "LBY",
        "brk_diff": 0,
        "name": "Libya",
        "name_long": "Libya",
        "brk_a3": "LBY",
        "brk_name": "Libya",
        "brk_group": "",
        "abbrev": "Libya",
        "postal": "LY",
        "formal_en": "Libya",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Libya",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 2,
        "mapcolor9": 2,
        "mapcolor13": 11,
        "pop_est": 6310434,
        "gdp_md_est": 88830,
        "pop_year": -99,
        "lastcensus": 2006,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "LY",
        "iso_a3": "LBY",
        "iso_n3": "434",
        "un_a3": "434",
        "wb_a2": "LY",
        "wb_a3": "LBY",
        "woe_id": -99,
        "adm0_a3_is": "LBY",
        "adm0_a3_us": "LBY",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Northern Africa",
        "region_wb": "Middle East & North Africa",
        "name_len": 5,
        "long_len": 5,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "Sri Lanka": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Sri Lanka",
        "sov_a3": "LKA",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Sri Lanka",
        "adm0_a3": "LKA",
        "geou_dif": 0,
        "geounit": "Sri Lanka",
        "gu_a3": "LKA",
        "su_dif": 0,
        "subunit": "Sri Lanka",
        "su_a3": "LKA",
        "brk_diff": 0,
        "name": "Sri Lanka",
        "name_long": "Sri Lanka",
        "brk_a3": "LKA",
        "brk_name": "Sri Lanka",
        "brk_group": "",
        "abbrev": "Sri L.",
        "postal": "LK",
        "formal_en": "Democratic Socialist Republic of Sri Lanka",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Sri Lanka",
        "name_alt": "",
        "mapcolor7": 3,
        "mapcolor8": 5,
        "mapcolor9": 4,
        "mapcolor13": 9,
        "pop_est": 21324791,
        "gdp_md_est": 91870,
        "pop_year": -99,
        "lastcensus": 2001,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "LK",
        "iso_a3": "LKA",
        "iso_n3": "144",
        "un_a3": "144",
        "wb_a2": "LK",
        "wb_a3": "LKA",
        "woe_id": -99,
        "adm0_a3_is": "LKA",
        "adm0_a3_us": "LKA",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Southern Asia",
        "region_wb": "South Asia",
        "name_len": 9,
        "long_len": 9,
        "abbrev_len": 6,
        "tiny": -99,
        "homepart": 1
    },
    "Lesotho": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 6,
        "sovereignt": "Lesotho",
        "sov_a3": "LSO",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Lesotho",
        "adm0_a3": "LSO",
        "geou_dif": 0,
        "geounit": "Lesotho",
        "gu_a3": "LSO",
        "su_dif": 0,
        "subunit": "Lesotho",
        "su_a3": "LSO",
        "brk_diff": 0,
        "name": "Lesotho",
        "name_long": "Lesotho",
        "brk_a3": "LSO",
        "brk_name": "Lesotho",
        "brk_group": "",
        "abbrev": "Les.",
        "postal": "LS",
        "formal_en": "Kingdom of Lesotho",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Lesotho",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 5,
        "mapcolor9": 2,
        "mapcolor13": 8,
        "pop_est": 2130819,
        "gdp_md_est": 3293,
        "pop_year": -99,
        "lastcensus": 2006,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "LS",
        "iso_a3": "LSO",
        "iso_n3": "426",
        "un_a3": "426",
        "wb_a2": "LS",
        "wb_a3": "LSO",
        "woe_id": -99,
        "adm0_a3_is": "LSO",
        "adm0_a3_us": "LSO",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Southern Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Lithuania": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 5,
        "sovereignt": "Lithuania",
        "sov_a3": "LTU",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Lithuania",
        "adm0_a3": "LTU",
        "geou_dif": 0,
        "geounit": "Lithuania",
        "gu_a3": "LTU",
        "su_dif": 0,
        "subunit": "Lithuania",
        "su_a3": "LTU",
        "brk_diff": 0,
        "name": "Lithuania",
        "name_long": "Lithuania",
        "brk_a3": "LTU",
        "brk_name": "Lithuania",
        "brk_group": "",
        "abbrev": "Lith.",
        "postal": "LT",
        "formal_en": "Republic of Lithuania",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Lithuania",
        "name_alt": "",
        "mapcolor7": 6,
        "mapcolor8": 3,
        "mapcolor9": 3,
        "mapcolor13": 9,
        "pop_est": 3555179,
        "gdp_md_est": 63330,
        "pop_year": -99,
        "lastcensus": 2011,
        "gdp_year": -99,
        "economy": "2. Developed region: nonG7",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "LT",
        "iso_a3": "LTU",
        "iso_n3": "440",
        "un_a3": "440",
        "wb_a2": "LT",
        "wb_a3": "LTU",
        "woe_id": -99,
        "adm0_a3_is": "LTU",
        "adm0_a3_us": "LTU",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Northern Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 9,
        "long_len": 9,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "Luxembourg": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 6,
        "sovereignt": "Luxembourg",
        "sov_a3": "LUX",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Luxembourg",
        "adm0_a3": "LUX",
        "geou_dif": 0,
        "geounit": "Luxembourg",
        "gu_a3": "LUX",
        "su_dif": 0,
        "subunit": "Luxembourg",
        "su_a3": "LUX",
        "brk_diff": 0,
        "name": "Luxembourg",
        "name_long": "Luxembourg",
        "brk_a3": "LUX",
        "brk_name": "Luxembourg",
        "brk_group": "",
        "abbrev": "Lux.",
        "postal": "L",
        "formal_en": "Grand Duchy of Luxembourg",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Luxembourg",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 7,
        "mapcolor9": 3,
        "mapcolor13": 7,
        "pop_est": 491775,
        "gdp_md_est": 39370,
        "pop_year": -99,
        "lastcensus": 2011,
        "gdp_year": -99,
        "economy": "2. Developed region: nonG7",
        "income_grp": "1. High income: OECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "LU",
        "iso_a3": "LUX",
        "iso_n3": "442",
        "un_a3": "442",
        "wb_a2": "LU",
        "wb_a3": "LUX",
        "woe_id": -99,
        "adm0_a3_is": "LUX",
        "adm0_a3_us": "LUX",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Western Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 10,
        "long_len": 10,
        "abbrev_len": 4,
        "tiny": 5,
        "homepart": 1
    },
    "Latvia": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 5,
        "sovereignt": "Latvia",
        "sov_a3": "LVA",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Latvia",
        "adm0_a3": "LVA",
        "geou_dif": 0,
        "geounit": "Latvia",
        "gu_a3": "LVA",
        "su_dif": 0,
        "subunit": "Latvia",
        "su_a3": "LVA",
        "brk_diff": 0,
        "name": "Latvia",
        "name_long": "Latvia",
        "brk_a3": "LVA",
        "brk_name": "Latvia",
        "brk_group": "",
        "abbrev": "Lat.",
        "postal": "LV",
        "formal_en": "Republic of Latvia",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Latvia",
        "name_alt": "",
        "mapcolor7": 4,
        "mapcolor8": 7,
        "mapcolor9": 6,
        "mapcolor13": 13,
        "pop_est": 2231503,
        "gdp_md_est": 38860,
        "pop_year": -99,
        "lastcensus": 2011,
        "gdp_year": -99,
        "economy": "2. Developed region: nonG7",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "LV",
        "iso_a3": "LVA",
        "iso_n3": "428",
        "un_a3": "428",
        "wb_a2": "LV",
        "wb_a3": "LVA",
        "woe_id": -99,
        "adm0_a3_is": "LVA",
        "adm0_a3_us": "LVA",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Northern Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 6,
        "long_len": 6,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Morocco": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Morocco",
        "sov_a3": "MAR",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Morocco",
        "adm0_a3": "MAR",
        "geou_dif": 0,
        "geounit": "Morocco",
        "gu_a3": "MAR",
        "su_dif": 0,
        "subunit": "Morocco",
        "su_a3": "MAR",
        "brk_diff": 0,
        "name": "Morocco",
        "name_long": "Morocco",
        "brk_a3": "MAR",
        "brk_name": "Morocco",
        "brk_group": "",
        "abbrev": "Mor.",
        "postal": "MA",
        "formal_en": "Kingdom of Morocco",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Morocco",
        "name_alt": "",
        "mapcolor7": 2,
        "mapcolor8": 2,
        "mapcolor9": 3,
        "mapcolor13": 9,
        "pop_est": 34859364,
        "gdp_md_est": 136600,
        "pop_year": -99,
        "lastcensus": 2004,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "MA",
        "iso_a3": "MAR",
        "iso_n3": "504",
        "un_a3": "504",
        "wb_a2": "MA",
        "wb_a3": "MAR",
        "woe_id": -99,
        "adm0_a3_is": "MAR",
        "adm0_a3_us": "MAR",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Northern Africa",
        "region_wb": "Middle East & North Africa",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Moldova": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 6,
        "sovereignt": "Moldova",
        "sov_a3": "MDA",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Moldova",
        "adm0_a3": "MDA",
        "geou_dif": 0,
        "geounit": "Moldova",
        "gu_a3": "MDA",
        "su_dif": 0,
        "subunit": "Moldova",
        "su_a3": "MDA",
        "brk_diff": 0,
        "name": "Moldova",
        "name_long": "Moldova",
        "brk_a3": "MDA",
        "brk_name": "Moldova",
        "brk_group": "",
        "abbrev": "Mda.",
        "postal": "MD",
        "formal_en": "Republic of Moldova",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Moldova",
        "name_alt": "",
        "mapcolor7": 3,
        "mapcolor8": 5,
        "mapcolor9": 4,
        "mapcolor13": 12,
        "pop_est": 4320748,
        "gdp_md_est": 10670,
        "pop_year": -99,
        "lastcensus": 2004,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "MD",
        "iso_a3": "MDA",
        "iso_n3": "498",
        "un_a3": "498",
        "wb_a2": "MD",
        "wb_a3": "MDA",
        "woe_id": -99,
        "adm0_a3_is": "MDA",
        "adm0_a3_us": "MDA",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Eastern Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Madagascar": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Madagascar",
        "sov_a3": "MDG",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Madagascar",
        "adm0_a3": "MDG",
        "geou_dif": 0,
        "geounit": "Madagascar",
        "gu_a3": "MDG",
        "su_dif": 0,
        "subunit": "Madagascar",
        "su_a3": "MDG",
        "brk_diff": 0,
        "name": "Madagascar",
        "name_long": "Madagascar",
        "brk_a3": "MDG",
        "brk_name": "Madagascar",
        "brk_group": "",
        "abbrev": "Mad.",
        "postal": "MG",
        "formal_en": "Republic of Madagascar",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Madagascar",
        "name_alt": "",
        "mapcolor7": 6,
        "mapcolor8": 5,
        "mapcolor9": 2,
        "mapcolor13": 3,
        "pop_est": 20653556,
        "gdp_md_est": 20130,
        "pop_year": -99,
        "lastcensus": 1993,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "MG",
        "iso_a3": "MDG",
        "iso_n3": "450",
        "un_a3": "450",
        "wb_a2": "MG",
        "wb_a3": "MDG",
        "woe_id": -99,
        "adm0_a3_is": "MDG",
        "adm0_a3_us": "MDG",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Eastern Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 10,
        "long_len": 10,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Mexico": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "Mexico",
        "sov_a3": "MEX",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Mexico",
        "adm0_a3": "MEX",
        "geou_dif": 0,
        "geounit": "Mexico",
        "gu_a3": "MEX",
        "su_dif": 0,
        "subunit": "Mexico",
        "su_a3": "MEX",
        "brk_diff": 0,
        "name": "Mexico",
        "name_long": "Mexico",
        "brk_a3": "MEX",
        "brk_name": "Mexico",
        "brk_group": "",
        "abbrev": "Mex.",
        "postal": "MX",
        "formal_en": "United Mexican States",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Mexico",
        "name_alt": "",
        "mapcolor7": 6,
        "mapcolor8": 1,
        "mapcolor9": 7,
        "mapcolor13": 3,
        "pop_est": 111211789,
        "gdp_md_est": 1563000,
        "pop_year": -99,
        "lastcensus": 2010,
        "gdp_year": -99,
        "economy": "4. Emerging region: MIKT",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "MX",
        "iso_a3": "MEX",
        "iso_n3": "484",
        "un_a3": "484",
        "wb_a2": "MX",
        "wb_a3": "MEX",
        "woe_id": -99,
        "adm0_a3_is": "MEX",
        "adm0_a3_us": "MEX",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "North America",
        "region_un": "Americas",
        "subregion": "Central America",
        "region_wb": "Latin America & Caribbean",
        "name_len": 6,
        "long_len": 6,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Macedonia": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 6,
        "sovereignt": "Macedonia",
        "sov_a3": "MKD",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Macedonia",
        "adm0_a3": "MKD",
        "geou_dif": 0,
        "geounit": "Macedonia",
        "gu_a3": "MKD",
        "su_dif": 0,
        "subunit": "Macedonia",
        "su_a3": "MKD",
        "brk_diff": 0,
        "name": "Macedonia",
        "name_long": "Macedonia",
        "brk_a3": "MKD",
        "brk_name": "Macedonia",
        "brk_group": "",
        "abbrev": "Mkd.",
        "postal": "MK",
        "formal_en": "Former Yugoslav Republic of Macedonia",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Macedonia, FYR",
        "name_alt": "",
        "mapcolor7": 5,
        "mapcolor8": 3,
        "mapcolor9": 7,
        "mapcolor13": 3,
        "pop_est": 2066718,
        "gdp_md_est": 18780,
        "pop_year": -99,
        "lastcensus": 2010,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "MK",
        "iso_a3": "MKD",
        "iso_n3": "807",
        "un_a3": "807",
        "wb_a2": "MK",
        "wb_a3": "MKD",
        "woe_id": -99,
        "adm0_a3_is": "MKD",
        "adm0_a3_us": "MKD",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Southern Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 9,
        "long_len": 9,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Mali": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Mali",
        "sov_a3": "MLI",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Mali",
        "adm0_a3": "MLI",
        "geou_dif": 0,
        "geounit": "Mali",
        "gu_a3": "MLI",
        "su_dif": 0,
        "subunit": "Mali",
        "su_a3": "MLI",
        "brk_diff": 0,
        "name": "Mali",
        "name_long": "Mali",
        "brk_a3": "MLI",
        "brk_name": "Mali",
        "brk_group": "",
        "abbrev": "Mali",
        "postal": "ML",
        "formal_en": "Republic of Mali",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Mali",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 4,
        "mapcolor9": 1,
        "mapcolor13": 7,
        "pop_est": 12666987,
        "gdp_md_est": 14590,
        "pop_year": -99,
        "lastcensus": 2009,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "ML",
        "iso_a3": "MLI",
        "iso_n3": "466",
        "un_a3": "466",
        "wb_a2": "ML",
        "wb_a3": "MLI",
        "woe_id": -99,
        "adm0_a3_is": "MLI",
        "adm0_a3_us": "MLI",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Western Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 4,
        "long_len": 4,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Myanmar": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Myanmar",
        "sov_a3": "MMR",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Myanmar",
        "adm0_a3": "MMR",
        "geou_dif": 0,
        "geounit": "Myanmar",
        "gu_a3": "MMR",
        "su_dif": 0,
        "subunit": "Myanmar",
        "su_a3": "MMR",
        "brk_diff": 0,
        "name": "Myanmar",
        "name_long": "Myanmar",
        "brk_a3": "MMR",
        "brk_name": "Myanmar",
        "brk_group": "",
        "abbrev": "Myan.",
        "postal": "MM",
        "formal_en": "Republic of the Union of Myanmar",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Myanmar",
        "name_alt": "",
        "mapcolor7": 2,
        "mapcolor8": 2,
        "mapcolor9": 5,
        "mapcolor13": 13,
        "pop_est": 48137741,
        "gdp_md_est": 55130,
        "pop_year": -99,
        "lastcensus": 1983,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "MM",
        "iso_a3": "MMR",
        "iso_n3": "104",
        "un_a3": "104",
        "wb_a2": "MM",
        "wb_a3": "MMR",
        "woe_id": -99,
        "adm0_a3_is": "MMR",
        "adm0_a3_us": "MMR",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "South-Eastern Asia",
        "region_wb": "East Asia & Pacific",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "Montenegro": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 6,
        "sovereignt": "Montenegro",
        "sov_a3": "MNE",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Montenegro",
        "adm0_a3": "MNE",
        "geou_dif": 0,
        "geounit": "Montenegro",
        "gu_a3": "MNE",
        "su_dif": 0,
        "subunit": "Montenegro",
        "su_a3": "MNE",
        "brk_diff": 0,
        "name": "Montenegro",
        "name_long": "Montenegro",
        "brk_a3": "MNE",
        "brk_name": "Montenegro",
        "brk_group": "",
        "abbrev": "Mont.",
        "postal": "ME",
        "formal_en": "Montenegro",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Montenegro",
        "name_alt": "",
        "mapcolor7": 4,
        "mapcolor8": 1,
        "mapcolor9": 4,
        "mapcolor13": 5,
        "pop_est": 672180,
        "gdp_md_est": 6816,
        "pop_year": -99,
        "lastcensus": 2011,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "ME",
        "iso_a3": "MNE",
        "iso_n3": "499",
        "un_a3": "499",
        "wb_a2": "ME",
        "wb_a3": "MNE",
        "woe_id": -99,
        "adm0_a3_is": "MNE",
        "adm0_a3_us": "MNE",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Southern Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 10,
        "long_len": 10,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "Mongolia": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Mongolia",
        "sov_a3": "MNG",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Mongolia",
        "adm0_a3": "MNG",
        "geou_dif": 0,
        "geounit": "Mongolia",
        "gu_a3": "MNG",
        "su_dif": 0,
        "subunit": "Mongolia",
        "su_a3": "MNG",
        "brk_diff": 0,
        "name": "Mongolia",
        "name_long": "Mongolia",
        "brk_a3": "MNG",
        "brk_name": "Mongolia",
        "brk_group": "",
        "abbrev": "Mong.",
        "postal": "MN",
        "formal_en": "Mongolia",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Mongolia",
        "name_alt": "",
        "mapcolor7": 3,
        "mapcolor8": 5,
        "mapcolor9": 5,
        "mapcolor13": 6,
        "pop_est": 3041142,
        "gdp_md_est": 9476,
        "pop_year": -99,
        "lastcensus": 2010,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "MN",
        "iso_a3": "MNG",
        "iso_n3": "496",
        "un_a3": "496",
        "wb_a2": "MN",
        "wb_a3": "MNG",
        "woe_id": -99,
        "adm0_a3_is": "MNG",
        "adm0_a3_us": "MNG",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Eastern Asia",
        "region_wb": "East Asia & Pacific",
        "name_len": 8,
        "long_len": 8,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "Mozambique": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Mozambique",
        "sov_a3": "MOZ",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Mozambique",
        "adm0_a3": "MOZ",
        "geou_dif": 0,
        "geounit": "Mozambique",
        "gu_a3": "MOZ",
        "su_dif": 0,
        "subunit": "Mozambique",
        "su_a3": "MOZ",
        "brk_diff": 0,
        "name": "Mozambique",
        "name_long": "Mozambique",
        "brk_a3": "MOZ",
        "brk_name": "Mozambique",
        "brk_group": "",
        "abbrev": "Moz.",
        "postal": "MZ",
        "formal_en": "Republic of Mozambique",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Mozambique",
        "name_alt": "",
        "mapcolor7": 4,
        "mapcolor8": 2,
        "mapcolor9": 1,
        "mapcolor13": 4,
        "pop_est": 21669278,
        "gdp_md_est": 18940,
        "pop_year": -99,
        "lastcensus": 2007,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "MZ",
        "iso_a3": "MOZ",
        "iso_n3": "508",
        "un_a3": "508",
        "wb_a2": "MZ",
        "wb_a3": "MOZ",
        "woe_id": -99,
        "adm0_a3_is": "MOZ",
        "adm0_a3_us": "MOZ",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Eastern Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 10,
        "long_len": 10,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Mauritania": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Mauritania",
        "sov_a3": "MRT",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Mauritania",
        "adm0_a3": "MRT",
        "geou_dif": 0,
        "geounit": "Mauritania",
        "gu_a3": "MRT",
        "su_dif": 0,
        "subunit": "Mauritania",
        "su_a3": "MRT",
        "brk_diff": 0,
        "name": "Mauritania",
        "name_long": "Mauritania",
        "brk_a3": "MRT",
        "brk_name": "Mauritania",
        "brk_group": "",
        "abbrev": "Mrt.",
        "postal": "MR",
        "formal_en": "Islamic Republic of Mauritania",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Mauritania",
        "name_alt": "",
        "mapcolor7": 3,
        "mapcolor8": 3,
        "mapcolor9": 2,
        "mapcolor13": 1,
        "pop_est": 3129486,
        "gdp_md_est": 6308,
        "pop_year": -99,
        "lastcensus": 2000,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "MR",
        "iso_a3": "MRT",
        "iso_n3": "478",
        "un_a3": "478",
        "wb_a2": "MR",
        "wb_a3": "MRT",
        "woe_id": -99,
        "adm0_a3_is": "MRT",
        "adm0_a3_us": "MRT",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Western Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 10,
        "long_len": 10,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Malawi": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 6,
        "sovereignt": "Malawi",
        "sov_a3": "MWI",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Malawi",
        "adm0_a3": "MWI",
        "geou_dif": 0,
        "geounit": "Malawi",
        "gu_a3": "MWI",
        "su_dif": 0,
        "subunit": "Malawi",
        "su_a3": "MWI",
        "brk_diff": 0,
        "name": "Malawi",
        "name_long": "Malawi",
        "brk_a3": "MWI",
        "brk_name": "Malawi",
        "brk_group": "",
        "abbrev": "Mal.",
        "postal": "MW",
        "formal_en": "Republic of Malawi",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Malawi",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 3,
        "mapcolor9": 4,
        "mapcolor13": 5,
        "pop_est": 14268711,
        "gdp_md_est": 11810,
        "pop_year": -99,
        "lastcensus": 2008,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "MW",
        "iso_a3": "MWI",
        "iso_n3": "454",
        "un_a3": "454",
        "wb_a2": "MW",
        "wb_a3": "MWI",
        "woe_id": -99,
        "adm0_a3_is": "MWI",
        "adm0_a3_us": "MWI",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Eastern Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 6,
        "long_len": 6,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Malaysia": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Malaysia",
        "sov_a3": "MYS",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Malaysia",
        "adm0_a3": "MYS",
        "geou_dif": 0,
        "geounit": "Malaysia",
        "gu_a3": "MYS",
        "su_dif": 0,
        "subunit": "Malaysia",
        "su_a3": "MYS",
        "brk_diff": 0,
        "name": "Malaysia",
        "name_long": "Malaysia",
        "brk_a3": "MYS",
        "brk_name": "Malaysia",
        "brk_group": "",
        "abbrev": "Malay.",
        "postal": "MY",
        "formal_en": "Malaysia",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Malaysia",
        "name_alt": "",
        "mapcolor7": 2,
        "mapcolor8": 4,
        "mapcolor9": 3,
        "mapcolor13": 6,
        "pop_est": 25715819,
        "gdp_md_est": 384300,
        "pop_year": -99,
        "lastcensus": 2010,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "MY",
        "iso_a3": "MYS",
        "iso_n3": "458",
        "un_a3": "458",
        "wb_a2": "MY",
        "wb_a3": "MYS",
        "woe_id": -99,
        "adm0_a3_is": "MYS",
        "adm0_a3_us": "MYS",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "South-Eastern Asia",
        "region_wb": "East Asia & Pacific",
        "name_len": 8,
        "long_len": 8,
        "abbrev_len": 6,
        "tiny": -99,
        "homepart": 1
    },
    "Namibia": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Namibia",
        "sov_a3": "NAM",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Namibia",
        "adm0_a3": "NAM",
        "geou_dif": 0,
        "geounit": "Namibia",
        "gu_a3": "NAM",
        "su_dif": 0,
        "subunit": "Namibia",
        "su_a3": "NAM",
        "brk_diff": 0,
        "name": "Namibia",
        "name_long": "Namibia",
        "brk_a3": "NAM",
        "brk_name": "Namibia",
        "brk_group": "",
        "abbrev": "Nam.",
        "postal": "NA",
        "formal_en": "Republic of Namibia",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Namibia",
        "name_alt": "",
        "mapcolor7": 4,
        "mapcolor8": 1,
        "mapcolor9": 1,
        "mapcolor13": 7,
        "pop_est": 2108665,
        "gdp_md_est": 13250,
        "pop_year": -99,
        "lastcensus": 2001,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "NA",
        "iso_a3": "NAM",
        "iso_n3": "516",
        "un_a3": "516",
        "wb_a2": "NA",
        "wb_a3": "NAM",
        "woe_id": -99,
        "adm0_a3_is": "NAM",
        "adm0_a3_us": "NAM",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Southern Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "New Caledonia": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "France",
        "sov_a3": "FR1",
        "adm0_dif": 1,
        "level": 2,
        "type": "Dependency",
        "admin": "New Caledonia",
        "adm0_a3": "NCL",
        "geou_dif": 0,
        "geounit": "New Caledonia",
        "gu_a3": "NCL",
        "su_dif": 0,
        "subunit": "New Caledonia",
        "su_a3": "NCL",
        "brk_diff": 0,
        "name": "New Caledonia",
        "name_long": "New Caledonia",
        "brk_a3": "NCL",
        "brk_name": "New Caledonia",
        "brk_group": "",
        "abbrev": "New C.",
        "postal": "NC",
        "formal_en": "New Caledonia",
        "formal_fr": "Nouvelle-Calédonie",
        "note_adm0": "Fr.",
        "note_brk": "",
        "name_sort": "New Caledonia",
        "name_alt": "",
        "mapcolor7": 7,
        "mapcolor8": 5,
        "mapcolor9": 9,
        "mapcolor13": 11,
        "pop_est": 227436,
        "gdp_md_est": 3158,
        "pop_year": -99,
        "lastcensus": 2009,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "2. High income: nonOECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "NC",
        "iso_a3": "NCL",
        "iso_n3": "540",
        "un_a3": "540",
        "wb_a2": "NC",
        "wb_a3": "NCL",
        "woe_id": -99,
        "adm0_a3_is": "NCL",
        "adm0_a3_us": "NCL",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Oceania",
        "region_un": "Oceania",
        "subregion": "Melanesia",
        "region_wb": "East Asia & Pacific",
        "name_len": 13,
        "long_len": 13,
        "abbrev_len": 6,
        "tiny": -99,
        "homepart": -99
    },
    "Niger": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Niger",
        "sov_a3": "NER",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Niger",
        "adm0_a3": "NER",
        "geou_dif": 0,
        "geounit": "Niger",
        "gu_a3": "NER",
        "su_dif": 0,
        "subunit": "Niger",
        "su_a3": "NER",
        "brk_diff": 0,
        "name": "Niger",
        "name_long": "Niger",
        "brk_a3": "NER",
        "brk_name": "Niger",
        "brk_group": "",
        "abbrev": "Niger",
        "postal": "NE",
        "formal_en": "Republic of Niger",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Niger",
        "name_alt": "",
        "mapcolor7": 4,
        "mapcolor8": 5,
        "mapcolor9": 3,
        "mapcolor13": 13,
        "pop_est": 15306252,
        "gdp_md_est": 10040,
        "pop_year": -99,
        "lastcensus": 2001,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "NE",
        "iso_a3": "NER",
        "iso_n3": "562",
        "un_a3": "562",
        "wb_a2": "NE",
        "wb_a3": "NER",
        "woe_id": -99,
        "adm0_a3_is": "NER",
        "adm0_a3_us": "NER",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Western Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 5,
        "long_len": 5,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "Nigeria": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "Nigeria",
        "sov_a3": "NGA",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Nigeria",
        "adm0_a3": "NGA",
        "geou_dif": 0,
        "geounit": "Nigeria",
        "gu_a3": "NGA",
        "su_dif": 0,
        "subunit": "Nigeria",
        "su_a3": "NGA",
        "brk_diff": 0,
        "name": "Nigeria",
        "name_long": "Nigeria",
        "brk_a3": "NGA",
        "brk_name": "Nigeria",
        "brk_group": "",
        "abbrev": "Nigeria",
        "postal": "NG",
        "formal_en": "Federal Republic of Nigeria",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Nigeria",
        "name_alt": "",
        "mapcolor7": 3,
        "mapcolor8": 2,
        "mapcolor9": 5,
        "mapcolor13": 2,
        "pop_est": 149229090,
        "gdp_md_est": 335400,
        "pop_year": -99,
        "lastcensus": 2006,
        "gdp_year": -99,
        "economy": "5. Emerging region: G20",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "NG",
        "iso_a3": "NGA",
        "iso_n3": "566",
        "un_a3": "566",
        "wb_a2": "NG",
        "wb_a3": "NGA",
        "woe_id": -99,
        "adm0_a3_is": "NGA",
        "adm0_a3_us": "NGA",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Western Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 7,
        "tiny": -99,
        "homepart": 1
    },
    "Nicaragua": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 5,
        "sovereignt": "Nicaragua",
        "sov_a3": "NIC",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Nicaragua",
        "adm0_a3": "NIC",
        "geou_dif": 0,
        "geounit": "Nicaragua",
        "gu_a3": "NIC",
        "su_dif": 0,
        "subunit": "Nicaragua",
        "su_a3": "NIC",
        "brk_diff": 0,
        "name": "Nicaragua",
        "name_long": "Nicaragua",
        "brk_a3": "NIC",
        "brk_name": "Nicaragua",
        "brk_group": "",
        "abbrev": "Nic.",
        "postal": "NI",
        "formal_en": "Republic of Nicaragua",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Nicaragua",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 4,
        "mapcolor9": 1,
        "mapcolor13": 9,
        "pop_est": 5891199,
        "gdp_md_est": 16790,
        "pop_year": -99,
        "lastcensus": 2005,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "NI",
        "iso_a3": "NIC",
        "iso_n3": "558",
        "un_a3": "558",
        "wb_a2": "NI",
        "wb_a3": "NIC",
        "woe_id": -99,
        "adm0_a3_is": "NIC",
        "adm0_a3_us": "NIC",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "North America",
        "region_un": "Americas",
        "subregion": "Central America",
        "region_wb": "Latin America & Caribbean",
        "name_len": 9,
        "long_len": 9,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Netherlands": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 5,
        "sovereignt": "Netherlands",
        "sov_a3": "NL1",
        "adm0_dif": 1,
        "level": 2,
        "type": "Country",
        "admin": "Netherlands",
        "adm0_a3": "NLD",
        "geou_dif": 0,
        "geounit": "Netherlands",
        "gu_a3": "NLD",
        "su_dif": 0,
        "subunit": "Netherlands",
        "su_a3": "NLD",
        "brk_diff": 0,
        "name": "Netherlands",
        "name_long": "Netherlands",
        "brk_a3": "NLD",
        "brk_name": "Netherlands",
        "brk_group": "",
        "abbrev": "Neth.",
        "postal": "NL",
        "formal_en": "Kingdom of the Netherlands",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Netherlands",
        "name_alt": "",
        "mapcolor7": 4,
        "mapcolor8": 2,
        "mapcolor9": 2,
        "mapcolor13": 9,
        "pop_est": 16715999,
        "gdp_md_est": 672000,
        "pop_year": -99,
        "lastcensus": 2011,
        "gdp_year": -99,
        "economy": "2. Developed region: nonG7",
        "income_grp": "1. High income: OECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "NL",
        "iso_a3": "NLD",
        "iso_n3": "528",
        "un_a3": "528",
        "wb_a2": "NL",
        "wb_a3": "NLD",
        "woe_id": -99,
        "adm0_a3_is": "NLD",
        "adm0_a3_us": "NLD",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Western Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 11,
        "long_len": 11,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "Norway": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Norway",
        "sov_a3": "NOR",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Norway",
        "adm0_a3": "NOR",
        "geou_dif": 0,
        "geounit": "Norway",
        "gu_a3": "NOR",
        "su_dif": 0,
        "subunit": "Norway",
        "su_a3": "NOR",
        "brk_diff": 0,
        "name": "Norway",
        "name_long": "Norway",
        "brk_a3": "NOR",
        "brk_name": "Norway",
        "brk_group": "",
        "abbrev": "Nor.",
        "postal": "N",
        "formal_en": "Kingdom of Norway",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Norway",
        "name_alt": "",
        "mapcolor7": 5,
        "mapcolor8": 3,
        "mapcolor9": 8,
        "mapcolor13": 12,
        "pop_est": 4676305,
        "gdp_md_est": 276400,
        "pop_year": -99,
        "lastcensus": 2001,
        "gdp_year": -99,
        "economy": "2. Developed region: nonG7",
        "income_grp": "1. High income: OECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "NO",
        "iso_a3": "NOR",
        "iso_n3": "578",
        "un_a3": "578",
        "wb_a2": "NO",
        "wb_a3": "NOR",
        "woe_id": -99,
        "adm0_a3_is": "NOR",
        "adm0_a3_us": "NOR",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Northern Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 6,
        "long_len": 6,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Nepal": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Nepal",
        "sov_a3": "NPL",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Nepal",
        "adm0_a3": "NPL",
        "geou_dif": 0,
        "geounit": "Nepal",
        "gu_a3": "NPL",
        "su_dif": 0,
        "subunit": "Nepal",
        "su_a3": "NPL",
        "brk_diff": 0,
        "name": "Nepal",
        "name_long": "Nepal",
        "brk_a3": "NPL",
        "brk_name": "Nepal",
        "brk_group": "",
        "abbrev": "Nepal",
        "postal": "NP",
        "formal_en": "Nepal",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Nepal",
        "name_alt": "",
        "mapcolor7": 2,
        "mapcolor8": 2,
        "mapcolor9": 3,
        "mapcolor13": 12,
        "pop_est": 28563377,
        "gdp_md_est": 31080,
        "pop_year": -99,
        "lastcensus": 2001,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "NP",
        "iso_a3": "NPL",
        "iso_n3": "524",
        "un_a3": "524",
        "wb_a2": "NP",
        "wb_a3": "NPL",
        "woe_id": -99,
        "adm0_a3_is": "NPL",
        "adm0_a3_us": "NPL",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Southern Asia",
        "region_wb": "South Asia",
        "name_len": 5,
        "long_len": 5,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "New Zealand": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "New Zealand",
        "sov_a3": "NZ1",
        "adm0_dif": 1,
        "level": 2,
        "type": "Country",
        "admin": "New Zealand",
        "adm0_a3": "NZL",
        "geou_dif": 0,
        "geounit": "New Zealand",
        "gu_a3": "NZL",
        "su_dif": 0,
        "subunit": "New Zealand",
        "su_a3": "NZL",
        "brk_diff": 0,
        "name": "New Zealand",
        "name_long": "New Zealand",
        "brk_a3": "NZL",
        "brk_name": "New Zealand",
        "brk_group": "",
        "abbrev": "N.Z.",
        "postal": "NZ",
        "formal_en": "New Zealand",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "New Zealand",
        "name_alt": "",
        "mapcolor7": 3,
        "mapcolor8": 3,
        "mapcolor9": 4,
        "mapcolor13": 4,
        "pop_est": 4213418,
        "gdp_md_est": 116700,
        "pop_year": -99,
        "lastcensus": 2006,
        "gdp_year": -99,
        "economy": "2. Developed region: nonG7",
        "income_grp": "1. High income: OECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "NZ",
        "iso_a3": "NZL",
        "iso_n3": "554",
        "un_a3": "554",
        "wb_a2": "NZ",
        "wb_a3": "NZL",
        "woe_id": -99,
        "adm0_a3_is": "NZL",
        "adm0_a3_us": "NZL",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Oceania",
        "region_un": "Oceania",
        "subregion": "Australia and New Zealand",
        "region_wb": "East Asia & Pacific",
        "name_len": 11,
        "long_len": 11,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Oman": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 4,
        "sovereignt": "Oman",
        "sov_a3": "OMN",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Oman",
        "adm0_a3": "OMN",
        "geou_dif": 0,
        "geounit": "Oman",
        "gu_a3": "OMN",
        "su_dif": 0,
        "subunit": "Oman",
        "su_a3": "OMN",
        "brk_diff": 0,
        "name": "Oman",
        "name_long": "Oman",
        "brk_a3": "OMN",
        "brk_name": "Oman",
        "brk_group": "",
        "abbrev": "Oman",
        "postal": "OM",
        "formal_en": "Sultanate of Oman",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Oman",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 4,
        "mapcolor9": 1,
        "mapcolor13": 6,
        "pop_est": 3418085,
        "gdp_md_est": 66980,
        "pop_year": -99,
        "lastcensus": 2010,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "2. High income: nonOECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "OM",
        "iso_a3": "OMN",
        "iso_n3": "512",
        "un_a3": "512",
        "wb_a2": "OM",
        "wb_a3": "OMN",
        "woe_id": -99,
        "adm0_a3_is": "OMN",
        "adm0_a3_us": "OMN",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Western Asia",
        "region_wb": "Middle East & North Africa",
        "name_len": 4,
        "long_len": 4,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Pakistan": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "Pakistan",
        "sov_a3": "PAK",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Pakistan",
        "adm0_a3": "PAK",
        "geou_dif": 0,
        "geounit": "Pakistan",
        "gu_a3": "PAK",
        "su_dif": 0,
        "subunit": "Pakistan",
        "su_a3": "PAK",
        "brk_diff": 0,
        "name": "Pakistan",
        "name_long": "Pakistan",
        "brk_a3": "PAK",
        "brk_name": "Pakistan",
        "brk_group": "",
        "abbrev": "Pak.",
        "postal": "PK",
        "formal_en": "Islamic Republic of Pakistan",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Pakistan",
        "name_alt": "",
        "mapcolor7": 2,
        "mapcolor8": 2,
        "mapcolor9": 3,
        "mapcolor13": 11,
        "pop_est": 176242949,
        "gdp_md_est": 427300,
        "pop_year": -99,
        "lastcensus": 1998,
        "gdp_year": -99,
        "economy": "5. Emerging region: G20",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "PK",
        "iso_a3": "PAK",
        "iso_n3": "586",
        "un_a3": "586",
        "wb_a2": "PK",
        "wb_a3": "PAK",
        "woe_id": -99,
        "adm0_a3_is": "PAK",
        "adm0_a3_us": "PAK",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Southern Asia",
        "region_wb": "South Asia",
        "name_len": 8,
        "long_len": 8,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Panama": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 4,
        "sovereignt": "Panama",
        "sov_a3": "PAN",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Panama",
        "adm0_a3": "PAN",
        "geou_dif": 0,
        "geounit": "Panama",
        "gu_a3": "PAN",
        "su_dif": 0,
        "subunit": "Panama",
        "su_a3": "PAN",
        "brk_diff": 0,
        "name": "Panama",
        "name_long": "Panama",
        "brk_a3": "PAN",
        "brk_name": "Panama",
        "brk_group": "",
        "abbrev": "Pan.",
        "postal": "PA",
        "formal_en": "Republic of Panama",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Panama",
        "name_alt": "",
        "mapcolor7": 4,
        "mapcolor8": 4,
        "mapcolor9": 6,
        "mapcolor13": 3,
        "pop_est": 3360474,
        "gdp_md_est": 38830,
        "pop_year": -99,
        "lastcensus": 2010,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "PA",
        "iso_a3": "PAN",
        "iso_n3": "591",
        "un_a3": "591",
        "wb_a2": "PA",
        "wb_a3": "PAN",
        "woe_id": -99,
        "adm0_a3_is": "PAN",
        "adm0_a3_us": "PAN",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "North America",
        "region_un": "Americas",
        "subregion": "Central America",
        "region_wb": "Latin America & Caribbean",
        "name_len": 6,
        "long_len": 6,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Peru": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "Peru",
        "sov_a3": "PER",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Peru",
        "adm0_a3": "PER",
        "geou_dif": 0,
        "geounit": "Peru",
        "gu_a3": "PER",
        "su_dif": 0,
        "subunit": "Peru",
        "su_a3": "PER",
        "brk_diff": 0,
        "name": "Peru",
        "name_long": "Peru",
        "brk_a3": "PER",
        "brk_name": "Peru",
        "brk_group": "",
        "abbrev": "Peru",
        "postal": "PE",
        "formal_en": "Republic of Peru",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Peru",
        "name_alt": "",
        "mapcolor7": 4,
        "mapcolor8": 4,
        "mapcolor9": 4,
        "mapcolor13": 11,
        "pop_est": 29546963,
        "gdp_md_est": 247300,
        "pop_year": -99,
        "lastcensus": 2007,
        "gdp_year": -99,
        "economy": "5. Emerging region: G20",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "PE",
        "iso_a3": "PER",
        "iso_n3": "604",
        "un_a3": "604",
        "wb_a2": "PE",
        "wb_a3": "PER",
        "woe_id": -99,
        "adm0_a3_is": "PER",
        "adm0_a3_us": "PER",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "South America",
        "region_un": "Americas",
        "subregion": "South America",
        "region_wb": "Latin America & Caribbean",
        "name_len": 4,
        "long_len": 4,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Philippines": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "Philippines",
        "sov_a3": "PHL",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Philippines",
        "adm0_a3": "PHL",
        "geou_dif": 0,
        "geounit": "Philippines",
        "gu_a3": "PHL",
        "su_dif": 0,
        "subunit": "Philippines",
        "su_a3": "PHL",
        "brk_diff": 0,
        "name": "Philippines",
        "name_long": "Philippines",
        "brk_a3": "PHL",
        "brk_name": "Philippines",
        "brk_group": "",
        "abbrev": "Phil.",
        "postal": "PH",
        "formal_en": "Republic of the Philippines",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Philippines",
        "name_alt": "",
        "mapcolor7": 3,
        "mapcolor8": 2,
        "mapcolor9": 2,
        "mapcolor13": 8,
        "pop_est": 97976603,
        "gdp_md_est": 317500,
        "pop_year": -99,
        "lastcensus": 2010,
        "gdp_year": -99,
        "economy": "5. Emerging region: G20",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "PH",
        "iso_a3": "PHL",
        "iso_n3": "608",
        "un_a3": "608",
        "wb_a2": "PH",
        "wb_a3": "PHL",
        "woe_id": -99,
        "adm0_a3_is": "PHL",
        "adm0_a3_us": "PHL",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "South-Eastern Asia",
        "region_wb": "East Asia & Pacific",
        "name_len": 11,
        "long_len": 11,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "Papua New Guinea": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "Papua New Guinea",
        "sov_a3": "PNG",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Papua New Guinea",
        "adm0_a3": "PNG",
        "geou_dif": 0,
        "geounit": "Papua New Guinea",
        "gu_a3": "PNG",
        "su_dif": 1,
        "subunit": "Papua New Guinea",
        "su_a3": "PN1",
        "brk_diff": 0,
        "name": "Papua New Guinea",
        "name_long": "Papua New Guinea",
        "brk_a3": "PN1",
        "brk_name": "Papua New Guinea",
        "brk_group": "",
        "abbrev": "P.N.G.",
        "postal": "PG",
        "formal_en": "Independent State of Papua New Guinea",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Papua New Guinea",
        "name_alt": "",
        "mapcolor7": 4,
        "mapcolor8": 2,
        "mapcolor9": 3,
        "mapcolor13": 1,
        "pop_est": 6057263,
        "gdp_md_est": 13210,
        "pop_year": -99,
        "lastcensus": 2000,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "PG",
        "iso_a3": "PNG",
        "iso_n3": "598",
        "un_a3": "598",
        "wb_a2": "PG",
        "wb_a3": "PNG",
        "woe_id": -99,
        "adm0_a3_is": "PNG",
        "adm0_a3_us": "PNG",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Oceania",
        "region_un": "Oceania",
        "subregion": "Melanesia",
        "region_wb": "East Asia & Pacific",
        "name_len": 16,
        "long_len": 16,
        "abbrev_len": 6,
        "tiny": -99,
        "homepart": 1
    },
    "Poland": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Poland",
        "sov_a3": "POL",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Poland",
        "adm0_a3": "POL",
        "geou_dif": 0,
        "geounit": "Poland",
        "gu_a3": "POL",
        "su_dif": 0,
        "subunit": "Poland",
        "su_a3": "POL",
        "brk_diff": 0,
        "name": "Poland",
        "name_long": "Poland",
        "brk_a3": "POL",
        "brk_name": "Poland",
        "brk_group": "",
        "abbrev": "Pol.",
        "postal": "PL",
        "formal_en": "Republic of Poland",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Poland",
        "name_alt": "",
        "mapcolor7": 3,
        "mapcolor8": 7,
        "mapcolor9": 1,
        "mapcolor13": 2,
        "pop_est": 38482919,
        "gdp_md_est": 667900,
        "pop_year": -99,
        "lastcensus": 2011,
        "gdp_year": -99,
        "economy": "2. Developed region: nonG7",
        "income_grp": "1. High income: OECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "PL",
        "iso_a3": "POL",
        "iso_n3": "616",
        "un_a3": "616",
        "wb_a2": "PL",
        "wb_a3": "POL",
        "woe_id": -99,
        "adm0_a3_is": "POL",
        "adm0_a3_us": "POL",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Eastern Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 6,
        "long_len": 6,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Puerto Rico": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 5,
        "sovereignt": "United States of America",
        "sov_a3": "US1",
        "adm0_dif": 1,
        "level": 2,
        "type": "Dependency",
        "admin": "Puerto Rico",
        "adm0_a3": "PRI",
        "geou_dif": 0,
        "geounit": "Puerto Rico",
        "gu_a3": "PRI",
        "su_dif": 0,
        "subunit": "Puerto Rico",
        "su_a3": "PRI",
        "brk_diff": 0,
        "name": "Puerto Rico",
        "name_long": "Puerto Rico",
        "brk_a3": "PRI",
        "brk_name": "Puerto Rico",
        "brk_group": "",
        "abbrev": "P.R.",
        "postal": "PR",
        "formal_en": "Commonwealth of Puerto Rico",
        "formal_fr": "",
        "note_adm0": "Commonwealth of U.S.A.",
        "note_brk": "",
        "name_sort": "Puerto Rico",
        "name_alt": "",
        "mapcolor7": 4,
        "mapcolor8": 5,
        "mapcolor9": 1,
        "mapcolor13": 1,
        "pop_est": 3971020,
        "gdp_md_est": 70230,
        "pop_year": -99,
        "lastcensus": 2010,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "2. High income: nonOECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "PR",
        "iso_a3": "PRI",
        "iso_n3": "630",
        "un_a3": "630",
        "wb_a2": "PR",
        "wb_a3": "PRI",
        "woe_id": -99,
        "adm0_a3_is": "PRI",
        "adm0_a3_us": "PRI",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "North America",
        "region_un": "Americas",
        "subregion": "Caribbean",
        "region_wb": "Latin America & Caribbean",
        "name_len": 11,
        "long_len": 11,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": -99
    },
    "Dem. Rep. Korea": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "North Korea",
        "sov_a3": "PRK",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "North Korea",
        "adm0_a3": "PRK",
        "geou_dif": 0,
        "geounit": "North Korea",
        "gu_a3": "PRK",
        "su_dif": 0,
        "subunit": "North Korea",
        "su_a3": "PRK",
        "brk_diff": 0,
        "name": "Dem. Rep. Korea",
        "name_long": "Dem. Rep. Korea",
        "brk_a3": "PRK",
        "brk_name": "Dem. Rep. Korea",
        "brk_group": "",
        "abbrev": "N.K.",
        "postal": "KP",
        "formal_en": "Democratic People's Republic of Korea",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Korea, Dem. Rep.",
        "name_alt": "",
        "mapcolor7": 3,
        "mapcolor8": 5,
        "mapcolor9": 3,
        "mapcolor13": 9,
        "pop_est": 22665345,
        "gdp_md_est": 40000,
        "pop_year": -99,
        "lastcensus": 2009,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "KP",
        "iso_a3": "PRK",
        "iso_n3": "408",
        "un_a3": "408",
        "wb_a2": "KP",
        "wb_a3": "PRK",
        "woe_id": -99,
        "adm0_a3_is": "PRK",
        "adm0_a3_us": "PRK",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Eastern Asia",
        "region_wb": "East Asia & Pacific",
        "name_len": 15,
        "long_len": 15,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Portugal": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "Portugal",
        "sov_a3": "PRT",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Portugal",
        "adm0_a3": "PRT",
        "geou_dif": 0,
        "geounit": "Portugal",
        "gu_a3": "PRT",
        "su_dif": 1,
        "subunit": "Portugal",
        "su_a3": "PR1",
        "brk_diff": 0,
        "name": "Portugal",
        "name_long": "Portugal",
        "brk_a3": "PR1",
        "brk_name": "Portugal",
        "brk_group": "",
        "abbrev": "Port.",
        "postal": "P",
        "formal_en": "Portuguese Republic",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Portugal",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 7,
        "mapcolor9": 1,
        "mapcolor13": 4,
        "pop_est": 10707924,
        "gdp_md_est": 208627,
        "pop_year": -99,
        "lastcensus": 2011,
        "gdp_year": 0,
        "economy": "2. Developed region: nonG7",
        "income_grp": "1. High income: OECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "PT",
        "iso_a3": "PRT",
        "iso_n3": "620",
        "un_a3": "620",
        "wb_a2": "PT",
        "wb_a3": "PRT",
        "woe_id": -99,
        "adm0_a3_is": "PRT",
        "adm0_a3_us": "PRT",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Southern Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 8,
        "long_len": 8,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "Paraguay": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 4,
        "sovereignt": "Paraguay",
        "sov_a3": "PRY",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Paraguay",
        "adm0_a3": "PRY",
        "geou_dif": 0,
        "geounit": "Paraguay",
        "gu_a3": "PRY",
        "su_dif": 0,
        "subunit": "Paraguay",
        "su_a3": "PRY",
        "brk_diff": 0,
        "name": "Paraguay",
        "name_long": "Paraguay",
        "brk_a3": "PRY",
        "brk_name": "Paraguay",
        "brk_group": "",
        "abbrev": "Para.",
        "postal": "PY",
        "formal_en": "Republic of Paraguay",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Paraguay",
        "name_alt": "",
        "mapcolor7": 6,
        "mapcolor8": 3,
        "mapcolor9": 6,
        "mapcolor13": 2,
        "pop_est": 6995655,
        "gdp_md_est": 28890,
        "pop_year": -99,
        "lastcensus": 2002,
        "gdp_year": -99,
        "economy": "5. Emerging region: G20",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "PY",
        "iso_a3": "PRY",
        "iso_n3": "600",
        "un_a3": "600",
        "wb_a2": "PY",
        "wb_a3": "PRY",
        "woe_id": -99,
        "adm0_a3_is": "PRY",
        "adm0_a3_us": "PRY",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "South America",
        "region_un": "Americas",
        "subregion": "South America",
        "region_wb": "Latin America & Caribbean",
        "name_len": 8,
        "long_len": 8,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "Palestine": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 5,
        "sovereignt": "Israel",
        "sov_a3": "ISR",
        "adm0_dif": 1,
        "level": 2,
        "type": "Disputed",
        "admin": "Palestine",
        "adm0_a3": "PSX",
        "geou_dif": 0,
        "geounit": "Palestine",
        "gu_a3": "PSX",
        "su_dif": 0,
        "subunit": "Palestine",
        "su_a3": "PSX",
        "brk_diff": 0,
        "name": "Palestine",
        "name_long": "Palestine",
        "brk_a3": "PSX",
        "brk_name": "Palestine",
        "brk_group": "",
        "abbrev": "Pal.",
        "postal": "PAL",
        "formal_en": "West Bank and Gaza",
        "formal_fr": "",
        "note_adm0": "Partial self-admin.",
        "note_brk": "Partial self-admin.",
        "name_sort": "Palestine (West Bank and Gaza)",
        "name_alt": "",
        "mapcolor7": 3,
        "mapcolor8": 2,
        "mapcolor9": 5,
        "mapcolor13": 8,
        "pop_est": 4119083,
        "gdp_md_est": 11950.77,
        "pop_year": -99,
        "lastcensus": 2007,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "PS",
        "iso_a3": "PSE",
        "iso_n3": "275",
        "un_a3": "275",
        "wb_a2": "GZ",
        "wb_a3": "WBG",
        "woe_id": -99,
        "adm0_a3_is": "PSE",
        "adm0_a3_us": "PSX",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Western Asia",
        "region_wb": "Middle East & North Africa",
        "name_len": 9,
        "long_len": 9,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": -99
    },
    "Qatar": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 5,
        "sovereignt": "Qatar",
        "sov_a3": "QAT",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Qatar",
        "adm0_a3": "QAT",
        "geou_dif": 0,
        "geounit": "Qatar",
        "gu_a3": "QAT",
        "su_dif": 0,
        "subunit": "Qatar",
        "su_a3": "QAT",
        "brk_diff": 0,
        "name": "Qatar",
        "name_long": "Qatar",
        "brk_a3": "QAT",
        "brk_name": "Qatar",
        "brk_group": "",
        "abbrev": "Qatar",
        "postal": "QA",
        "formal_en": "State of Qatar",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Qatar",
        "name_alt": "",
        "mapcolor7": 3,
        "mapcolor8": 6,
        "mapcolor9": 2,
        "mapcolor13": 4,
        "pop_est": 833285,
        "gdp_md_est": 91330,
        "pop_year": -99,
        "lastcensus": 2010,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "2. High income: nonOECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "QA",
        "iso_a3": "QAT",
        "iso_n3": "634",
        "un_a3": "634",
        "wb_a2": "QA",
        "wb_a3": "QAT",
        "woe_id": -99,
        "adm0_a3_is": "QAT",
        "adm0_a3_us": "QAT",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Western Asia",
        "region_wb": "Middle East & North Africa",
        "name_len": 5,
        "long_len": 5,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "Romania": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Romania",
        "sov_a3": "ROU",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Romania",
        "adm0_a3": "ROU",
        "geou_dif": 0,
        "geounit": "Romania",
        "gu_a3": "ROU",
        "su_dif": 0,
        "subunit": "Romania",
        "su_a3": "ROU",
        "brk_diff": 0,
        "name": "Romania",
        "name_long": "Romania",
        "brk_a3": "ROU",
        "brk_name": "Romania",
        "brk_group": "",
        "abbrev": "Rom.",
        "postal": "RO",
        "formal_en": "Romania",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Romania",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 4,
        "mapcolor9": 3,
        "mapcolor13": 13,
        "pop_est": 22215421,
        "gdp_md_est": 271400,
        "pop_year": -99,
        "lastcensus": 2011,
        "gdp_year": -99,
        "economy": "2. Developed region: nonG7",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "RO",
        "iso_a3": "ROU",
        "iso_n3": "642",
        "un_a3": "642",
        "wb_a2": "RO",
        "wb_a3": "ROM",
        "woe_id": -99,
        "adm0_a3_is": "ROU",
        "adm0_a3_us": "ROU",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Eastern Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Russia": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "Russia",
        "sov_a3": "RUS",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Russia",
        "adm0_a3": "RUS",
        "geou_dif": 0,
        "geounit": "Russia",
        "gu_a3": "RUS",
        "su_dif": 0,
        "subunit": "Russia",
        "su_a3": "RUS",
        "brk_diff": 0,
        "name": "Russia",
        "name_long": "Russian Federation",
        "brk_a3": "RUS",
        "brk_name": "Russia",
        "brk_group": "",
        "abbrev": "Rus.",
        "postal": "RUS",
        "formal_en": "Russian Federation",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Russian Federation",
        "name_alt": "",
        "mapcolor7": 2,
        "mapcolor8": 5,
        "mapcolor9": 7,
        "mapcolor13": 7,
        "pop_est": 140041247,
        "gdp_md_est": 2266000,
        "pop_year": -99,
        "lastcensus": 2010,
        "gdp_year": -99,
        "economy": "3. Emerging region: BRIC",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "RU",
        "iso_a3": "RUS",
        "iso_n3": "643",
        "un_a3": "643",
        "wb_a2": "RU",
        "wb_a3": "RUS",
        "woe_id": -99,
        "adm0_a3_is": "RUS",
        "adm0_a3_us": "RUS",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Eastern Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 6,
        "long_len": 18,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Rwanda": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Rwanda",
        "sov_a3": "RWA",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Rwanda",
        "adm0_a3": "RWA",
        "geou_dif": 0,
        "geounit": "Rwanda",
        "gu_a3": "RWA",
        "su_dif": 0,
        "subunit": "Rwanda",
        "su_a3": "RWA",
        "brk_diff": 0,
        "name": "Rwanda",
        "name_long": "Rwanda",
        "brk_a3": "RWA",
        "brk_name": "Rwanda",
        "brk_group": "",
        "abbrev": "Rwa.",
        "postal": "RW",
        "formal_en": "Republic of Rwanda",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Rwanda",
        "name_alt": "",
        "mapcolor7": 5,
        "mapcolor8": 2,
        "mapcolor9": 3,
        "mapcolor13": 10,
        "pop_est": 10473282,
        "gdp_md_est": 9706,
        "pop_year": -99,
        "lastcensus": 2002,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "RW",
        "iso_a3": "RWA",
        "iso_n3": "646",
        "un_a3": "646",
        "wb_a2": "RW",
        "wb_a3": "RWA",
        "woe_id": -99,
        "adm0_a3_is": "RWA",
        "adm0_a3_us": "RWA",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Eastern Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 6,
        "long_len": 6,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "W. Sahara": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 7,
        "sovereignt": "Western Sahara",
        "sov_a3": "SAH",
        "adm0_dif": 0,
        "level": 2,
        "type": "Indeterminate",
        "admin": "Western Sahara",
        "adm0_a3": "SAH",
        "geou_dif": 0,
        "geounit": "Western Sahara",
        "gu_a3": "SAH",
        "su_dif": 0,
        "subunit": "Western Sahara",
        "su_a3": "SAH",
        "brk_diff": 1,
        "name": "W. Sahara",
        "name_long": "Western Sahara",
        "brk_a3": "B28",
        "brk_name": "W. Sahara",
        "brk_group": "",
        "abbrev": "W. Sah.",
        "postal": "WS",
        "formal_en": "Sahrawi Arab Democratic Republic",
        "formal_fr": "",
        "note_adm0": "Self admin.",
        "note_brk": "Self admin.; Claimed by Morocco",
        "name_sort": "Western Sahara",
        "name_alt": "",
        "mapcolor7": 4,
        "mapcolor8": 7,
        "mapcolor9": 4,
        "mapcolor13": 4,
        "pop_est": -99,
        "gdp_md_est": -99,
        "pop_year": -99,
        "lastcensus": -99,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "EH",
        "iso_a3": "ESH",
        "iso_n3": "732",
        "un_a3": "732",
        "wb_a2": "-99",
        "wb_a3": "-99",
        "woe_id": -99,
        "adm0_a3_is": "MAR",
        "adm0_a3_us": "SAH",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Northern Africa",
        "region_wb": "Middle East & North Africa",
        "name_len": 9,
        "long_len": 14,
        "abbrev_len": 7,
        "tiny": -99,
        "homepart": 1
    },
    "Saudi Arabia": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "Saudi Arabia",
        "sov_a3": "SAU",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Saudi Arabia",
        "adm0_a3": "SAU",
        "geou_dif": 0,
        "geounit": "Saudi Arabia",
        "gu_a3": "SAU",
        "su_dif": 0,
        "subunit": "Saudi Arabia",
        "su_a3": "SAU",
        "brk_diff": 0,
        "name": "Saudi Arabia",
        "name_long": "Saudi Arabia",
        "brk_a3": "SAU",
        "brk_name": "Saudi Arabia",
        "brk_group": "",
        "abbrev": "Saud.",
        "postal": "SA",
        "formal_en": "Kingdom of Saudi Arabia",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Saudi Arabia",
        "name_alt": "",
        "mapcolor7": 6,
        "mapcolor8": 1,
        "mapcolor9": 6,
        "mapcolor13": 7,
        "pop_est": 28686633,
        "gdp_md_est": 576500,
        "pop_year": -99,
        "lastcensus": 2010,
        "gdp_year": -99,
        "economy": "2. Developed region: nonG7",
        "income_grp": "2. High income: nonOECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "SA",
        "iso_a3": "SAU",
        "iso_n3": "682",
        "un_a3": "682",
        "wb_a2": "SA",
        "wb_a3": "SAU",
        "woe_id": -99,
        "adm0_a3_is": "SAU",
        "adm0_a3_us": "SAU",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Western Asia",
        "region_wb": "Middle East & North Africa",
        "name_len": 12,
        "long_len": 12,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "Sudan": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Sudan",
        "sov_a3": "SDN",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Sudan",
        "adm0_a3": "SDN",
        "geou_dif": 0,
        "geounit": "Sudan",
        "gu_a3": "SDN",
        "su_dif": 0,
        "subunit": "Sudan",
        "su_a3": "SDN",
        "brk_diff": 0,
        "name": "Sudan",
        "name_long": "Sudan",
        "brk_a3": "SDN",
        "brk_name": "Sudan",
        "brk_group": "",
        "abbrev": "Sudan",
        "postal": "SD",
        "formal_en": "Republic of the Sudan",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Sudan",
        "name_alt": "",
        "mapcolor7": 2,
        "mapcolor8": 6,
        "mapcolor9": 4,
        "mapcolor13": 1,
        "pop_est": 25946220,
        "gdp_md_est": 88080,
        "pop_year": -99,
        "lastcensus": 2008,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "SD",
        "iso_a3": "SDN",
        "iso_n3": "729",
        "un_a3": "729",
        "wb_a2": "SD",
        "wb_a3": "SDN",
        "woe_id": -99,
        "adm0_a3_is": "SDN",
        "adm0_a3_us": "SDN",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Northern Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 5,
        "long_len": 5,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "S. Sudan": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "South Sudan",
        "sov_a3": "SDS",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "South Sudan",
        "adm0_a3": "SDS",
        "geou_dif": 0,
        "geounit": "South Sudan",
        "gu_a3": "SDS",
        "su_dif": 0,
        "subunit": "South Sudan",
        "su_a3": "SDS",
        "brk_diff": 0,
        "name": "S. Sudan",
        "name_long": "South Sudan",
        "brk_a3": "SDS",
        "brk_name": "S. Sudan",
        "brk_group": "",
        "abbrev": "S. Sud.",
        "postal": "SS",
        "formal_en": "Republic of South Sudan",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "South Sudan",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 3,
        "mapcolor9": 3,
        "mapcolor13": 5,
        "pop_est": 10625176,
        "gdp_md_est": 13227,
        "pop_year": -99,
        "lastcensus": 2008,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "SS",
        "iso_a3": "SSD",
        "iso_n3": "728",
        "un_a3": "728",
        "wb_a2": "SS",
        "wb_a3": "SSD",
        "woe_id": -99,
        "adm0_a3_is": "SSD",
        "adm0_a3_us": "SDS",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Eastern Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 8,
        "long_len": 11,
        "abbrev_len": 7,
        "tiny": -99,
        "homepart": 1
    },
    "Senegal": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Senegal",
        "sov_a3": "SEN",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Senegal",
        "adm0_a3": "SEN",
        "geou_dif": 0,
        "geounit": "Senegal",
        "gu_a3": "SEN",
        "su_dif": 0,
        "subunit": "Senegal",
        "su_a3": "SEN",
        "brk_diff": 0,
        "name": "Senegal",
        "name_long": "Senegal",
        "brk_a3": "SEN",
        "brk_name": "Senegal",
        "brk_group": "",
        "abbrev": "Sen.",
        "postal": "SN",
        "formal_en": "Republic of Senegal",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Senegal",
        "name_alt": "",
        "mapcolor7": 2,
        "mapcolor8": 6,
        "mapcolor9": 5,
        "mapcolor13": 5,
        "pop_est": 13711597,
        "gdp_md_est": 21980,
        "pop_year": -99,
        "lastcensus": 2002,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "SN",
        "iso_a3": "SEN",
        "iso_n3": "686",
        "un_a3": "686",
        "wb_a2": "SN",
        "wb_a3": "SEN",
        "woe_id": -99,
        "adm0_a3_is": "SEN",
        "adm0_a3_us": "SEN",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Western Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Solomon Is.": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Solomon Islands",
        "sov_a3": "SLB",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Solomon Islands",
        "adm0_a3": "SLB",
        "geou_dif": 0,
        "geounit": "Solomon Islands",
        "gu_a3": "SLB",
        "su_dif": 0,
        "subunit": "Solomon Islands",
        "su_a3": "SLB",
        "brk_diff": 0,
        "name": "Solomon Is.",
        "name_long": "Solomon Islands",
        "brk_a3": "SLB",
        "brk_name": "Solomon Is.",
        "brk_group": "",
        "abbrev": "S. Is.",
        "postal": "SB",
        "formal_en": "",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Solomon Islands",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 4,
        "mapcolor9": 1,
        "mapcolor13": 6,
        "pop_est": 595613,
        "gdp_md_est": 1078,
        "pop_year": -99,
        "lastcensus": 2009,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "SB",
        "iso_a3": "SLB",
        "iso_n3": "090",
        "un_a3": "090",
        "wb_a2": "SB",
        "wb_a3": "SLB",
        "woe_id": -99,
        "adm0_a3_is": "SLB",
        "adm0_a3_us": "SLB",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Oceania",
        "region_un": "Oceania",
        "subregion": "Melanesia",
        "region_wb": "East Asia & Pacific",
        "name_len": 11,
        "long_len": 15,
        "abbrev_len": 6,
        "tiny": -99,
        "homepart": 1
    },
    "Sierra Leone": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 4,
        "sovereignt": "Sierra Leone",
        "sov_a3": "SLE",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Sierra Leone",
        "adm0_a3": "SLE",
        "geou_dif": 0,
        "geounit": "Sierra Leone",
        "gu_a3": "SLE",
        "su_dif": 0,
        "subunit": "Sierra Leone",
        "su_a3": "SLE",
        "brk_diff": 0,
        "name": "Sierra Leone",
        "name_long": "Sierra Leone",
        "brk_a3": "SLE",
        "brk_name": "Sierra Leone",
        "brk_group": "",
        "abbrev": "S.L.",
        "postal": "SL",
        "formal_en": "Republic of Sierra Leone",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Sierra Leone",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 4,
        "mapcolor9": 1,
        "mapcolor13": 7,
        "pop_est": 6440053,
        "gdp_md_est": 4285,
        "pop_year": -99,
        "lastcensus": 2004,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "SL",
        "iso_a3": "SLE",
        "iso_n3": "694",
        "un_a3": "694",
        "wb_a2": "SL",
        "wb_a3": "SLE",
        "woe_id": -99,
        "adm0_a3_is": "SLE",
        "adm0_a3_us": "SLE",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Western Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 12,
        "long_len": 12,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "El Salvador": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 6,
        "sovereignt": "El Salvador",
        "sov_a3": "SLV",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "El Salvador",
        "adm0_a3": "SLV",
        "geou_dif": 0,
        "geounit": "El Salvador",
        "gu_a3": "SLV",
        "su_dif": 0,
        "subunit": "El Salvador",
        "su_a3": "SLV",
        "brk_diff": 0,
        "name": "El Salvador",
        "name_long": "El Salvador",
        "brk_a3": "SLV",
        "brk_name": "El Salvador",
        "brk_group": "",
        "abbrev": "El. S.",
        "postal": "SV",
        "formal_en": "Republic of El Salvador",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "El Salvador",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 4,
        "mapcolor9": 6,
        "mapcolor13": 8,
        "pop_est": 7185218,
        "gdp_md_est": 43630,
        "pop_year": -99,
        "lastcensus": 2007,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "SV",
        "iso_a3": "SLV",
        "iso_n3": "222",
        "un_a3": "222",
        "wb_a2": "SV",
        "wb_a3": "SLV",
        "woe_id": -99,
        "adm0_a3_is": "SLV",
        "adm0_a3_us": "SLV",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "North America",
        "region_un": "Americas",
        "subregion": "Central America",
        "region_wb": "Latin America & Caribbean",
        "name_len": 11,
        "long_len": 11,
        "abbrev_len": 6,
        "tiny": -99,
        "homepart": 1
    },
    "Somaliland": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 5,
        "sovereignt": "Somaliland",
        "sov_a3": "SOL",
        "adm0_dif": 0,
        "level": 2,
        "type": "Indeterminate",
        "admin": "Somaliland",
        "adm0_a3": "SOL",
        "geou_dif": 0,
        "geounit": "Somaliland",
        "gu_a3": "SOL",
        "su_dif": 0,
        "subunit": "Somaliland",
        "su_a3": "SOL",
        "brk_diff": 1,
        "name": "Somaliland",
        "name_long": "Somaliland",
        "brk_a3": "B30",
        "brk_name": "Somaliland",
        "brk_group": "",
        "abbrev": "Solnd.",
        "postal": "SL",
        "formal_en": "Republic of Somaliland",
        "formal_fr": "",
        "note_adm0": "Self admin.",
        "note_brk": "Self admin.; Claimed by Somalia",
        "name_sort": "Somaliland",
        "name_alt": "",
        "mapcolor7": 3,
        "mapcolor8": 6,
        "mapcolor9": 5,
        "mapcolor13": 2,
        "pop_est": 3500000,
        "gdp_md_est": 12250,
        "pop_year": -99,
        "lastcensus": -99,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "SH",
        "iso_a3": "-99",
        "iso_n3": "-99",
        "un_a3": "-099",
        "wb_a2": "SH",
        "wb_a3": "-99",
        "woe_id": -99,
        "adm0_a3_is": "SOM",
        "adm0_a3_us": "SOM",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Eastern Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 10,
        "long_len": 10,
        "abbrev_len": 6,
        "tiny": -99,
        "homepart": 1
    },
    "Somalia": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 6,
        "sovereignt": "Somalia",
        "sov_a3": "SOM",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Somalia",
        "adm0_a3": "SOM",
        "geou_dif": 0,
        "geounit": "Somalia",
        "gu_a3": "SOM",
        "su_dif": 0,
        "subunit": "Somalia",
        "su_a3": "SOM",
        "brk_diff": 0,
        "name": "Somalia",
        "name_long": "Somalia",
        "brk_a3": "SOM",
        "brk_name": "Somalia",
        "brk_group": "",
        "abbrev": "Som.",
        "postal": "SO",
        "formal_en": "Federal Republic of Somalia",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Somalia",
        "name_alt": "",
        "mapcolor7": 2,
        "mapcolor8": 8,
        "mapcolor9": 6,
        "mapcolor13": 7,
        "pop_est": 9832017,
        "gdp_md_est": 5524,
        "pop_year": -99,
        "lastcensus": 1987,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "SO",
        "iso_a3": "SOM",
        "iso_n3": "706",
        "un_a3": "706",
        "wb_a2": "SO",
        "wb_a3": "SOM",
        "woe_id": -99,
        "adm0_a3_is": "SOM",
        "adm0_a3_us": "SOM",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Eastern Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Serbia": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 5,
        "sovereignt": "Republic of Serbia",
        "sov_a3": "SRB",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Republic of Serbia",
        "adm0_a3": "SRB",
        "geou_dif": 0,
        "geounit": "Republic of Serbia",
        "gu_a3": "SRB",
        "su_dif": 0,
        "subunit": "Republic of Serbia",
        "su_a3": "SRB",
        "brk_diff": 0,
        "name": "Serbia",
        "name_long": "Serbia",
        "brk_a3": "SRB",
        "brk_name": "Serbia",
        "brk_group": "",
        "abbrev": "Serb.",
        "postal": "RS",
        "formal_en": "Republic of Serbia",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Serbia",
        "name_alt": "",
        "mapcolor7": 3,
        "mapcolor8": 3,
        "mapcolor9": 2,
        "mapcolor13": 10,
        "pop_est": 7379339,
        "gdp_md_est": 80340,
        "pop_year": -99,
        "lastcensus": 2011,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "RS",
        "iso_a3": "SRB",
        "iso_n3": "688",
        "un_a3": "688",
        "wb_a2": "YF",
        "wb_a3": "SRB",
        "woe_id": -99,
        "adm0_a3_is": "SRB",
        "adm0_a3_us": "SRB",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Southern Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 6,
        "long_len": 6,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "Suriname": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 4,
        "sovereignt": "Suriname",
        "sov_a3": "SUR",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Suriname",
        "adm0_a3": "SUR",
        "geou_dif": 0,
        "geounit": "Suriname",
        "gu_a3": "SUR",
        "su_dif": 0,
        "subunit": "Suriname",
        "su_a3": "SUR",
        "brk_diff": 0,
        "name": "Suriname",
        "name_long": "Suriname",
        "brk_a3": "SUR",
        "brk_name": "Suriname",
        "brk_group": "",
        "abbrev": "Sur.",
        "postal": "SR",
        "formal_en": "Republic of Suriname",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Suriname",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 4,
        "mapcolor9": 7,
        "mapcolor13": 6,
        "pop_est": 481267,
        "gdp_md_est": 4254,
        "pop_year": -99,
        "lastcensus": 2004,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "SR",
        "iso_a3": "SUR",
        "iso_n3": "740",
        "un_a3": "740",
        "wb_a2": "SR",
        "wb_a3": "SUR",
        "woe_id": -99,
        "adm0_a3_is": "SUR",
        "adm0_a3_us": "SUR",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "South America",
        "region_un": "Americas",
        "subregion": "South America",
        "region_wb": "Latin America & Caribbean",
        "name_len": 8,
        "long_len": 8,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Slovakia": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 6,
        "sovereignt": "Slovakia",
        "sov_a3": "SVK",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Slovakia",
        "adm0_a3": "SVK",
        "geou_dif": 0,
        "geounit": "Slovakia",
        "gu_a3": "SVK",
        "su_dif": 0,
        "subunit": "Slovakia",
        "su_a3": "SVK",
        "brk_diff": 0,
        "name": "Slovakia",
        "name_long": "Slovakia",
        "brk_a3": "SVK",
        "brk_name": "Slovakia",
        "brk_group": "",
        "abbrev": "Svk.",
        "postal": "SK",
        "formal_en": "Slovak Republic",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Slovak Republic",
        "name_alt": "",
        "mapcolor7": 2,
        "mapcolor8": 4,
        "mapcolor9": 4,
        "mapcolor13": 9,
        "pop_est": 5463046,
        "gdp_md_est": 119500,
        "pop_year": -99,
        "lastcensus": 2011,
        "gdp_year": -99,
        "economy": "2. Developed region: nonG7",
        "income_grp": "1. High income: OECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "SK",
        "iso_a3": "SVK",
        "iso_n3": "703",
        "un_a3": "703",
        "wb_a2": "SK",
        "wb_a3": "SVK",
        "woe_id": -99,
        "adm0_a3_is": "SVK",
        "adm0_a3_us": "SVK",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Eastern Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 8,
        "long_len": 8,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Slovenia": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 6,
        "sovereignt": "Slovenia",
        "sov_a3": "SVN",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Slovenia",
        "adm0_a3": "SVN",
        "geou_dif": 0,
        "geounit": "Slovenia",
        "gu_a3": "SVN",
        "su_dif": 0,
        "subunit": "Slovenia",
        "su_a3": "SVN",
        "brk_diff": 0,
        "name": "Slovenia",
        "name_long": "Slovenia",
        "brk_a3": "SVN",
        "brk_name": "Slovenia",
        "brk_group": "",
        "abbrev": "Slo.",
        "postal": "SLO",
        "formal_en": "Republic of Slovenia",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Slovenia",
        "name_alt": "",
        "mapcolor7": 2,
        "mapcolor8": 3,
        "mapcolor9": 2,
        "mapcolor13": 12,
        "pop_est": 2005692,
        "gdp_md_est": 59340,
        "pop_year": -99,
        "lastcensus": 2011,
        "gdp_year": -99,
        "economy": "2. Developed region: nonG7",
        "income_grp": "1. High income: OECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "SI",
        "iso_a3": "SVN",
        "iso_n3": "705",
        "un_a3": "705",
        "wb_a2": "SI",
        "wb_a3": "SVN",
        "woe_id": -99,
        "adm0_a3_is": "SVN",
        "adm0_a3_us": "SVN",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Southern Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 8,
        "long_len": 8,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Sweden": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Sweden",
        "sov_a3": "SWE",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Sweden",
        "adm0_a3": "SWE",
        "geou_dif": 0,
        "geounit": "Sweden",
        "gu_a3": "SWE",
        "su_dif": 0,
        "subunit": "Sweden",
        "su_a3": "SWE",
        "brk_diff": 0,
        "name": "Sweden",
        "name_long": "Sweden",
        "brk_a3": "SWE",
        "brk_name": "Sweden",
        "brk_group": "",
        "abbrev": "Swe.",
        "postal": "S",
        "formal_en": "Kingdom of Sweden",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Sweden",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 4,
        "mapcolor9": 2,
        "mapcolor13": 4,
        "pop_est": 9059651,
        "gdp_md_est": 344300,
        "pop_year": -99,
        "lastcensus": -99,
        "gdp_year": -99,
        "economy": "2. Developed region: nonG7",
        "income_grp": "1. High income: OECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "SE",
        "iso_a3": "SWE",
        "iso_n3": "752",
        "un_a3": "752",
        "wb_a2": "SE",
        "wb_a3": "SWE",
        "woe_id": -99,
        "adm0_a3_is": "SWE",
        "adm0_a3_us": "SWE",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Northern Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 6,
        "long_len": 6,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Swaziland": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 4,
        "sovereignt": "Swaziland",
        "sov_a3": "SWZ",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Swaziland",
        "adm0_a3": "SWZ",
        "geou_dif": 0,
        "geounit": "Swaziland",
        "gu_a3": "SWZ",
        "su_dif": 0,
        "subunit": "Swaziland",
        "su_a3": "SWZ",
        "brk_diff": 0,
        "name": "Swaziland",
        "name_long": "Swaziland",
        "brk_a3": "SWZ",
        "brk_name": "Swaziland",
        "brk_group": "",
        "abbrev": "Swz.",
        "postal": "SW",
        "formal_en": "Kingdom of Swaziland",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Swaziland",
        "name_alt": "",
        "mapcolor7": 3,
        "mapcolor8": 6,
        "mapcolor9": 2,
        "mapcolor13": 5,
        "pop_est": 1123913,
        "gdp_md_est": 5702,
        "pop_year": -99,
        "lastcensus": 2007,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "SZ",
        "iso_a3": "SWZ",
        "iso_n3": "748",
        "un_a3": "748",
        "wb_a2": "SZ",
        "wb_a3": "SWZ",
        "woe_id": -99,
        "adm0_a3_is": "SWZ",
        "adm0_a3_us": "SWZ",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Southern Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 9,
        "long_len": 9,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Syria": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Syria",
        "sov_a3": "SYR",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Syria",
        "adm0_a3": "SYR",
        "geou_dif": 0,
        "geounit": "Syria",
        "gu_a3": "SYR",
        "su_dif": 0,
        "subunit": "Syria",
        "su_a3": "SYR",
        "brk_diff": 0,
        "name": "Syria",
        "name_long": "Syria",
        "brk_a3": "SYR",
        "brk_name": "Syria",
        "brk_group": "",
        "abbrev": "Syria",
        "postal": "SYR",
        "formal_en": "Syrian Arab Republic",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Syrian Arab Republic",
        "name_alt": "",
        "mapcolor7": 2,
        "mapcolor8": 6,
        "mapcolor9": 2,
        "mapcolor13": 6,
        "pop_est": 20178485,
        "gdp_md_est": 98830,
        "pop_year": -99,
        "lastcensus": 2004,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "SY",
        "iso_a3": "SYR",
        "iso_n3": "760",
        "un_a3": "760",
        "wb_a2": "SY",
        "wb_a3": "SYR",
        "woe_id": -99,
        "adm0_a3_is": "SYR",
        "adm0_a3_us": "SYR",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Western Asia",
        "region_wb": "Middle East & North Africa",
        "name_len": 5,
        "long_len": 5,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "Chad": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Chad",
        "sov_a3": "TCD",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Chad",
        "adm0_a3": "TCD",
        "geou_dif": 0,
        "geounit": "Chad",
        "gu_a3": "TCD",
        "su_dif": 0,
        "subunit": "Chad",
        "su_a3": "TCD",
        "brk_diff": 0,
        "name": "Chad",
        "name_long": "Chad",
        "brk_a3": "TCD",
        "brk_name": "Chad",
        "brk_group": "",
        "abbrev": "Chad",
        "postal": "TD",
        "formal_en": "Republic of Chad",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Chad",
        "name_alt": "",
        "mapcolor7": 6,
        "mapcolor8": 1,
        "mapcolor9": 8,
        "mapcolor13": 6,
        "pop_est": 10329208,
        "gdp_md_est": 15860,
        "pop_year": -99,
        "lastcensus": 2009,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "TD",
        "iso_a3": "TCD",
        "iso_n3": "148",
        "un_a3": "148",
        "wb_a2": "TD",
        "wb_a3": "TCD",
        "woe_id": -99,
        "adm0_a3_is": "TCD",
        "adm0_a3_us": "TCD",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Middle Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 4,
        "long_len": 4,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Togo": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 6,
        "sovereignt": "Togo",
        "sov_a3": "TGO",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Togo",
        "adm0_a3": "TGO",
        "geou_dif": 0,
        "geounit": "Togo",
        "gu_a3": "TGO",
        "su_dif": 0,
        "subunit": "Togo",
        "su_a3": "TGO",
        "brk_diff": 0,
        "name": "Togo",
        "name_long": "Togo",
        "brk_a3": "TGO",
        "brk_name": "Togo",
        "brk_group": "",
        "abbrev": "Togo",
        "postal": "TG",
        "formal_en": "Togolese Republic",
        "formal_fr": "République Togolaise",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Togo",
        "name_alt": "",
        "mapcolor7": 3,
        "mapcolor8": 1,
        "mapcolor9": 3,
        "mapcolor13": 5,
        "pop_est": 6019877,
        "gdp_md_est": 5118,
        "pop_year": -99,
        "lastcensus": 2010,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "TG",
        "iso_a3": "TGO",
        "iso_n3": "768",
        "un_a3": "768",
        "wb_a2": "TG",
        "wb_a3": "TGO",
        "woe_id": -99,
        "adm0_a3_is": "TGO",
        "adm0_a3_us": "TGO",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Western Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 4,
        "long_len": 4,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Thailand": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Thailand",
        "sov_a3": "THA",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Thailand",
        "adm0_a3": "THA",
        "geou_dif": 0,
        "geounit": "Thailand",
        "gu_a3": "THA",
        "su_dif": 0,
        "subunit": "Thailand",
        "su_a3": "THA",
        "brk_diff": 0,
        "name": "Thailand",
        "name_long": "Thailand",
        "brk_a3": "THA",
        "brk_name": "Thailand",
        "brk_group": "",
        "abbrev": "Thai.",
        "postal": "TH",
        "formal_en": "Kingdom of Thailand",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Thailand",
        "name_alt": "",
        "mapcolor7": 3,
        "mapcolor8": 6,
        "mapcolor9": 8,
        "mapcolor13": 1,
        "pop_est": 65905410,
        "gdp_md_est": 547400,
        "pop_year": -99,
        "lastcensus": 2010,
        "gdp_year": -99,
        "economy": "5. Emerging region: G20",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "TH",
        "iso_a3": "THA",
        "iso_n3": "764",
        "un_a3": "764",
        "wb_a2": "TH",
        "wb_a3": "THA",
        "woe_id": -99,
        "adm0_a3_is": "THA",
        "adm0_a3_us": "THA",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "South-Eastern Asia",
        "region_wb": "East Asia & Pacific",
        "name_len": 8,
        "long_len": 8,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "Tajikistan": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 4,
        "sovereignt": "Tajikistan",
        "sov_a3": "TJK",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Tajikistan",
        "adm0_a3": "TJK",
        "geou_dif": 0,
        "geounit": "Tajikistan",
        "gu_a3": "TJK",
        "su_dif": 0,
        "subunit": "Tajikistan",
        "su_a3": "TJK",
        "brk_diff": 0,
        "name": "Tajikistan",
        "name_long": "Tajikistan",
        "brk_a3": "TJK",
        "brk_name": "Tajikistan",
        "brk_group": "",
        "abbrev": "Tjk.",
        "postal": "TJ",
        "formal_en": "Republic of Tajikistan",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Tajikistan",
        "name_alt": "",
        "mapcolor7": 3,
        "mapcolor8": 6,
        "mapcolor9": 2,
        "mapcolor13": 5,
        "pop_est": 7349145,
        "gdp_md_est": 13160,
        "pop_year": -99,
        "lastcensus": 2010,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "TJ",
        "iso_a3": "TJK",
        "iso_n3": "762",
        "un_a3": "762",
        "wb_a2": "TJ",
        "wb_a3": "TJK",
        "woe_id": -99,
        "adm0_a3_is": "TJK",
        "adm0_a3_us": "TJK",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Central Asia",
        "region_wb": "Europe & Central Asia",
        "name_len": 10,
        "long_len": 10,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Turkmenistan": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 4,
        "sovereignt": "Turkmenistan",
        "sov_a3": "TKM",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Turkmenistan",
        "adm0_a3": "TKM",
        "geou_dif": 0,
        "geounit": "Turkmenistan",
        "gu_a3": "TKM",
        "su_dif": 0,
        "subunit": "Turkmenistan",
        "su_a3": "TKM",
        "brk_diff": 0,
        "name": "Turkmenistan",
        "name_long": "Turkmenistan",
        "brk_a3": "TKM",
        "brk_name": "Turkmenistan",
        "brk_group": "",
        "abbrev": "Turkm.",
        "postal": "TM",
        "formal_en": "Turkmenistan",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Turkmenistan",
        "name_alt": "",
        "mapcolor7": 3,
        "mapcolor8": 2,
        "mapcolor9": 1,
        "mapcolor13": 9,
        "pop_est": 4884887,
        "gdp_md_est": 29780,
        "pop_year": -99,
        "lastcensus": 1995,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "TM",
        "iso_a3": "TKM",
        "iso_n3": "795",
        "un_a3": "795",
        "wb_a2": "TM",
        "wb_a3": "TKM",
        "woe_id": -99,
        "adm0_a3_is": "TKM",
        "adm0_a3_us": "TKM",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Central Asia",
        "region_wb": "Europe & Central Asia",
        "name_len": 12,
        "long_len": 12,
        "abbrev_len": 6,
        "tiny": -99,
        "homepart": 1
    },
    "Timor-Leste": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 5,
        "sovereignt": "East Timor",
        "sov_a3": "TLS",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "East Timor",
        "adm0_a3": "TLS",
        "geou_dif": 0,
        "geounit": "East Timor",
        "gu_a3": "TLS",
        "su_dif": 0,
        "subunit": "East Timor",
        "su_a3": "TLS",
        "brk_diff": 0,
        "name": "Timor-Leste",
        "name_long": "Timor-Leste",
        "brk_a3": "TLS",
        "brk_name": "Timor-Leste",
        "brk_group": "",
        "abbrev": "T.L.",
        "postal": "TL",
        "formal_en": "Democratic Republic of Timor-Leste",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Timor-Leste",
        "name_alt": "East Timor",
        "mapcolor7": 2,
        "mapcolor8": 2,
        "mapcolor9": 4,
        "mapcolor13": 3,
        "pop_est": 1131612,
        "gdp_md_est": 2520,
        "pop_year": -99,
        "lastcensus": 2010,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "TL",
        "iso_a3": "TLS",
        "iso_n3": "626",
        "un_a3": "626",
        "wb_a2": "TP",
        "wb_a3": "TMP",
        "woe_id": -99,
        "adm0_a3_is": "TLS",
        "adm0_a3_us": "TLS",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "South-Eastern Asia",
        "region_wb": "East Asia & Pacific",
        "name_len": 11,
        "long_len": 11,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Trinidad and Tobago": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 5,
        "sovereignt": "Trinidad and Tobago",
        "sov_a3": "TTO",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Trinidad and Tobago",
        "adm0_a3": "TTO",
        "geou_dif": 0,
        "geounit": "Trinidad and Tobago",
        "gu_a3": "TTO",
        "su_dif": 0,
        "subunit": "Trinidad and Tobago",
        "su_a3": "TTO",
        "brk_diff": 0,
        "name": "Trinidad and Tobago",
        "name_long": "Trinidad and Tobago",
        "brk_a3": "TTO",
        "brk_name": "Trinidad and Tobago",
        "brk_group": "",
        "abbrev": "Tr.T.",
        "postal": "TT",
        "formal_en": "Republic of Trinidad and Tobago",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Trinidad and Tobago",
        "name_alt": "",
        "mapcolor7": 5,
        "mapcolor8": 6,
        "mapcolor9": 2,
        "mapcolor13": 5,
        "pop_est": 1310000,
        "gdp_md_est": 29010,
        "pop_year": -99,
        "lastcensus": 2011,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "2. High income: nonOECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "TT",
        "iso_a3": "TTO",
        "iso_n3": "780",
        "un_a3": "780",
        "wb_a2": "TT",
        "wb_a3": "TTO",
        "woe_id": -99,
        "adm0_a3_is": "TTO",
        "adm0_a3_us": "TTO",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "North America",
        "region_un": "Americas",
        "subregion": "Caribbean",
        "region_wb": "Latin America & Caribbean",
        "name_len": 19,
        "long_len": 19,
        "abbrev_len": 5,
        "tiny": 2,
        "homepart": 1
    },
    "Tunisia": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Tunisia",
        "sov_a3": "TUN",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Tunisia",
        "adm0_a3": "TUN",
        "geou_dif": 0,
        "geounit": "Tunisia",
        "gu_a3": "TUN",
        "su_dif": 0,
        "subunit": "Tunisia",
        "su_a3": "TUN",
        "brk_diff": 0,
        "name": "Tunisia",
        "name_long": "Tunisia",
        "brk_a3": "TUN",
        "brk_name": "Tunisia",
        "brk_group": "",
        "abbrev": "Tun.",
        "postal": "TN",
        "formal_en": "Republic of Tunisia",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Tunisia",
        "name_alt": "",
        "mapcolor7": 4,
        "mapcolor8": 3,
        "mapcolor9": 3,
        "mapcolor13": 2,
        "pop_est": 10486339,
        "gdp_md_est": 81710,
        "pop_year": -99,
        "lastcensus": 2004,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "TN",
        "iso_a3": "TUN",
        "iso_n3": "788",
        "un_a3": "788",
        "wb_a2": "TN",
        "wb_a3": "TUN",
        "woe_id": -99,
        "adm0_a3_is": "TUN",
        "adm0_a3_us": "TUN",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Northern Africa",
        "region_wb": "Middle East & North Africa",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Turkey": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "Turkey",
        "sov_a3": "TUR",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Turkey",
        "adm0_a3": "TUR",
        "geou_dif": 0,
        "geounit": "Turkey",
        "gu_a3": "TUR",
        "su_dif": 0,
        "subunit": "Turkey",
        "su_a3": "TUR",
        "brk_diff": 0,
        "name": "Turkey",
        "name_long": "Turkey",
        "brk_a3": "TUR",
        "brk_name": "Turkey",
        "brk_group": "",
        "abbrev": "Tur.",
        "postal": "TR",
        "formal_en": "Republic of Turkey",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Turkey",
        "name_alt": "",
        "mapcolor7": 6,
        "mapcolor8": 3,
        "mapcolor9": 8,
        "mapcolor13": 4,
        "pop_est": 76805524,
        "gdp_md_est": 902700,
        "pop_year": -99,
        "lastcensus": 2000,
        "gdp_year": -99,
        "economy": "4. Emerging region: MIKT",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "TR",
        "iso_a3": "TUR",
        "iso_n3": "792",
        "un_a3": "792",
        "wb_a2": "TR",
        "wb_a3": "TUR",
        "woe_id": -99,
        "adm0_a3_is": "TUR",
        "adm0_a3_us": "TUR",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Western Asia",
        "region_wb": "Europe & Central Asia",
        "name_len": 6,
        "long_len": 6,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Taiwan": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Taiwan",
        "sov_a3": "TWN",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Taiwan",
        "adm0_a3": "TWN",
        "geou_dif": 0,
        "geounit": "Taiwan",
        "gu_a3": "TWN",
        "su_dif": 0,
        "subunit": "Taiwan",
        "su_a3": "TWN",
        "brk_diff": 1,
        "name": "Taiwan",
        "name_long": "Taiwan",
        "brk_a3": "B77",
        "brk_name": "Taiwan",
        "brk_group": "",
        "abbrev": "Taiwan",
        "postal": "TW",
        "formal_en": "",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "Self admin.; Claimed by China",
        "name_sort": "Taiwan",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 5,
        "mapcolor9": 7,
        "mapcolor13": 2,
        "pop_est": 22974347,
        "gdp_md_est": 712000,
        "pop_year": -99,
        "lastcensus": -99,
        "gdp_year": -99,
        "economy": "2. Developed region: nonG7",
        "income_grp": "2. High income: nonOECD",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "TW",
        "iso_a3": "TWN",
        "iso_n3": "158",
        "un_a3": "-099",
        "wb_a2": "-99",
        "wb_a3": "-99",
        "woe_id": -99,
        "adm0_a3_is": "TWN",
        "adm0_a3_us": "TWN",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Eastern Asia",
        "region_wb": "East Asia & Pacific",
        "name_len": 6,
        "long_len": 6,
        "abbrev_len": 6,
        "tiny": -99,
        "homepart": 1
    },
    "Tanzania": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "United Republic of Tanzania",
        "sov_a3": "TZA",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "United Republic of Tanzania",
        "adm0_a3": "TZA",
        "geou_dif": 0,
        "geounit": "Tanzania",
        "gu_a3": "TZA",
        "su_dif": 0,
        "subunit": "Tanzania",
        "su_a3": "TZA",
        "brk_diff": 0,
        "name": "Tanzania",
        "name_long": "Tanzania",
        "brk_a3": "TZA",
        "brk_name": "Tanzania",
        "brk_group": "",
        "abbrev": "Tanz.",
        "postal": "TZ",
        "formal_en": "United Republic of Tanzania",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Tanzania",
        "name_alt": "",
        "mapcolor7": 3,
        "mapcolor8": 6,
        "mapcolor9": 2,
        "mapcolor13": 2,
        "pop_est": 41048532,
        "gdp_md_est": 54250,
        "pop_year": -99,
        "lastcensus": 2002,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "TZ",
        "iso_a3": "TZA",
        "iso_n3": "834",
        "un_a3": "834",
        "wb_a2": "TZ",
        "wb_a3": "TZA",
        "woe_id": -99,
        "adm0_a3_is": "TZA",
        "adm0_a3_us": "TZA",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Eastern Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 8,
        "long_len": 8,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "Uganda": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Uganda",
        "sov_a3": "UGA",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Uganda",
        "adm0_a3": "UGA",
        "geou_dif": 0,
        "geounit": "Uganda",
        "gu_a3": "UGA",
        "su_dif": 0,
        "subunit": "Uganda",
        "su_a3": "UGA",
        "brk_diff": 0,
        "name": "Uganda",
        "name_long": "Uganda",
        "brk_a3": "UGA",
        "brk_name": "Uganda",
        "brk_group": "",
        "abbrev": "Uga.",
        "postal": "UG",
        "formal_en": "Republic of Uganda",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Uganda",
        "name_alt": "",
        "mapcolor7": 6,
        "mapcolor8": 3,
        "mapcolor9": 6,
        "mapcolor13": 4,
        "pop_est": 32369558,
        "gdp_md_est": 39380,
        "pop_year": -99,
        "lastcensus": 2002,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "UG",
        "iso_a3": "UGA",
        "iso_n3": "800",
        "un_a3": "800",
        "wb_a2": "UG",
        "wb_a3": "UGA",
        "woe_id": -99,
        "adm0_a3_is": "UGA",
        "adm0_a3_us": "UGA",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Eastern Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 6,
        "long_len": 6,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Ukraine": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Ukraine",
        "sov_a3": "UKR",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Ukraine",
        "adm0_a3": "UKR",
        "geou_dif": 0,
        "geounit": "Ukraine",
        "gu_a3": "UKR",
        "su_dif": 0,
        "subunit": "Ukraine",
        "su_a3": "UKR",
        "brk_diff": 0,
        "name": "Ukraine",
        "name_long": "Ukraine",
        "brk_a3": "UKR",
        "brk_name": "Ukraine",
        "brk_group": "",
        "abbrev": "Ukr.",
        "postal": "UA",
        "formal_en": "Ukraine",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Ukraine",
        "name_alt": "",
        "mapcolor7": 5,
        "mapcolor8": 1,
        "mapcolor9": 6,
        "mapcolor13": 3,
        "pop_est": 45700395,
        "gdp_md_est": 339800,
        "pop_year": -99,
        "lastcensus": 2001,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "UA",
        "iso_a3": "UKR",
        "iso_n3": "804",
        "un_a3": "804",
        "wb_a2": "UA",
        "wb_a3": "UKR",
        "woe_id": -99,
        "adm0_a3_is": "UKR",
        "adm0_a3_us": "UKR",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Europe",
        "region_un": "Europe",
        "subregion": "Eastern Europe",
        "region_wb": "Europe & Central Asia",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Uruguay": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 4,
        "sovereignt": "Uruguay",
        "sov_a3": "URY",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Uruguay",
        "adm0_a3": "URY",
        "geou_dif": 0,
        "geounit": "Uruguay",
        "gu_a3": "URY",
        "su_dif": 0,
        "subunit": "Uruguay",
        "su_a3": "URY",
        "brk_diff": 0,
        "name": "Uruguay",
        "name_long": "Uruguay",
        "brk_a3": "URY",
        "brk_name": "Uruguay",
        "brk_group": "",
        "abbrev": "Ury.",
        "postal": "UY",
        "formal_en": "Oriental Republic of Uruguay",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Uruguay",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 2,
        "mapcolor9": 2,
        "mapcolor13": 10,
        "pop_est": 3494382,
        "gdp_md_est": 43160,
        "pop_year": -99,
        "lastcensus": 2004,
        "gdp_year": -99,
        "economy": "5. Emerging region: G20",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "UY",
        "iso_a3": "URY",
        "iso_n3": "858",
        "un_a3": "858",
        "wb_a2": "UY",
        "wb_a3": "URY",
        "woe_id": -99,
        "adm0_a3_is": "URY",
        "adm0_a3_us": "URY",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "South America",
        "region_un": "Americas",
        "subregion": "South America",
        "region_wb": "Latin America & Caribbean",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "United States": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "United States of America",
        "sov_a3": "US1",
        "adm0_dif": 1,
        "level": 2,
        "type": "Country",
        "admin": "United States of America",
        "adm0_a3": "USA",
        "geou_dif": 0,
        "geounit": "United States of America",
        "gu_a3": "USA",
        "su_dif": 0,
        "subunit": "United States of America",
        "su_a3": "USA",
        "brk_diff": 0,
        "name": "United States",
        "name_long": "United States",
        "brk_a3": "USA",
        "brk_name": "United States",
        "brk_group": "",
        "abbrev": "U.S.A.",
        "postal": "US",
        "formal_en": "United States of America",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "United States of America",
        "name_alt": "",
        "mapcolor7": 4,
        "mapcolor8": 5,
        "mapcolor9": 1,
        "mapcolor13": 1,
        "pop_est": 313973000,
        "gdp_md_est": 15094000,
        "pop_year": 0,
        "lastcensus": 2010,
        "gdp_year": 0,
        "economy": "1. Developed region: G7",
        "income_grp": "1. High income: OECD",
        "wikipedia": 0,
        "fips_10": "",
        "iso_a2": "US",
        "iso_a3": "USA",
        "iso_n3": "840",
        "un_a3": "840",
        "wb_a2": "US",
        "wb_a3": "USA",
        "woe_id": -99,
        "adm0_a3_is": "USA",
        "adm0_a3_us": "USA",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "North America",
        "region_un": "Americas",
        "subregion": "Northern America",
        "region_wb": "North America",
        "name_len": 13,
        "long_len": 13,
        "abbrev_len": 6,
        "tiny": -99,
        "homepart": 1
    },
    "Uzbekistan": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Uzbekistan",
        "sov_a3": "UZB",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Uzbekistan",
        "adm0_a3": "UZB",
        "geou_dif": 0,
        "geounit": "Uzbekistan",
        "gu_a3": "UZB",
        "su_dif": 0,
        "subunit": "Uzbekistan",
        "su_a3": "UZB",
        "brk_diff": 0,
        "name": "Uzbekistan",
        "name_long": "Uzbekistan",
        "brk_a3": "UZB",
        "brk_name": "Uzbekistan",
        "brk_group": "",
        "abbrev": "Uzb.",
        "postal": "UZ",
        "formal_en": "Republic of Uzbekistan",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Uzbekistan",
        "name_alt": "",
        "mapcolor7": 2,
        "mapcolor8": 3,
        "mapcolor9": 5,
        "mapcolor13": 4,
        "pop_est": 27606007,
        "gdp_md_est": 71670,
        "pop_year": -99,
        "lastcensus": 1989,
        "gdp_year": -99,
        "economy": "6. Developing region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "UZ",
        "iso_a3": "UZB",
        "iso_n3": "860",
        "un_a3": "860",
        "wb_a2": "UZ",
        "wb_a3": "UZB",
        "woe_id": -99,
        "adm0_a3_is": "UZB",
        "adm0_a3_us": "UZB",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Central Asia",
        "region_wb": "Europe & Central Asia",
        "name_len": 10,
        "long_len": 10,
        "abbrev_len": 4,
        "tiny": 5,
        "homepart": 1
    },
    "Venezuela": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Venezuela",
        "sov_a3": "VEN",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Venezuela",
        "adm0_a3": "VEN",
        "geou_dif": 0,
        "geounit": "Venezuela",
        "gu_a3": "VEN",
        "su_dif": 0,
        "subunit": "Venezuela",
        "su_a3": "VEN",
        "brk_diff": 0,
        "name": "Venezuela",
        "name_long": "Venezuela",
        "brk_a3": "VEN",
        "brk_name": "Venezuela",
        "brk_group": "",
        "abbrev": "Ven.",
        "postal": "VE",
        "formal_en": "Bolivarian Republic of Venezuela",
        "formal_fr": "República Bolivariana de Venezuela",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Venezuela, RB",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 3,
        "mapcolor9": 1,
        "mapcolor13": 4,
        "pop_est": 26814843,
        "gdp_md_est": 357400,
        "pop_year": -99,
        "lastcensus": 2001,
        "gdp_year": -99,
        "economy": "5. Emerging region: G20",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "VE",
        "iso_a3": "VEN",
        "iso_n3": "862",
        "un_a3": "862",
        "wb_a2": "VE",
        "wb_a3": "VEN",
        "woe_id": -99,
        "adm0_a3_is": "VEN",
        "adm0_a3_us": "VEN",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "South America",
        "region_un": "Americas",
        "subregion": "South America",
        "region_wb": "Latin America & Caribbean",
        "name_len": 9,
        "long_len": 9,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "Vietnam": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "Vietnam",
        "sov_a3": "VNM",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Vietnam",
        "adm0_a3": "VNM",
        "geou_dif": 0,
        "geounit": "Vietnam",
        "gu_a3": "VNM",
        "su_dif": 0,
        "subunit": "Vietnam",
        "su_a3": "VNM",
        "brk_diff": 0,
        "name": "Vietnam",
        "name_long": "Vietnam",
        "brk_a3": "VNM",
        "brk_name": "Vietnam",
        "brk_group": "",
        "abbrev": "Viet.",
        "postal": "VN",
        "formal_en": "Socialist Republic of Vietnam",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Vietnam",
        "name_alt": "",
        "mapcolor7": 5,
        "mapcolor8": 6,
        "mapcolor9": 5,
        "mapcolor13": 4,
        "pop_est": 86967524,
        "gdp_md_est": 241700,
        "pop_year": -99,
        "lastcensus": 2009,
        "gdp_year": -99,
        "economy": "5. Emerging region: G20",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "VN",
        "iso_a3": "VNM",
        "iso_n3": "704",
        "un_a3": "704",
        "wb_a2": "VN",
        "wb_a3": "VNM",
        "woe_id": -99,
        "adm0_a3_is": "VNM",
        "adm0_a3_us": "VNM",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "South-Eastern Asia",
        "region_wb": "East Asia & Pacific",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 5,
        "tiny": 2,
        "homepart": 1
    },
    "Vanuatu": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 4,
        "sovereignt": "Vanuatu",
        "sov_a3": "VUT",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Vanuatu",
        "adm0_a3": "VUT",
        "geou_dif": 0,
        "geounit": "Vanuatu",
        "gu_a3": "VUT",
        "su_dif": 0,
        "subunit": "Vanuatu",
        "su_a3": "VUT",
        "brk_diff": 0,
        "name": "Vanuatu",
        "name_long": "Vanuatu",
        "brk_a3": "VUT",
        "brk_name": "Vanuatu",
        "brk_group": "",
        "abbrev": "Van.",
        "postal": "VU",
        "formal_en": "Republic of Vanuatu",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Vanuatu",
        "name_alt": "",
        "mapcolor7": 6,
        "mapcolor8": 3,
        "mapcolor9": 7,
        "mapcolor13": 3,
        "pop_est": 218519,
        "gdp_md_est": 988.5,
        "pop_year": -99,
        "lastcensus": 2009,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "VU",
        "iso_a3": "VUT",
        "iso_n3": "548",
        "un_a3": "548",
        "wb_a2": "VU",
        "wb_a3": "VUT",
        "woe_id": -99,
        "adm0_a3_is": "VUT",
        "adm0_a3_us": "VUT",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Oceania",
        "region_un": "Oceania",
        "subregion": "Melanesia",
        "region_wb": "East Asia & Pacific",
        "name_len": 7,
        "long_len": 7,
        "abbrev_len": 4,
        "tiny": 2,
        "homepart": 1
    },
    "Yemen": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Yemen",
        "sov_a3": "YEM",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Yemen",
        "adm0_a3": "YEM",
        "geou_dif": 0,
        "geounit": "Yemen",
        "gu_a3": "YEM",
        "su_dif": 0,
        "subunit": "Yemen",
        "su_a3": "YEM",
        "brk_diff": 0,
        "name": "Yemen",
        "name_long": "Yemen",
        "brk_a3": "YEM",
        "brk_name": "Yemen",
        "brk_group": "",
        "abbrev": "Yem.",
        "postal": "YE",
        "formal_en": "Republic of Yemen",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Yemen, Rep.",
        "name_alt": "",
        "mapcolor7": 5,
        "mapcolor8": 3,
        "mapcolor9": 3,
        "mapcolor13": 11,
        "pop_est": 23822783,
        "gdp_md_est": 55280,
        "pop_year": -99,
        "lastcensus": 2004,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "YE",
        "iso_a3": "YEM",
        "iso_n3": "887",
        "un_a3": "887",
        "wb_a2": "RY",
        "wb_a3": "YEM",
        "woe_id": -99,
        "adm0_a3_is": "YEM",
        "adm0_a3_us": "YEM",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Asia",
        "region_un": "Asia",
        "subregion": "Western Asia",
        "region_wb": "Middle East & North Africa",
        "name_len": 5,
        "long_len": 5,
        "abbrev_len": 4,
        "tiny": -99,
        "homepart": 1
    },
    "South Africa": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 2,
        "sovereignt": "South Africa",
        "sov_a3": "ZAF",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "South Africa",
        "adm0_a3": "ZAF",
        "geou_dif": 0,
        "geounit": "South Africa",
        "gu_a3": "ZAF",
        "su_dif": 0,
        "subunit": "South Africa",
        "su_a3": "ZAF",
        "brk_diff": 0,
        "name": "South Africa",
        "name_long": "South Africa",
        "brk_a3": "ZAF",
        "brk_name": "South Africa",
        "brk_group": "",
        "abbrev": "S.Af.",
        "postal": "ZA",
        "formal_en": "Republic of South Africa",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "South Africa",
        "name_alt": "",
        "mapcolor7": 2,
        "mapcolor8": 3,
        "mapcolor9": 4,
        "mapcolor13": 2,
        "pop_est": 49052489,
        "gdp_md_est": 491000,
        "pop_year": -99,
        "lastcensus": 2001,
        "gdp_year": -99,
        "economy": "5. Emerging region: G20",
        "income_grp": "3. Upper middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "ZA",
        "iso_a3": "ZAF",
        "iso_n3": "710",
        "un_a3": "710",
        "wb_a2": "ZA",
        "wb_a3": "ZAF",
        "woe_id": -99,
        "adm0_a3_is": "ZAF",
        "adm0_a3_us": "ZAF",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Southern Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 12,
        "long_len": 12,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    },
    "Zambia": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Zambia",
        "sov_a3": "ZMB",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Zambia",
        "adm0_a3": "ZMB",
        "geou_dif": 0,
        "geounit": "Zambia",
        "gu_a3": "ZMB",
        "su_dif": 0,
        "subunit": "Zambia",
        "su_a3": "ZMB",
        "brk_diff": 0,
        "name": "Zambia",
        "name_long": "Zambia",
        "brk_a3": "ZMB",
        "brk_name": "Zambia",
        "brk_group": "",
        "abbrev": "Zambia",
        "postal": "ZM",
        "formal_en": "Republic of Zambia",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Zambia",
        "name_alt": "",
        "mapcolor7": 5,
        "mapcolor8": 8,
        "mapcolor9": 5,
        "mapcolor13": 13,
        "pop_est": 11862740,
        "gdp_md_est": 17500,
        "pop_year": -99,
        "lastcensus": 2010,
        "gdp_year": -99,
        "economy": "7. Least developed region",
        "income_grp": "4. Lower middle income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "ZM",
        "iso_a3": "ZMB",
        "iso_n3": "894",
        "un_a3": "894",
        "wb_a2": "ZM",
        "wb_a3": "ZMB",
        "woe_id": -99,
        "adm0_a3_is": "ZMB",
        "adm0_a3_us": "ZMB",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Eastern Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 6,
        "long_len": 6,
        "abbrev_len": 6,
        "tiny": -99,
        "homepart": 1
    },
    "Zimbabwe": {
        "scalerank": 1,
        "featurecla": "Admin-0 country",
        "labelrank": 3,
        "sovereignt": "Zimbabwe",
        "sov_a3": "ZWE",
        "adm0_dif": 0,
        "level": 2,
        "type": "Sovereign country",
        "admin": "Zimbabwe",
        "adm0_a3": "ZWE",
        "geou_dif": 0,
        "geounit": "Zimbabwe",
        "gu_a3": "ZWE",
        "su_dif": 0,
        "subunit": "Zimbabwe",
        "su_a3": "ZWE",
        "brk_diff": 0,
        "name": "Zimbabwe",
        "name_long": "Zimbabwe",
        "brk_a3": "ZWE",
        "brk_name": "Zimbabwe",
        "brk_group": "",
        "abbrev": "Zimb.",
        "postal": "ZW",
        "formal_en": "Republic of Zimbabwe",
        "formal_fr": "",
        "note_adm0": "",
        "note_brk": "",
        "name_sort": "Zimbabwe",
        "name_alt": "",
        "mapcolor7": 1,
        "mapcolor8": 5,
        "mapcolor9": 3,
        "mapcolor13": 9,
        "pop_est": 12619600,
        "gdp_md_est": 9323,
        "pop_year": 0,
        "lastcensus": 2002,
        "gdp_year": 0,
        "economy": "5. Emerging region: G20",
        "income_grp": "5. Low income",
        "wikipedia": -99,
        "fips_10": "",
        "iso_a2": "ZW",
        "iso_a3": "ZWE",
        "iso_n3": "716",
        "un_a3": "716",
        "wb_a2": "ZW",
        "wb_a3": "ZWE",
        "woe_id": -99,
        "adm0_a3_is": "ZWE",
        "adm0_a3_us": "ZWE",
        "adm0_a3_un": -99,
        "adm0_a3_wb": -99,
        "continent": "Africa",
        "region_un": "Africa",
        "subregion": "Eastern Africa",
        "region_wb": "Sub-Saharan Africa",
        "name_len": 8,
        "long_len": 8,
        "abbrev_len": 5,
        "tiny": -99,
        "homepart": 1
    }
}