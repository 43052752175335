import React from 'react';
import { countries } from './data/dx-countries.js';
import { wikies } from './data/wiki.js';

export default function TooltipTemplate(info) {
  var name = info.attribute('name');
  var country = countries[name];
  var wiki = wikies[country.iso_a2];

  var continentText = country.region_un ? ` (${country.region_un})` : '';
  var capitalText = wiki.capital ? `Capital: ${wiki.capital}` : '';
  var areaText = `Area: ${wiki.area.toLocaleString()} km²`;
  var populationText = '';
  if (wiki.population.value > 0)
    populationText = `Population: ${wiki.population.value.toLocaleString()} (${wiki.population.year} ${wiki.population.source})`;

  var imageSrc = '';
  try { imageSrc = require('./flags/' + country.iso_a2 + '.png'); } catch(ex) { }
  
  return (
    <div id="tooltip">
      <div><img id="tooltip-image" src={imageSrc}/></div>
      <div><strong>{country.name_sort}</strong>{continentText}</div>
      <div>{capitalText}</div>
      <div>{areaText}</div>
      <div>{populationText}</div>
    </div>
  );
}
